// src/components/filter.
import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { useMediaQuery } from "react-responsive"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState } from "react"
import { Link } from "react-router-dom"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
function DatatableTables2() {
  const MOBILE_BREAKPOINT = "(max-width: 767px)"
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT })
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  function getButtonColor(showstatus) {
    if (showstatus == "Schedule") {
      return "yellow"
    } else if (showstatus == "Confirmed") {
      return "green"
    } else if (showstatus == "Cancelled") {
      return "lightgrey"
    } else if (showstatus == "Completed") {
      return "#4CAF50"
    } else if (showstatus == "No-Show") {
      return "red"
    }
    return "blue" // Default color if none of the conditions match
  }
  const formatDate = date => {
    return moment(date).format("DD/MM/YYYY")
  }
  function formatTime(pickupTime) {
    const dummyDate = "1970-01-01"
    const timeString = dummyDate + "T" + pickupTime
    const time = new Date(timeString)
    let hour = time.getHours()
    let minute = time.getMinutes()
    const period = hour >= 12 ? "PM" : "AM"
    hour = hour % 12 === 0 ? 12 : hour % 12
    hour = hour < 10 ? "0" + hour : hour // Add leading zero if less than 10
    minute = minute < 10 ? "0" + minute : minute // Add leading zero if less than 10

    return `${hour}:${minute} ${period}`
  }
  function separateTimeParts(formattedTime) {
    const hour = formattedTime.split(":")[0].trim()
    const remainingTime = formattedTime.substring(hour.length).trim()
    const mins = remainingTime.split(" ")[0].trim()
    const period = remainingTime.substring(mins.length).trim()
    return [hour, mins, period]
  }
  const isPickupFromAirport = pickupAddress => {
    return pickupAddress.includes("Airport", "Terminal")
  }
  const isDropOffToHotel = pickupAddress => {
    return pickupAddress.includes("Hotel")
  }
  function add(a, b) {
    return parseInt(a) + parseInt(b)
  }
  const fetchData = () => {
    setIsLoading(true)

    const storeToken = localStorage.getItem("apiToken")
    const value = JSON.parse(storeToken)
    const token = value.API
    console.log(token)
    return fetch("https://flymiles.com.au/adminapi/public/api/booking/3", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(apidata => {
        // apisetData(data)
        console.log(apidata)
        if (
          apidata.success &&
          apidata.bookings &&
          apidata.bookings.length > 0
        ) {
          const updatedData = [
            ...data, // Keep the existing data
            ...apidata.bookings.slice(0, apidata.length).map(booking => ({
              conf: (
                <div
                  style={{
                    color: "#2196f3",
                  }}
                >
                  {booking.reservationid}
                </div>
              ),
              pickup: (
                <div>
                  <strong>Pickup:</strong>
                  <br />
                  {formatDate(booking.pickup_date)}
                  <br />
                  {formatTime(booking.pickup_time)}
                  <br />
                  <i className="fa fa-fw fa-clock"></i> {booking.duration}
                  {" Min"}
                  <br />
                  <i className="fa fa-fw fa-tachometer-alt"></i>{" "}
                  {booking.distance}
                  {" km"}
                </div>
              ),
              name: (
                <div>
                  <span
                    style={{ textTransform: "uppercase", color: "#F44436" }}
                  >
                    {booking.name}
                  </span>
                  <br />{" "}
                  <a
                    style={{ color: "inherit" }}
                    href={"tel:" + booking.mobile}
                  >
                    {booking.mobile}
                  </a>
                  <br />
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {booking.veh_type_name}
                  </span>
                  <br />
                  <i className="fa fa-fw fa-user"></i>
                  {booking.pax} -<i className="fa fa-fw fa-briefcase"></i>
                  {booking.luggage} -<i className="fa fa-fw fa-baby"></i>
                  {booking.extra_item3_count === null ||
                  booking.extra_item2_count === null
                    ? 0
                    : (parseInt(booking.extra_item3_count) || 0) +
                      (parseInt(booking.extra_item2_count) || 0)}
                </div>
              ),
              pickupdropoff: (
                <div>
                  <strong>Pickup</strong>

                  <br />
                  {booking.pickup_address}
                  <br />
                  <strong>Dropoff</strong>

                  <br />
                  {booking.drop_off}
                  <div
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "#ffffff",
                      padding: "2px 10px",
                    }}
                  >
                    {isPickupFromAirport(booking.pickup_address) ||
                    isDropOffToHotel(booking.drop_off) ? (
                      <i
                        style={{ transform: "rotate(-45deg)" }}
                        className="fa fa-fw fa-plane deg45m"
                      ></i>
                    ) : (
                      <i className="fa fa-fw fa-hotel"></i>
                    )}
                    {booking.pick_airline_name} {booking.pick_flight_number}{" "}
                    {booking.showstatus} |
                    <small>
                      {" "}
                      {booking.agent_name} | Supplier Conf no -{booking.groupon}
                    </small>
                  </div>
                </div>
              ),
              status: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      borderRadius: "2px",
                      padding: "5px 15px",
                      boxShadow: "-1px 1px 3px rgba(0, 0, 0, 0.4)",
                      backgroundColor: getButtonColor(booking.showstatus),
                    }}
                  >
                    {booking.showstatus}
                  </button>
                </div>
              ),
              drivervehicle: (
                <div>
                  <i className="fa fa-fw fa-user"></i>
                  {booking.driver_name}
                  <br />
                  <i className="fa fa-fw fa-car"></i>
                  {booking.vehicle_model}
                  <br />
                  <i className="fa fa-fw fa-phone"></i>
                  {booking.contact_no}
                  <br />
                  <i className="fa fa-fw fa-credit-card"></i>{" "}
                  {booking.payment_type === "credit card" ? (
                    <>
                      {booking.payment_type}
                      <br />
                      <span
                        className="paid p-1 px-2"
                        style={{
                          color: "#34c38f",
                          backgroundColor: "rgba(52, 195, 143, 0.18)",
                          borderRadius: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        Paid
                      </span>
                    </>
                  ) : booking.showstatus === "Cancelled" &&
                    booking.payment_type === "stripe" ? (
                    <>
                      {booking.payment_type}
                      <span
                        className="paid p-1 px-2 mr-1"
                        style={{
                          color: "#34c38f",
                          backgroundColor: "rgba(52, 195, 143, 0.18)",
                          borderRadius: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        Paid
                      </span>
                      <span
                        className="refunded p-1 px-2"
                        style={{
                          color: "#f1b44c",
                          backgroundColor: "rgba(241, 180, 76, 0.18)",
                          borderRadius: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        Refunded
                      </span>
                    </>
                  ) : (
                    <>{booking.payment_type}</>
                  )}
                </div>
              ),
              price: (
                <div>
                  <span
                    style={{
                      margin: "0px",
                      textTransform: "uppercase",
                      fontWeight: "600",
                    }}
                  >
                    {booking.currency}
                  </span>{" "}
                  <br /> {booking.price}
                </div>
              ),
              allData:
                booking.reservationid +
                formatTime(booking.pickup_time) +
                formatDate(booking.pickup_date) +
                booking.duration +
                booking.distance +
                booking.name +
                booking.mobile +
                booking.veh_type_name +
                booking.pax +
                booking.luggage +
                booking.pickup_address +
                booking.drop_off +
                booking.pick_airline_name +
                booking.pick_flight_number +
                booking.showstatus +
                booking.agent_name +
                booking.groupon +
                booking.showstatus +
                booking.driver_name +
                booking.vehicle_model +
                booking.contact_no +
                booking.currency +
                booking.price,

              ...(isMobile
                ? {
                    date: (
                      <div>
                        <div>
                          {formatDate(booking.pickup_date)}{" "}
                          <i className="fa fa-fw fa-briefcase"></i>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            padding: "0.75rem 0",
                            gap: "0.75rem",
                          }}
                        >
                          <div style={{ backgroundColor: "none" }}>
                            {(() => {
                              const formattedTime = formatTime(
                                booking.pickup_time
                              )
                              const [hour, mins, period] =
                                separateTimeParts(formattedTime)

                              return (
                                <>
                                  <div style={{ display: "flex" }}>
                                    <h1
                                      style={{
                                        fontSize: "4rem",
                                        fontWeight: "700",
                                        color: "#7F0605",
                                        margin: "0px",
                                      }}
                                    >
                                      {hour}
                                    </h1>

                                    <div
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "700",
                                        color: "#7F0605",
                                      }}
                                    >
                                      {mins} <br /> {period}
                                    </div>
                                  </div>
                                </>
                              )
                            })()}
                            <div
                              style={{
                                color: "#7F0605",
                                fontWeight: "600",
                                fontSize: "1rem",
                              }}
                            >
                              {isPickupFromAirport(booking.pickup_address) ||
                              isDropOffToHotel(booking.drop_off) ? (
                                <i
                                  style={{ transform: "rotate(-45deg)" }}
                                  className="fa fa-fw fa-plane deg45m"
                                ></i>
                              ) : (
                                <i className="fa fa-fw fa-hotel"></i>
                              )}
                              {booking.pick_airline_name}{" "}
                              {booking.pick_flight_number}
                            </div>
                            <br />
                            <div>
                              <i className="fa fa-fw fa-user"></i> {booking.pax}{" "}
                              -<i className="fa fa-fw fa-briefcase"></i>{" "}
                              {booking.luggage} -
                              <i className="fa fa-fw fa-baby"></i>{" "}
                              {booking.extra_item3_count === null ||
                              booking.extra_item2_count === null
                                ? 0
                                : (parseInt(booking.extra_item3_count) || 0) +
                                  (parseInt(booking.extra_item2_count) || 0)}
                            </div>
                          </div>
                          <div style={{ backgroundColor: "none" }}>
                            <strong>Pickup</strong>
                            <br />
                            {booking.pickup_address}
                            <br />
                            <strong>Dropoff</strong>
                            <br />
                            {booking.drop_off}
                            <br />
                            <br />
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              {booking.veh_type_name}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            borderTop: "1px solid #ccc",
                            paddingTop: "3px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                          }}
                        >
                          <div>
                            <i className="fa fa-fw fa-user"></i> {booking.name}
                            <br />
                            <i
                              style={{ transform: "rotate(-270deg)" }}
                              className="fa fa-fw fa-phone"
                            ></i>{" "}
                            <a
                              style={{ color: "inherit" }}
                              href={"tel:" + booking.mobile}
                            >
                              {booking.mobile}
                            </a>
                            <br />
                            <i className="fa fa-fw fa-car"></i>{" "}
                            {booking.driver_name !== null ? (
                              <span>{booking.driver_name}</span>
                            ) : (
                              <span>No Driver Found</span>
                            )}{" "}
                            / {booking.veh_type_name}
                          </div>
                          <div className="flex flex-col align-items-end">
                            {booking.payment_type === "credit card" ? (
                              <>
                                <span
                                  className="paid p-1 px-2"
                                  style={{
                                    color: "#34c38f",
                                    backgroundColor: "rgba(52, 195, 143, 0.18)",
                                    borderRadius: "6px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Paid
                                </span>
                                <br />{" "}
                                <span className="fw-bold">
                                  {booking.payment_type}
                                </span>
                              </>
                            ) : booking.showstatus === "Cancelled" &&
                              booking.payment_type === "stripe" ? (
                              <>
                                <span
                                  className="paid p-1 px-2 mr-1"
                                  style={{
                                    color: "#34c38f",
                                    backgroundColor: "rgba(52, 195, 143, 0.18)",
                                    borderRadius: "6px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Paid
                                </span>
                                <span
                                  className="refunded p-1 px-2"
                                  style={{
                                    color: "#f1b44c",
                                    backgroundColor: "rgba(241, 180, 76, 0.18)",
                                    borderRadius: "6px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Refunded
                                </span>
                                <br />{" "}
                                <span className="fw-bold">
                                  {booking.payment_type}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="fw-bold">
                                  {booking.payment_type}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#4CAF50",
                            color: "#ffffff",
                            padding: "2px 10px",
                          }}
                        >
                          <small>
                            {booking.agent_name} | Supplier Conf no -
                            {booking.groupon} |{" "}
                            <span
                              style={{
                                margin: "0px",
                                textTransform: "uppercase",
                                fontWeight: "600",
                              }}
                            >
                              {booking.currency} {booking.price}
                            </span>
                          </small>
                        </div>
                      </div>
                    ),
                  }
                : {}),
            })),
          ]

          setData(updatedData) // Update the state with the updated data array
        }
      })
      .catch(error => {
        // Handle the error
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false) // Set loading state to false after fetching data
      })
  }
  useEffect(() => {
    fetchData()
  }, [])
  //   console.log("data", data)

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Conf#",
        accessor: "conf",
      },
      {
        Header: "Pickup",
        accessor: "pickup",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Pickup - Dropoff",
        accessor: "pickupdropoff",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "DRIVER & VEHICLE",
        accessor: "drivervehicle",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Alldata",
        accessor: "allData",
      },
    ]

    if (isMobile) {
      // Remove unnecessary columns for mobile
      const mobileColumns = baseColumns.filter(
        column =>
          column.accessor !== "conf" &&
          column.accessor !== "pickup" &&
          column.accessor !== "name" &&
          column.accessor !== "pickupdropoff" &&
          column.accessor !== "status" &&
          column.accessor !== "drivervehicle" &&
          column.accessor !== "price"
      )

      // Add a new "mobile" column
      mobileColumns.unshift({
        Header: "Date",
        accessor: "date",
      })

      return mobileColumns
    }

    return baseColumns
  }, [isMobile])

  //meta title
  document.title = "Post Data Table | Flymiles"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Post Bookings" />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <div className="flex-shrink-0">
            {/* <Link
              to="#!"
              // onClick={() => setModal(true)}
              className="btn btn-primary me-1"
            >
              Add New Job
            </Link> */}
            <Link
              to="/post-datatable"
              className="btn btn-light me-1"
              onClick={() => window.reload()}
            >
              <i className="mdi mdi-refresh"></i>
            </Link>
            <UncontrolledDropdown className="dropdown d-inline-block me-1">
              <DropdownToggle
                type="menu"
                className="btn btn-success"
                id="dropdownMenuButton1"
              >
                <i className="mdi mdi-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu>
                <li>
                  <DropdownItem href="#" onClick={() => window.print()}>
                    Print
                  </DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          columns={columns}
          data={isLoading ? [] : data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
        {isLoading && <div style={{ fontWeight: "bold" }}>Loading Data...</div>}
      </div>
    </div>
  )
}
DatatableTables2.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables2
