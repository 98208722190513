import React, { useEffect, useState } from "react"
import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

const predefinedRestrictions = [
  {
    id: "1",
    question: "Not recommended for travelers with back problems",
    answer: false,
  },
  {
    id: "2",
    question: "Not recommended for pregnant travelers",
    answer: false,
  },
  {
    id: "3",
    question:
      "Not recommended for travelers with heart problems or other serious medical conditions",
    answer: false,
  },
  // Add more predefined restrictions if needed
]

const HealthRestrictionsForm = ({ onChange, data }) => {
  const [healthRestrictions, setHealthRestrictions] = useState([])

  useEffect(() => {
    // Convert incoming data to expected format and include additional checks
    const incomingRestrictions =
      data?.map(d => ({
        id: d.id,
        question: d.title,
        answer: d.check_uncheck === "yes",
      })) || []

    // Update predefined restrictions with answers from incoming data
    const updatedPredefinedRestrictions = predefinedRestrictions.map(pr => {
      const found = incomingRestrictions.find(ir => ir.question === pr.question)
      return found ? { ...pr, answer: found.answer } : pr
    })

    // Find additional restrictions in incoming data not present in predefined set
    const additionalRestrictions = incomingRestrictions.filter(
      ir => !predefinedRestrictions.some(pr => pr.question === ir.question)
    )

    // Merge updated predefined restrictions with any additional restrictions
    const mergedRestrictions = [
      ...updatedPredefinedRestrictions,
      ...additionalRestrictions,
    ]

    setHealthRestrictions(mergedRestrictions)
  }, [data])

  const handleChange = (id, checked) => {
    setHealthRestrictions(current =>
      current.map(restriction =>
        restriction.id === id
          ? { ...restriction, answer: checked }
          : restriction
      )
    )
  }

  const [newRestrictionText, setNewRestrictionText] = useState("")
  const [showNewRestrictionInput, setShowNewRestrictionInput] = useState(false)

  const handleAddNewOption = () => {
    if (newRestrictionText.trim()) {
      const newId = `new-${healthRestrictions.length + 1}`
      setHealthRestrictions(current => [
        ...current,
        { id: newId, question: newRestrictionText, answer: "" },
      ])
      setNewRestrictionText("")
      setShowNewRestrictionInput(false)
    }
  }

  useEffect(() => {
    // console.log(healthRestrictions)

    onChange(healthRestrictions)
    // console.log(healthRestrictions)
  }, [healthRestrictions, onChange])

  return (
    <TabPane tabId="healthRestrictions">
      <h2>Inclusions</h2>
      {healthRestrictions.map(item => (
        <FormGroup key={item.id}>
          <Label>
            <Input
              type="checkbox"
              name={`restriction-${item.id}`}
              value="yes"
              // checked={item.answer === "yes" ? true : false}
              // onChange={() => handleChange(item.id, "yes")}
              checked={item.answer === true ? true : false}
              onChange={e => handleChange(item.id, e.target.checked)}
              onClick={e => {
                e.preventDefault()
                // if (e.target.checked == true || item.answer === "yes") {
                //   handleChange(item.id, (e.target.checked = false))
                // } else if (e.target.checked == false) {
                //   handleChange(item.id, (e.target.checked = "yes"))
                // }
                if (item.answer === false) {
                  handleChange(item.id, (e.target.checked = true))
                } else {
                  handleChange(item.id, (e.target.checked = false))
                }
              }}
            />{" "}
            {item.question}
          </Label>
        </FormGroup>
      ))}
      {/* {healthRestrictions.map(restriction => (
        <FormGroup check key={restriction.id}>
          <Label check>
            <Input
              type="checkbox"
              checked={restriction.checked}
              onChange={e =>
                handleCheckboxChange(restriction.id, e.target.checked)
              }
            />
            {restriction.question}
          </Label>
        </FormGroup>
      ))} */}

      {showNewRestrictionInput ? (
        <>
          <FormGroup>
            <Label>New Inclusions:</Label>
            <Input
              type="text"
              value={newRestrictionText}
              onChange={e => setNewRestrictionText(e.target.value)}
              placeholder="Enter a new health restriction"
            />
          </FormGroup>
          <Button color="success" onClick={handleAddNewOption}>
            Add
          </Button>
        </>
      ) : (
        <Button
          color="primary"
          onClick={() => setShowNewRestrictionInput(true)}
        >
          + Add New Inclusions
        </Button>
      )}
    </TabPane>
  )
}

export default HealthRestrictionsForm
