// import React, { useEffect, useState } from "react"
// import { Label, Input, Button } from "reactstrap"

// const ImageUploadComponent = ({ handleMainImage, data }) => {
//   const [mainImage, setMainImage] = useState(null)
//   // console.log(mainImage?.image)
//   // const value =
//   // onChange(value)
//   // console.log("kk", mainImage)
//   const handleMainImageChange = event => {
//     const file = event.target.files[0]
//     if (file) {
//       // Create a new FileReader instance
//       const reader = new FileReader()

//       // Set up onload event handler to get the Base64 string once reading is complete
//       reader.onload = loadEvent => {
//         const base64String = loadEvent.target.result
//         // Now base64String contains the image in Base64
//         setMainImage({
//           preview: base64String, // Use the Base64 string for the preview
//           file: file,
//         })
//       }

//       // Read the file as a data URL (which will be a Base64-encoded string)
//       reader.readAsDataURL(file)
//     }
//   }

//   useEffect(() => {
//     // Only call onChange when mainImage changes
//     if (mainImage?.preview) {
//       handleMainImage([{ image: mainImage?.preview, type: "main" }])
//     }
//   }, [mainImage, handleMainImage, handleMainImageChange])

//   const deleteMainImage = () => {
//     if (mainImage && mainImage.preview) {
//       URL.revokeObjectURL(mainImage.preview)
//     }
//     setMainImage(null) // This should hide the image and close button
//   }

//   return (
//     <div style={{ marginTop: 10 }}>
//       {mainImage !== null ? (
//         <div
//           style={{
//             position: "relative",
//             display: "inline-block",
//             marginTop: "0px",
//           }}
//         >
//           <img
//             src={mainImage?.preview}
//             alt="Main product"
//             style={{ maxWidth: "200px", height: "auto", display: "block" }}
//           />
//           <Button
//             close
//             style={{ position: "absolute", top: "0", right: "0" }}
//             onClick={deleteMainImage}
//           />
//         </div>
//       ) : (
//         <div>
//           <Label htmlFor="product-main-image" style={{ cursor: "pointer" }}>
//             {/* Display the upload box or the image */}
//             <div
//               style={{
//                 border: "2px dashed #ddd",
//                 padding: "30px",
//                 marginBottom: "10px",
//                 position: "relative",
//                 textAlign: "center",
//                 color: "#aaa",
//                 display: mainImage ? "none" : "block",
//               }}
//             >
//               {!mainImage && (
//                 <>
//                   <i className="fa fa-upload" style={{ fontSize: "24px" }}></i>
//                   <p>Drag and drop files here or click to browse</p>
//                   <p>Maximum file size is 100MB.</p>
//                 </>
//               )}
//             </div>
//           </Label>
//           <Input
//             type="file"
//             className="form-control"
//             id="product-main-image"
//             accept="image/*"
//             onChange={handleMainImageChange}
//             style={{ display: "none" }}
//           />
//         </div>
//       )}
//     </div>
//   )
// }

// export default ImageUploadComponent

import React, { useEffect, useState } from "react"
import { Label, Input, Button } from "reactstrap"

const ImageUploadComponent = ({ handleMainImage, data }) => {
  const [mainImage, setMainImage] = useState({
    preview: "",
    file: null,
  })

  // Initialize component with existing image data if available
  useEffect(() => {
    if (data && data.length > 0) {
      const existingImage = data[0] // Assuming the first image is the main image
      const imageUrl = `https://login.flymiles.com.au/api_sections/tours/tour_img/${existingImage.image}` // Adjust based on how you serve images
      setMainImage({
        preview: imageUrl, // Use URL/path for the preview
        file: null, // No file initially since it's an existing image
      })
    }
  }, [data])

  const handleMainImageChange = event => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()

      reader.onload = loadEvent => {
        const base64String = loadEvent.target.result
        setMainImage({
          preview: base64String,
          file: file,
        })
      }

      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    // Call onChange only if there's a file selected (new or existing)
    if (mainImage.file || mainImage.preview) {
      handleMainImage([{ image: mainImage.preview, type: "main" }])
    }
  }, [mainImage])

  const deleteMainImage = () => {
    if (mainImage && mainImage.preview) {
      URL.revokeObjectURL(mainImage.preview)
    }
    setMainImage({
      preview: "",
      file: null,
    }) // This should hide the image and close button
  }

  return (
    <div style={{ marginTop: 10 }}>
      {mainImage.preview ? (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            marginTop: "0px",
          }}
        >
          <img
            src={mainImage.preview}
            alt="Main product"
            style={{ maxWidth: "200px", height: "auto", display: "block" }}
          />
          <Button
            close
            style={{ position: "absolute", top: "0", right: "0" }}
            onClick={deleteMainImage}
          />
        </div>
      ) : (
        <Label htmlFor="product-main-image" style={{ cursor: "pointer" }}>
          <div
            style={{
              border: "2px dashed #ddd",
              padding: "30px",
              marginBottom: "10px",
              position: "relative",
              textAlign: "center",
              color: "#aaa",
            }}
          >
            <i className="fa fa-upload" style={{ fontSize: "24px" }}></i>
            <p>Drag and drop files here or click to browse</p>
            <p>Maximum file size is 500KB.</p>
          </div>
        </Label>
      )}
      <Input
        type="file"
        className="form-control"
        id="product-main-image"
        accept="image/*"
        onChange={handleMainImageChange}
        style={{ display: "none" }}
      />
    </div>
  )
}

export default ImageUploadComponent
