import React, { useState } from "react"
import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

const ItineraryDay = ({ day, onRemove, onChange }) => {
  return (
    <div>
      <FormGroup>
        <Label>Header:</Label>
        <Input
          type="text"
          value={day.header}
          onChange={e => onChange(day.id, "header", e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Body:</Label>
        <Input
          type="textarea"
          value={day.body}
          onChange={e => onChange(day.id, "body", e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Duration:</Label>
        <Input
          type="text"
          value={day.duration}
          onChange={e => onChange(day.id, "duration", e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Includes:</Label>
        <Input
          type="text"
          value={day.includes}
          onChange={e => onChange(day.id, "includes", e.target.value)}
        />
      </FormGroup>
      <Button color="danger" onClick={() => onRemove(day.id)}>
        Delete Day
      </Button>
      <hr />
    </div>
  )
}

const DayWiseItinerary = () => {
  const [days, setDays] = useState([])

  console.log("dd", days)
  const addDay = () => {
    const newDay = {
      id: days.length + 1,
      header: "",
      body: "",
      duration: "",
      includes: "",
    }
    setDays([...days, newDay])
  }

  const removeDay = dayId => {
    setDays(days.filter(day => day.id !== dayId))
  }

  const updateDay = (dayId, field, value) => {
    setDays(
      days.map(day => {
        if (day.id === dayId) {
          return { ...day, [field]: value }
        }
        return day
      })
    )
  }

  return (
    <TabPane tabId={3}>
      {days.map(day => (
        <ItineraryDay
          key={day.id}
          day={day}
          onRemove={removeDay}
          onChange={updateDay}
        />
      ))}
      <Button color="primary" onClick={addDay}>
        Add Day
      </Button>
    </TabPane>
  )
}

export default DayWiseItinerary
