import React, { useEffect, useState } from "react"
import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

// Step 1: Define predefined questions
const predefinedQuestions = [
  { id: 1, question: "Is it Wheelchair accessible?", answer: "" },
  { id: 2, question: "Is it stroller accessible?", answer: "" },
  { id: 3, question: "Are service animals allowed?", answer: "" },
  {
    id: 4,
    question: "Can travelers easily arrive/depart on public transportation?",
    answer: "",
  },
  { id: 5, question: "Are infants required to sit on laps?", answer: "" },
  { id: 6, question: "Are infant seats available?", answer: "" },
]

const AccessibilityForm = ({ onChange, data }) => {
  const [accessibilityQuestions, setAccessibilityQuestions] = useState([])

  useEffect(() => {
    const incomingQuestions =
      data?.map(d => ({
        id: d.id,
        question: d.title,
        answer: d.yes_no,
      })) || []

    const updatedPredefinedQuestions = predefinedQuestions.map(pq => {
      const found = incomingQuestions.find(iq => iq.question === pq.question)
      return found ? { ...pq, answer: found.answer } : pq
    })

    const additionalQuestions = incomingQuestions.filter(
      iq => !predefinedQuestions.some(pq => pq.question === iq.question)
    )

    const mergedQuestions = [
      ...updatedPredefinedQuestions,
      ...additionalQuestions,
    ]

    setAccessibilityQuestions(mergedQuestions)
  }, [data])

  const handleChange = (id, value) => {
    setAccessibilityQuestions(current =>
      current.map(q => (q.id === id ? { ...q, answer: value } : q))
    )
  }

  const [newQuestionText, setNewQuestionText] = useState("")
  const [showNewQuestionInput, setShowNewQuestionInput] = useState(false)

  const handleAddNewOption = () => {
    if (newQuestionText.trim()) {
      const newId =
        Math.max(0, ...accessibilityQuestions.map(q => Number(q.id))) + 1
      setAccessibilityQuestions(current => [
        ...current,
        { id: `new-${newId}`, question: newQuestionText, answer: "" },
      ])
      setNewQuestionText("")
      setShowNewQuestionInput(false)
    }
  }

  const handleShowNewQuestionInput = () => {
    setShowNewQuestionInput(true)
  }

  const handleResetQuestion = id => {
    setAccessibilityQuestions(current =>
      current.map(q => (q.id === id ? { ...q, answer: "" } : q))
    )
  }

  useEffect(() => {
    onChange(accessibilityQuestions)
  }, [accessibilityQuestions])

  return (
    <TabPane tabId="4">
      <h2>What should travelers know before they book?</h2>
      <p>
        This information will help travelers know if this is a good tour for
        them.
      </p>

      {accessibilityQuestions.map(item => (
        <FormGroup
          key={item.id}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Label>{item.question}</Label>
          <div style={{ display: "flex", gap: 20 }}>
            <div>
              <Input
                type="radio"
                name={`question-${item.id}`} // The 'name' should be the same for both radio buttons in a group
                value="yes"
                checked={item.answer === "yes"}
                onChange={() => handleChange(item.id, "yes")}
                onClick={e => {
                  e.preventDefault()
                  handleChange(item.id, "yes")
                }}
              />{" "}
              Yes
            </div>
            <div>
              <Input
                type="radio"
                name={`question-${item.id}`} // The 'name' should be the same for both radio buttons in a group
                value="no"
                checked={item.answer === "no"}
                onChange={() => handleChange(item.id, "no")}
                onClick={e => {
                  e.preventDefault()
                  handleChange(item.id, "no")
                }}
              />{" "}
              No
            </div>
            <Button
              className="btn btn-sm btn-soft-danger"
              onClick={() => handleResetQuestion(item.id)}
            >
              <i className="mdi mdi-delete-outline" id="edittooltip" />{" "}
            </Button>
          </div>
        </FormGroup>
      ))}

      {showNewQuestionInput ? (
        <>
          <FormGroup>
            <Label>New Question:</Label>
            <Input
              type="text"
              value={newQuestionText}
              onChange={e => setNewQuestionText(e.target.value)}
              placeholder="Enter a new accessibility question"
            />
          </FormGroup>
          <Button color="success" onClick={handleAddNewOption}>
            Save
          </Button>
        </>
      ) : (
        <Button color="primary" onClick={handleShowNewQuestionInput}>
          + Add another
        </Button>
      )}
    </TabPane>
  )
}

export default AccessibilityForm
