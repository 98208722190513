import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "pages/JobPages/JobList/JobListCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
// import { JobNo } from "pages/JobPages/JobList/JobListCol"

function PackagesCountry() {
  //meta title
  document.title = "Country | Flymiles"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [jobsList, setJobsList] = useState([])
  const [job, setJob] = useState(null)

  const [countries, setCountries] = useState(null)
  const [dataChanged, setDataChanged] = useState(false)

  // const fetchCountries = async () => {
  //   const response = await fetch(
  //     "https://login.flymiles.com.au/api_sections/packages/get_country_city_list.php",
  //     {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ get_country: "Yes" }),
  //     }
  //   )
  //   const data = await response?.json()
  //   console.log(data)

  //   // Check if the fetched data is not empty
  //   if (data && Array.isArray(data) && data.length > 0) {
  //     setCountries(data)
  //     setJobsList({
  //       id: data.map(id => id.id),
  //       jobTitle: data.map(country => country.country),
  //     })
  //     // setJob(data)
  //   }
  // }

  const fetchCountries = async () => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/packages/get_country_city_list.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ get_country: "Yes", own_page: "yes" }),
      }
    )
    const data = await response.json()
    setCountries(data || [])
  }

  useEffect(() => {
    fetchCountries()
  }, [dataChanged])

  // useEffect(() => {
  //   fetchCountries()
  // }, [])

  // useEffect(() => {
  //   if (countries.length > 0) {
  //     // Assuming you have a function to determine jobs based on countries
  //     const newJobs = countries
  //     setJob(newJobs)
  //   }
  // setJob(countries)
  // }, [])
  console.log("cc", countries, job)
  const handleSave = async country => {
    // Perform your save operation here
    // For demonstration, let's just log to console and pretend we're saving
    console.log("Saving country:", country)

    // After save operation, toggle dataChanged to trigger re-fetch
    setDataChanged(!dataChanged)

    // Close modal
    setModal(false)
  }

  // console.log("data", countryCityList)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      jobTitle: (job && job.jobTitle) || "",
      // companyName: (job && job.companyName) || "",
      // location: (job && job.location) || "",
      // experience: (job && job.experience) || "",
      // position: (job && job.position) || "",
      // type: (job && job.type) || "",
      // status: (job && job.status) || "",
    },
    validationSchema: Yup.object({
      // jobId: Yup.string()
      //   .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Job Id")
      //   .required("Please Enter Your Job Id"),
      jobTitle: Yup.string().required("Please Enter Your Job Title"),
      // companyName: Yup.string().required("Please Enter Your Company Name"),
      // location: Yup.string().required("Please Enter Your Location"),
      // experience: Yup.string().required("Please Enter Your Experience"),
      // position: Yup.string().required("Please Enter Your Position"),
      // type: Yup.string().required("Please Enter Your Type"),
      // status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const payload = {
          country_id: job.id, // Assuming 'job.id' holds the country ID you want to update
          country: values.jobTitle, // Assuming 'jobTitle' is the updated country name
        }
        console.log(values.jobTitle, job.jobTitle) //
        const updateJobList = {
          id: job ? job.id : 0,
          // jobId: values.jobId,
          jobTitle: values.jobTitle,
          // companyName: values.companyName,
          // location: values.location,
          // experience: values.experience,
          // position: values.position,
          // type: values.type,
          // postedDate: "02 June 2021",
          // lastDate: "25 June 2021",
          // status: values.status,
        }

        fetch(
          "https://login.flymiles.com.au/api_sections/packages/update_country.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other headers as required by your API
            },
            body: JSON.stringify(payload),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // Handle success response
            // You might want to update your local state or Redux store based on the response
            // For example, refreshing the country list to reflect the updated data
            validation.resetForm()
            toggle()
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle errors here, possibly showing an error message to the user
          })

        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const payload = {
          country: values.jobTitle, // Assuming the jobTitle field is used for country name
        }

        const newJobList = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          // jobId: values["jobId"],
          jobTitle: values["jobTitle"],
          // companyName: values["companyName"],
          // location: values["location"],
          // experience: values["experience"],
          // position: values["position"],
          // type: values["type"],
          // postedDate: "02 June 2021",
          // lastDate: "25 June 2021",
          // status: values["status"],
        }

        fetch(
          "https://login.flymiles.com.au/api_sections/packages/add_country.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other headers as required by the API
            },
            body: JSON.stringify(payload),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // Handle success response
            // You might want to update your local state or Redux store based on the response
            // For example, refetching the country list if the API does not automatically do it
            validation.resetForm()
            toggle()
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle errors here
          })

        // save new Job
        dispatch(onAddNewJobList(newJobList))
        validation.resetForm()
      }
      toggle()
    },
  })

  // console.log(validation.values.jobTitle)
  // Call fetchCountryCityList inside useEffect to load data when the component mounts
  // useEffect(() => {
  //   fetchCountryCityList()
  // }, [validation.onSubmit])

  const dispatch = useDispatch()
  const { jobs } = useSelector(state => ({
    jobs: state.JobReducer.jobs,
  }))

  useEffect(() => {
    if (jobs && !jobs.length) {
      dispatch(onGetJobList())
    }
  }, [dispatch, jobs])

  useEffect(() => {
    setJobsList(jobs)
  }, [jobs])

  useEffect(() => {
    if (!isEmpty(jobs) && !!isEdit) {
      setJobsList(jobs)
      setIsEdit(false)
    }
  }, [jobs])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  const handleJobClick = arg => {
    const job = arg

    if (job) {
      const selectedCountry = countries?.find(country => country.id == job.id)
      // const selectedId = countries?.find(id => country.id == job.id)

      console.log("ss", selectedCountry)
      setJob({
        id: job.id,
        jobId: job.id,
        jobTitle: job.country,
      })

      setIsEdit(true)

      toggle()
    }
    //  else {
    // setIsEdit(false), isEdit(false)
    // }
  }
  const handleEnableDisableCountry = async (countryId, status) => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/packages/disable_enable_country.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country_id: countryId,
          disbale_disable: status,
        }),
      }
    )
    const data = await response.json()
    console.log(data) // Handle the response if necessary
    fetchCountries() // Refresh the list after the change
  }

  // console.log(job.jobId)
  // useEffect(() => {
  //   handleJobClick()
  // }, [handleJobClick, countries])

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setJob(job)
    setDeleteModal(true)
    // toggle()
  }

  const handleDeletejob = () => {
    if (job && job.id) {
      dispatch(onDeleteJobList(job.id))
      setDeleteModal(false)
    }
  }
  const handleJobClicks = () => {
    setJobsList("")
    setIsEdit(false)
    toggle()
  }

  const handleDeleteCountry = async () => {
    if (!job.id) return

    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/packages/delete_country.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ country_id: job.id }),
      }
    )

    console.log(job.id)
    const data = await response.json()
    console.log(data) // You might want to handle this depending on the response
    fetchCountries() // Refresh the list after deletion
    setDeleteModal(false)
  }

  const openDeleteModal = countryId => {
    setSelectedCountryId(countryId)
    setDeleteModal(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "country",
        filterable: true,
        // Cell: cellProps => {
        //   return <JobTitle {...cellProps} />
        // },
        Cell: ({ value }) => <>{value}</>, // Simplified for demonstration
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          const jobData = cellProps.row.original

          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    e.preventDefault()
                    const jobData = cellProps.row.original
                    handleJobClick(jobData)
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={`btn btn-sm btn-soft-${
                    jobData.disabled === "1" ? "success" : "success"
                  }`}
                  onClick={e => {
                    e.preventDefault()
                    handleEnableDisableCountry(
                      jobData.id,
                      jobData.disabled === "0" ? "1" : "0"
                    )
                  }}
                >
                  <i
                    className={`mdi mdi-${
                      jobData.disabled === "1"
                        ? "eye-off-outline"
                        : "eye-outline"
                    }`}
                    id="enableDisableTooltip"
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target="enableDisableTooltip"
                  >
                    Enable/Disable
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip
                    onClick={() => openDeleteModal(cellProps.original.id)}
                    placement="top"
                    target="deletetooltip"
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
      {
        Header: "No",
        accessor: "id",
        filterable: true,
        // Cell: cellProps => {
        //   return <JobNo {...cellProps} />
        // },
        Cell: ({ value }) => <>{value}</>, // Simplified for demonstration
      },
    ],
    []
  )

  if (!countries) {
    return <div>Loading countries...</div>
  } else {
    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCountry}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Packages" breadcrumbItem="Country Lists" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Country Lists
                      </h5>
                      <div className="flex-shrink-0">
                        <Link
                          to="#!"
                          onClick={() => setModal(true)}
                          className="btn btn-primary me-1"
                        >
                          Add New Country
                        </Link>
                        {/* <Link to="#!" className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                        <UncontrolledDropdown className="dropdown d-inline-block me-1">
                          <DropdownToggle
                            type="menu"
                            className="btn btn-success"
                            id="dropdownMenuButton1"
                          >
                            <i className="mdi mdi-dots-vertical"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <li>
                              <DropdownItem href="#">Action</DropdownItem>
                            </li>
                            <li>
                              <DropdownItem href="#">
                                Another action
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem href="#">
                                Something else here
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={countries}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      handleJobClicks={handleJobClicks}
                      isJobListGlobalFilter={true}
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {isEdit ? "Edit Country" : "Add Country"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">Country</Label>
                        <Input
                          name="jobTitle"
                          type="text"
                          placeholder="Insert Country Name"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.jobTitle || ""}
                          invalid={
                            validation.touched.jobTitle &&
                            validation.errors.jobTitle
                              ? true
                              : false
                          }
                        />
                        {validation.touched.jobTitle &&
                        validation.errors.jobTitle ? (
                          <FormFeedback type="invalid">
                            {validation.errors.jobTitle}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PackagesCountry
