// src/components/filter.
import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { useMediaQuery } from "react-responsive"
import * as Yup from "yup"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import TableContainer from "../../components/Common/TableContainer"
import { useState } from "react"
import { Link } from "react-router-dom"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  InputGroup,
  FormGroup,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

function DatatableTables() {
  const MOBILE_BREAKPOINT = "(max-width: 767px)"
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT })
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  // add new job

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [jobsList, setJobsList] = useState([])
  const [job, setJob] = useState(null)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      jobTitle: (job && job.jobTitle) || "",
      companyName: (job && job.companyName) || "",
      location: (job && job.location) || "",
      experience: (job && job.experience) || "",
      position: (job && job.position) || "",
      type: (job && job.type) || "",
      status: (job && job.status) || "",
    },
    validationSchema: Yup.object({
      jobId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Job Id")
        .required("Please Enter Your Job Id"),
      jobTitle: Yup.string().required("Please Enter Your Job Title"),
      companyName: Yup.string().required("Please Enter Your Company Name"),
      location: Yup.string().required("Please Enter Your Location"),
      experience: Yup.string().required("Please Enter Your Experience"),
      position: Yup.string().required("Please Enter Your Position"),
      type: Yup.string().required("Please Enter Your Type"),
      status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateJobList = {
          id: job ? job.id : 0,
          jobId: values.jobId,
          jobTitle: values.jobTitle,
          companyName: values.companyName,
          location: values.location,
          experience: values.experience,
          position: values.position,
          type: values.type,
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          status: values.status,
        }
        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const newJobList = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          jobId: values["jobId"],
          jobTitle: values["jobTitle"],
          companyName: values["companyName"],
          location: values["location"],
          experience: values["experience"],
          position: values["position"],
          type: values["type"],
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          status: values["status"],
        }
        // save new Job
        dispatch(onAddNewJobList(newJobList))
        validation.resetForm()
      }
      toggle()
    },
  })

  const dispatch = useDispatch()
  const { jobs } = useSelector(state => ({
    jobs: state.JobReducer.jobs,
  }))

  useEffect(() => {
    if (jobs && !jobs.length) {
      dispatch(onGetJobList())
    }
  }, [dispatch, jobs])

  useEffect(() => {
    setJobsList(jobs)
  }, [jobs])

  useEffect(() => {
    if (!isEmpty(jobs) && !!isEdit) {
      setJobsList(jobs)
      setIsEdit(false)
    }
  }, [jobs])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  const handleJobClick = arg => {
    const job = arg
    setJob({
      id: job.id,
      jobId: job.jobId,
      jobTitle: job.jobTitle,
      companyName: job.companyName,
      location: job.location,
      experience: job.experience,
      position: job.position,
      type: job.type,
      status: job.status,
    })

    setIsEdit(true)

    toggle()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setJob(job)
    setDeleteModal(true)
  }

  const handleDeletejob = () => {
    if (job && job.id) {
      dispatch(onDeleteJobList(job.id))
      setDeleteModal(false)
    }
  }
  const handleJobClicks = () => {
    setJobsList("")
    setIsEdit(false)
    toggle()
  }

  // end add new job

  // form
  const [data_attr, setdata_attr] = useState(0)
  const [selectedMulti2, setselectedMulti2] = useState(null)
  const [textareabadge, settextareabadge] = useState(0)
  const [rows1, setrows1] = useState([{ id: 1 }])

  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2)
  }
  const optionGroup1 = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  function textareachange(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }

  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
    }
  }
  // form end
  function getButtonColor(showstatus) {
    if (showstatus == "Schedule") {
      return "yellow"
    } else if (showstatus == "Confirmed") {
      return "green"
    } else if (showstatus == "Cancelled") {
      return "red"
    }
    return "blue" // Default color if none of the conditions match
  }
  const formatDate = date => {
    return moment(date).format("DD/MM/YYYY")
  }
  const isPickupFromAirport = pickupAddress => {
    return pickupAddress?.includes("Airport", "Terminal")
  }
  const isDropOffToHotel = pickupAddress => {
    return pickupAddress?.includes("Hotel")
  }
  function formatTime(pickupTime) {
    const dummyDate = "1970-01-01"
    const timeString = dummyDate + "T" + pickupTime
    const time = new Date(timeString)
    let hour = time.getHours()
    let minute = time.getMinutes()
    const period = hour >= 12 ? "PM" : "AM"
    hour = hour % 12 === 0 ? 12 : hour % 12
    hour = hour < 10 ? "0" + hour : hour // Add leading zero if less than 10
    minute = minute < 10 ? "0" + minute : minute // Add leading zero if less than 10

    return `${hour}:${minute} ${period}`
  }
  function separateTimeParts(formattedTime) {
    const hour = formattedTime.split(":")[0].trim()
    const remainingTime = formattedTime.substring(hour.length).trim()
    const mins = remainingTime.split(" ")[0].trim()
    const period = remainingTime.substring(mins.length).trim()
    return [hour, mins, period]
  }
  // handlePrintClick = () => {
  //   window.print()
  // }
  const fetchData = () => {
    setIsLoading(true)
    const storeToken = localStorage.getItem("apiToken")
    const value = JSON.parse(storeToken)
    const token = value.API
    // console.log(token)
    return fetch("https://flymiles.com.au/adminapi/public/api/booking/1", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(apidata => {
        // apisetData(data)
        console.log(apidata)
        if (
          apidata.success &&
          apidata.bookings &&
          apidata.bookings.length > 0
        ) {
          const updatedData = [
            ...data, // Keep the existing data
            ...apidata?.bookings.slice(0, apidata.length).map(booking => ({
              conf: (
                <div
                  style={{
                    color: "#2196f3",
                  }}
                >
                  {booking.reservationid}
                </div>
              ),
              pickup: (
                <div>
                  <strong>Pickup:</strong>
                  <br />
                  {formatDate(booking.pickup_date)}
                  <br />
                  {formatTime(booking.pickup_time)}
                  <br />
                  <i className="fa fa-fw fa-clock"></i> {booking.duration}
                  {" Min"}
                  <br />
                  <i className="fa fa-fw fa-tachometer-alt"></i>{" "}
                  {booking.distance}
                  {" km"}
                </div>
              ),
              name: (
                <div>
                  <span
                    style={{ textTransform: "uppercase", color: "#F44436" }}
                  >
                    {booking.name}
                  </span>
                  <br />
                  <a
                    style={{ color: "inherit" }}
                    href={"tel:" + booking.mobile}
                  >
                    {booking.mobile}
                  </a>
                  <br />
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {booking.veh_type_name}
                  </span>
                  <br />
                  <i className="fa fa-fw fa-user"></i>
                  {booking.pax} -<i className="fa fa-fw fa-briefcase"></i>
                  {booking.luggage} -<i className="fa fa-fw fa-baby"></i>
                  {booking.extra_item3_count === null ||
                  booking.extra_item2_count === null
                    ? 0
                    : (parseInt(booking.extra_item3_count) || 0) +
                      (parseInt(booking.extra_item2_count) || 0)}
                </div>
              ),
              pickupdropoff: (
                <div>
                  <strong>Pickup</strong>

                  <br />
                  {booking.pickup_address}
                  <br />
                  <strong>Dropoff</strong>

                  <br />
                  {booking.drop_off}
                  <br />
                  {booking.pickup_stop1 && (
                    <p className="m-0">
                      <strong>Stop1:</strong> {booking.pickup_stop1}
                    </p>
                  )}
                  {booking.pickup_stop2 && (
                    <p className="m-0">
                      <strong>Stop2:</strong> {booking.pickup_stop2}
                    </p>
                  )}
                  {booking.pickup_stop3 && (
                    <p className="m-0">
                      <strong>Stop3:</strong> {booking.pickup_stop3}
                    </p>
                  )}
                  <div
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "#ffffff",
                      padding: "2px 10px",
                    }}
                  >
                    {isPickupFromAirport(booking.pickup_address) ||
                    isDropOffToHotel(booking.drop_off) ? (
                      <i
                        style={{ transform: "rotate(-45deg)" }}
                        className="fa fa-fw fa-plane deg45m"
                      ></i>
                    ) : (
                      <i className="fa fa-fw fa-hotel"></i>
                    )}
                    {/* <i
                      style={{ transform: "rotate(-45deg)" }}
                      className="fa fa-fw fa-plane deg45m"
                    ></i> */}
                    {booking.pick_airline_name} {booking.pick_flight_number}{" "}
                    {booking.showstatus} |
                    <small>
                      {" "}
                      {booking.agent_name} | Supplier Conf no -{booking.groupon}
                    </small>
                  </div>
                </div>
              ),
              status: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      borderRadius: "2px",
                      padding: "5px 15px",
                      boxShadow: "-1px 1px 3px rgba(0, 0, 0, 0.4)",
                      backgroundColor: getButtonColor(booking.showstatus),
                    }}
                  >
                    {booking.showstatus}
                  </button>
                </div>
              ),
              drivervehicle: (
                <div>
                  <i className="fa fa-fw fa-user"></i> {booking.driver_name}
                  <br />
                  <i className="fa fa-fw fa-car"></i> {booking.vehicle_model}
                  <br />
                  <i className="fa fa-fw fa-phone"></i> {booking.contact_no}
                  <br />{" "}
                  {booking.payment_type === null ? (
                    <>
                      <i className="fa fa-fw fa-file-invoice"></i>
                      <span> invoice </span>
                      <br />
                      <span
                        className="refunded p-1 px-2"
                        style={{
                          color: "#f1b44c",
                          backgroundColor: "rgba(241, 180, 76, 0.18)",
                          borderRadius: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        Unpaid
                      </span>
                    </>
                  ) : booking.payment_type === "invoices" || "cash collect" ? (
                    <>
                      <i className="fa fa-fw fa-file-invoice"></i>
                      {booking.payment_type}
                      <br />
                      <span
                        className="paid p-1 px-2"
                        style={{
                          color: "#34c38f",
                          backgroundColor: "rgba(52, 195, 143, 0.18)",
                          borderRadius: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        Paid
                      </span>
                    </>
                  ) : booking.showstatus === "Cancelled" &&
                    booking.payment_type === "stripe" ? (
                    <>
                      <i className="fa fa-fw fa-credit-card"></i>
                      {booking.payment_type}
                      <span
                        className="paid p-1 px-2 mr-1"
                        style={{
                          color: "#34c38f",
                          backgroundColor: "rgba(52, 195, 143, 0.18)",
                          borderRadius: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        Paid
                      </span>
                      <span
                        className="refunded p-1 px-2"
                        style={{
                          color: "#f1b44c",
                          backgroundColor: "rgba(241, 180, 76, 0.18)",
                          borderRadius: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        Refunded
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <br />
                  <br />
                  <Link to={`/edit/${booking.id}`}>
                    <button className="btn btn-success pb-1 px-4 font-bold">
                      Edit
                    </button>
                  </Link>
                </div>
              ),
              price: (
                <div>
                  <span
                    style={{
                      margin: "0px",
                      textTransform: "uppercase",
                      fontWeight: "600",
                    }}
                  >
                    {booking.currency}
                  </span>{" "}
                  <br /> {booking.price}
                </div>
              ),
              allData:
                booking.reservationid +
                formatTime(booking.pickup_time) +
                formatDate(booking.pickup_date) +
                booking.duration +
                booking.distance +
                booking.name +
                booking.mobile +
                booking.veh_type_name +
                booking.pax +
                booking.luggage +
                booking.pickup_address +
                booking.drop_off +
                booking.pick_airline_name +
                booking.pick_flight_number +
                booking.showstatus +
                booking.agent_name +
                booking.groupon +
                booking.showstatus +
                booking.driver_name +
                booking.vehicle_model +
                booking.contact_no +
                booking.currency +
                booking.price,

              ...(isMobile
                ? {
                    date: (
                      <div>
                        <div className="d-flex justify-content-between align-items-end">
                          <div>
                            {formatDate(booking.pickup_date)}{" "}
                            <i className="fa fa-fw fa-briefcase"></i>
                          </div>
                          <div>
                            <Link to={`/edit/${booking.id}`}>
                              <button className="btn btn-success pb-1 mb-1 px-4 font-bold">
                                Edit
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            padding: "0.75rem 0",
                            gap: "0.75rem",
                          }}
                        >
                          <div style={{ backgroundColor: "none" }}>
                            {(() => {
                              const formattedTime = formatTime(
                                booking.pickup_time
                              )
                              const [hour, mins, period] =
                                separateTimeParts(formattedTime)

                              return (
                                <>
                                  <div style={{ display: "flex" }}>
                                    <h1
                                      style={{
                                        fontSize: "4rem",
                                        fontWeight: "700",
                                        color: "#7F0605",
                                        margin: "0px",
                                      }}
                                    >
                                      {hour}
                                    </h1>

                                    <div
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "700",
                                        color: "#7F0605",
                                      }}
                                    >
                                      {mins} <br /> {period}
                                    </div>
                                  </div>
                                </>
                              )
                            })()}
                            <div
                              style={{
                                color: "#7F0605",
                                fontWeight: "600",
                                fontSize: "1rem",
                              }}
                            >
                              {isPickupFromAirport(booking.pickup_address) ||
                              isDropOffToHotel(booking.drop_off) ? (
                                <i
                                  style={{ transform: "rotate(-45deg)" }}
                                  className="fa fa-fw fa-plane deg45m"
                                ></i>
                              ) : (
                                <i className="fa fa-fw fa-hotel"></i>
                              )}
                              {booking.pick_airline_name}{" "}
                              {booking.pick_flight_number}
                            </div>
                            <br />
                            <div>
                              <i className="fa fa-fw fa-user"></i> {booking.pax}{" "}
                              -<i className="fa fa-fw fa-briefcase"></i>{" "}
                              {booking.luggage} -
                              <i className="fa fa-fw fa-baby"></i>{" "}
                              {booking.extra_item3_count === null ||
                              booking.extra_item2_count === null
                                ? 0
                                : (parseInt(booking.extra_item3_count) || 0) +
                                  (parseInt(booking.extra_item2_count) || 0)}
                            </div>
                          </div>
                          <div style={{ backgroundColor: "none" }}>
                            <strong>Pickup</strong>
                            <br />
                            {booking.pickup_address}
                            <br />
                            <strong>Dropoff</strong>
                            <br />
                            {booking.drop_off}
                            <br />
                            {booking.pickup_stop1 && (
                              <p className="m-0">
                                <strong>Stop1:</strong> {booking.pickup_stop1}
                              </p>
                            )}
                            {booking.pickup_stop2 && (
                              <p className="m-0">
                                <strong>Stop2:</strong> {booking.pickup_stop2}
                              </p>
                            )}
                            {booking.pickup_stop3 && (
                              <p className="m-0">
                                <strong>Stop3:</strong> {booking.pickup_stop3}
                              </p>
                            )}
                            <br />
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              {booking.veh_type_name}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            borderTop: "1px solid #ccc",
                            paddingTop: "3px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                          }}
                        >
                          <div>
                            <i className="fa fa-fw fa-user"></i> {booking.name}
                            <br />
                            <i
                              style={{ transform: "rotate(-270deg)" }}
                              className="fa fa-fw fa-phone"
                            ></i>{" "}
                            <a
                              style={{ color: "inherit" }}
                              href={"tel:" + booking.mobile}
                            >
                              {booking.mobile}
                            </a>
                            <br />
                            <i className="fa fa-fw fa-car"></i>{" "}
                            {booking.driver_name !== null ? (
                              <span>{booking.driver_name}</span>
                            ) : (
                              <span>No Driver Found</span>
                            )}{" "}
                            / {booking.veh_type_name}
                          </div>
                          <div className="flex flex-col align-items-end">
                            {booking.payment_type === "credit card" ? (
                              <>
                                <span
                                  className="paid p-1 px-2"
                                  style={{
                                    color: "#34c38f",
                                    backgroundColor: "rgba(52, 195, 143, 0.18)",
                                    borderRadius: "6px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Paid
                                </span>
                                <br />{" "}
                                <span className="fw-bold">
                                  {booking.payment_type}
                                </span>
                              </>
                            ) : booking.showstatus === "Cancelled" &&
                              booking.payment_type === "stripe" ? (
                              <>
                                <span
                                  className="paid p-1 px-2 mr-1"
                                  style={{
                                    color: "#34c38f",
                                    backgroundColor: "rgba(52, 195, 143, 0.18)",
                                    borderRadius: "6px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Paid
                                </span>
                                <span
                                  className="refunded p-1 px-2"
                                  style={{
                                    color: "#f1b44c",
                                    backgroundColor: "rgba(241, 180, 76, 0.18)",
                                    borderRadius: "6px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Refunded
                                </span>
                                <br />{" "}
                                <span className="fw-bold">
                                  {booking.payment_type}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="fw-bold">
                                  {booking.payment_type}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#4CAF50",
                            color: "#ffffff",
                            padding: "2px 10px",
                          }}
                        >
                          <small>
                            {booking.agent_name} | Supplier Conf no -
                            {booking.groupon} |{" "}
                            <span
                              style={{
                                margin: "0px",
                                textTransform: "uppercase",
                                fontWeight: "600",
                              }}
                            >
                              {booking.currency} {booking.price}
                            </span>
                          </small>
                        </div>
                      </div>
                    ),
                  }
                : {}),
            })),
          ]

          setData(updatedData) // Update the state with the updated data array
        }
      })
      .catch(error => {
        // Handle the error
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false) // Set loading state to false after fetching data
      })
  }
  useEffect(() => {
    fetchData()
  }, [])
  //   console.log("data", data)

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Conf#",
        accessor: "conf",
      },
      {
        Header: "Pickup",
        accessor: "pickup",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Pickup - Dropoff",
        accessor: "pickupdropoff",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "DRIVER & VEHICLE",
        accessor: "drivervehicle",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Alldata",
        accessor: "allData",
      },
    ]

    if (isMobile) {
      // Remove unnecessary columns for mobile
      const mobileColumns = baseColumns.filter(
        column =>
          column.accessor !== "conf" &&
          column.accessor !== "pickup" &&
          column.accessor !== "name" &&
          column.accessor !== "pickupdropoff" &&
          column.accessor !== "status" &&
          column.accessor !== "drivervehicle" &&
          column.accessor !== "price"
      )

      // Add a new "mobile" column
      mobileColumns.unshift({
        Header: "Date",
        accessor: "date",
      })

      return mobileColumns
    }

    return baseColumns
  }, [isMobile])

  //meta title
  document.title = "Current Data Table | Flymiles"

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Current Bookings" />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <div className="flex-shrink-0">
              <Link
                to="/add-new-job"
                // href="/add-new-job"
                // onClick={() => setModal(true)}
                className="btn btn-primary me-1"
              >
                Add New Job
              </Link>
              <Link
                to="/current-datatable"
                onClick={() => window.reload()}
                className="btn btn-light me-1"
              >
                <i className="mdi mdi-refresh"></i>
              </Link>
              <UncontrolledDropdown className="dropdown d-inline-block me-1">
                <DropdownToggle
                  type="menu"
                  className="btn btn-success"
                  id="dropdownMenuButton1"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <li>
                    <DropdownItem href="#" onClick={() => window.print()}>
                      Print
                    </DropdownItem>
                  </li>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          {/* <Table columns={columns} data={data} /> */}

          <TableContainer
            columns={columns}
            data={isLoading ? [] : data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
          {isLoading && (
            <div style={{ fontWeight: "bold" }}>Loading Data...</div>
          )}

          {/* <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Edit Job" : "Add Job"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <h3>
                      <strong> PICKUP INFORMATION </strong>
                    </h3>
                    <div className="form-group mb-4">
                      <Label>Pickup Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </InputGroup>
                    </div>
                    <FormGroup className="mb-3">
                      <Label>Pickup Time</Label>

                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select time"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                          }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                      </InputGroup>
                    </FormGroup>
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Label>City</Label>
                      <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2()
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Label>Vehicle Type</Label>
                      <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2()
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>No. of Pax: (1 to 57)</Label>
                          <InputGroup>
                            <div
                              className="input-group-append"
                              onClick={() => {
                                setdata_attr(data_attr - 1)
                              }}
                            >
                              <Button type="button" color="primary">
                                <i className="mdi mdi-minus" />
                              </Button>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              value={data_attr}
                              placeholder="number"
                              readOnly
                            />
                            <div
                              className="input-group-append"
                              onClick={() => {
                                setdata_attr(data_attr + 1)
                              }}
                            >
                              <Button type="button" color="primary">
                                <i className="mdi mdi-plus" />
                              </Button>
                            </div>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>No. of luggage: (1 to 57)</Label>
                          <InputGroup>
                            <div
                              className="input-group-append"
                              onClick={() => {
                                setdata_attr(data_attr - 1)
                              }}
                            >
                              <Button type="button" color="primary">
                                <i className="mdi mdi-minus" />
                              </Button>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              value={data_attr}
                              placeholder="number"
                              readOnly
                            />
                            <div
                              className="input-group-append"
                              onClick={() => {
                                setdata_attr(data_attr + 1)
                              }}
                            >
                              <Button type="button" color="primary">
                                <i className="mdi mdi-plus" />
                              </Button>
                            </div>
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>

                    <h3>
                      <strong> CUSTOMER INFORMATION </strong>
                    </h3>
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Input
                        name="companyName"
                        type="text"
                        placeholder="Insert Company Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.companyName || ""}
                        invalid={
                          validation.touched.companyName &&
                          validation.errors.companyName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.companyName &&
                      validation.errors.companyName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.companyName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Phone</Label>
                      <Input
                        name="jobTitle"
                        type="tel"
                        placeholder="Insert Job Title"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.jobTitle || ""}
                        invalid={
                          validation.touched.jobTitle &&
                          validation.errors.jobTitle
                            ? true
                            : false
                        }
                      />
                      {validation.touched.jobTitle &&
                      validation.errors.jobTitle ? (
                        <FormFeedback type="invalid">
                          {validation.errors.jobTitle}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="location"
                        placeholder="Insert Location"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.location || ""}
                        invalid={
                          validation.touched.location &&
                          validation.errors.location
                            ? true
                            : false
                        }
                      />
                      {validation.touched.location &&
                      validation.errors.location ? (
                        <FormFeedback type="invalid">
                          {validation.errors.location}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Notes</Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e => {
                          textareachange(e)
                        }}
                        maxLength="225"
                        rows="3"
                        placeholder="This textarea has a limit of 225 chars."
                      />
                      {textareabadge ? (
                        <span className="badgecount badge bg-success">
                          {" "}
                          {textcount} / 225{" "}
                        </span>
                      ) : null}
                    </div>
                    <h3>
                      <strong> PICKUP & DROP INFORMATION</strong>
                    </h3>
                    <div className="mb-3">
                      <Label className="form-label">Pickup Location</Label>
                      <Input
                        name="experience"
                        type="text"
                        placeholder="Insert Experience"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.experience || ""}
                        invalid={
                          validation.touched.experience &&
                          validation.errors.experience
                            ? true
                            : false
                        }
                      />
                      {validation.touched.experience &&
                      validation.errors.experience ? (
                        <FormFeedback type="invalid">
                          {validation.errors.experience}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Dropoff Location</Label>
                      <Input
                        name="experience"
                        type="text"
                        placeholder="Insert Experience"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.experience || ""}
                        invalid={
                          validation.touched.experience &&
                          validation.errors.experience
                            ? true
                            : false
                        }
                      />
                      {validation.touched.experience &&
                      validation.errors.experience ? (
                        <FormFeedback type="invalid">
                          {validation.errors.experience}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="inner-repeater mb-3">
                      <Label>Stop</Label>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          {(rows1 || []).map((formRow, key) => (
                            <tr key={key}>
                              <td>
                                <Row className="mb-2">
                                  <Col md="9">
                                    <Input
                                      type="text"
                                      className="inner form-control"
                                      placeholder="Enter your phone no..."
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Button
                                      color="primary"
                                      className="btn-block inner "
                                      id="unknown-btn"
                                      style={{ width: "100%" }}
                                      onClick={e => {
                                        handleRemoveRow(formRow.id)
                                      }}
                                    >
                                      {" "}
                                      Delete{" "}
                                    </Button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Button
                        onClick={() => {
                          handleAddRowNested()
                        }}
                        color="success"
                        className="mt-1"
                      >
                        Add Stop
                      </Button>
                    </div>
                    <div className="mb-3">
                      <Label>Pickup Instruction</Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e => {
                          textareachange(e)
                        }}
                        maxLength="225"
                        rows="3"
                        placeholder="This textarea has a limit of 225 chars."
                      />
                      {textareabadge ? (
                        <span className="badgecount badge bg-success">
                          {" "}
                          {textcount} / 225{" "}
                        </span>
                      ) : null}
                    </div>

                    <h3>
                      <strong>DISPATCH & NOTES</strong>
                    </h3>
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Label>Vehicle Type</Label>
                      <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2()
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Label>Driver</Label>
                      <Select
                        value={selectedMulti2}
                        onChange={() => {
                          handleMulti2()
                        }}
                        options={optionGroup1}
                        className="select2-selection"
                        isLoading={true}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Fare</Label>
                      <Input
                        name="jobTitle"
                        type="Number"
                        placeholder="Insert Job Title"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.jobTitle || ""}
                        invalid={
                          validation.touched.jobTitle &&
                          validation.errors.jobTitle
                            ? true
                            : false
                        }
                      />
                      {validation.touched.jobTitle &&
                      validation.errors.jobTitle ? (
                        <FormFeedback type="invalid">
                          {validation.errors.jobTitle}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Type</Label>
                      <Input
                        name="type"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ""}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      >
                        <option>On Account</option>
                        <option>Cash Collect</option>
                        <option>Cab Charge</option>
                        <option>Others</option>
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Wait Time</Label>
                      <Input
                        name="position"
                        type="text"
                        placeholder="Insert Position"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.position || ""}
                        invalid={
                          validation.touched.position &&
                          validation.errors.position
                            ? true
                            : false
                        }
                      />
                      {validation.touched.position &&
                      validation.errors.position ? (
                        <FormFeedback type="invalid">
                          {validation.errors.position}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Notes</Label>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e => {
                          textareachange(e)
                        }}
                        maxLength="225"
                        rows="3"
                        placeholder="This textarea has a limit of 225 chars."
                      />
                      {textareabadge ? (
                        <span className="badgecount badge bg-success">
                          {" "}
                          {textcount} / 225{" "}
                        </span>
                      ) : null}
                    </div>

                    <h3>
                      <strong>FARE CALCULATION</strong>
                    </h3>
                    <div className="mb-3">
                      <Label className="form-label">Type</Label>
                      <Input
                        name="type"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ""}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      >
                        <option>Full Time</option>
                        <option>Part Time</option>
                        <option>Freelance</option>
                        <option>Internship</option>
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option>Active</option>
                        <option>New</option>
                        <option>Close</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal> */}
        </div>
      </div>
    </>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
