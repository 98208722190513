import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  // postSocialLogin,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    // Check if apiToken is present in localStorage
    const storedApiToken = localStorage.getItem("apiToken")
    if (storedApiToken) {
      // Parse the storedApiToken to get the token value
      const apiToken = JSON.parse(storedApiToken)

      // Create a new object with the values
      const user = {
        apiToken: apiToken.API,
        name: apiToken.NAME,
        email: apiToken.EMAIL,
      }

      console.log(user)

      // Do something with the apiToken, such as storing it in local storage or Redux state
      // ...
      // localStorage.setItem("apiToken2", user)

      // Dispatch the login success action
      yield put(loginSuccess(user))

      // Redirect to the dashboard
      history("/dashboard")
    } else {
      // apiToken not found in localStorage, proceed with API call
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const response = yield call(
          fireBaseBackend.loginUser,
          user.email,
          user.password
        )
        yield put(loginSuccess(response))
      } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        const response = yield call(postJwtLogin, {
          email: user.email,
          password: user.password,
        })
        localStorage.setItem("apiToken", JSON.stringify(response))
        yield put(loginSuccess(response))
      } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
        const response = yield call(postFakeLogin, {
          email: user.email,
          password: user.password,
        })
        localStorage.setItem("apiToken", JSON.stringify(response))
        yield put(loginSuccess(response))
      }
      history("/dashboard")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

// function* loginUser({ payload: { user, history } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(
//         fireBaseBackend.loginUser,
//         user.email,
//         user.password
//       )
//       yield put(loginSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//       const response = yield call(postJwtLogin, {
//         email: user.email,
//         password: user.password,
//       })
//       localStorage.setItem("apiToken", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
//       const response = yield call(postFakeLogin, {
//         email: user.email,
//         password: user.password,
//       })
//       localStorage.setItem("apiToken", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     }
//     history("/dashboard")
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("apiToken")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/dashboard")
      } else {
        history("/login")
      }
      localStorage.setItem("apiToken", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/dashboard")
  } catch (error) {
    console.log("error", error)
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
