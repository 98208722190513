// import React, { useEffect, useState } from "react"
// import { Col } from "reactstrap"

// const options = [
//   "Day Tours",
//   "Multi-Day Tours",
//   "Attractions / Passes",
//   "Helicopter / Scenic Flight",
//   "Cruises",
//   "Water Sports",
//   "Motor Sports",
//   "Hot Air Balloon",
//   "Winery",
//   "Meal Voucher",
//   "Camper Vans",
//   "Car Rental",
// ]

// function Inclusions({ onInclusionsChange, data }) {
//   // Convert incoming data to a format that matches the checkbox state structure
//   const initialState = options.map(option => ({
//     name: option,
//     checked: data ? data?.some(inclusion => inclusion.name === option) : false,
//     id: `customCheck-outlinecolor-${option}`, // Unique ID based on option name
//     colorClass: "form-check-primary", // Simplified for demonstration
//     image: "text", // Assuming all inclusions use a default "text" image
//   }))

//   const [checkboxStates, setCheckboxStates] = useState(initialState)

//   useEffect(() => {
//     // Update state to reflect changes in incoming data
//     setCheckboxStates(initialState)
//   }, [data])

//   useEffect(() => {
//     // Whenever checkboxStates changes, notify the parent component
//     const selectedInclusions = checkboxStates
//       .filter(checkbox => checkbox.checked)
//       .map(({ name, image }) => ({ name, image }))
//     onInclusionsChange(selectedInclusions)
//   }, [checkboxStates])

//   const handleCheckboxChange = index => {
//     const newCheckboxStates = [...checkboxStates]
//     newCheckboxStates[index].checked = !newCheckboxStates[index].checked
//     setCheckboxStates(newCheckboxStates)
//   }

//   return (
//     <React.Fragment>
//       {checkboxStates.map((option, index) => (
//         <Col key={option.name} lg="4">
//           <div
//             className={`form-check form-checkbox-outline ${option.colorClass} mb-3`}
//           >
//             <input
//               type="checkbox"
//               className="form-check-input"
//               id={option.id}
//               // checked={option.checked}
//               // onChange={() => handleCheckboxChange(index)}

//               checked={
//                 data
//                   ? option.checked == true
//                     ? true
//                     : false || ""
//                   : checkboxStates[index].checked
//               }
//               onChange={
//                 data
//                   ? () => handleCheckboxChange(option.name)
//                   : () => handleCheckboxChange(index)
//               }
//               // checked={option.checked == true ? true : false || ""}
//               // onChange={() => handleCheckboxChange(option.name)}
//               onClick={e => {
//                 e.preventDefault()
//                 if (data) {
//                   if (option.checked == true) {
//                     handleCheckboxChange(index)
//                     e.target.checked = false
//                   } else if (option.checked == false) {
//                     handleCheckboxChange(index)
//                     e.target.checked = true
//                   } else {
//                     handleCheckboxChange(index)
//                     e.target.checked
//                   }
//                 }
//               }}
//             />
//             <label className="form-check-label" htmlFor={option.id}>
//               {option.name}
//             </label>
//           </div>
//         </Col>
//       ))}
//     </React.Fragment>
//   )
// }

// export default Inclusions

import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"

function Inclusions({ onInclusionsChange, data }) {
  const [options, setOptions] = useState([]) // State to hold options from the API
  const [checkboxStates, setCheckboxStates] = useState([]) // State to hold checkbox states
  const [loading, setLoading] = useState(true) // State to manage loading
  const [error, setError] = useState(null) // State to manage errors

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://login.flymiles.com.au/api_sections/tours/get_tours_categories_backend.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ categories: "all" }),
          }
        )

        if (!response.ok) {
          throw new Error("Failed to fetch categories.")
        }

        const result = await response.json()

        if (result.length === 0) {
          throw new Error("No categories found.")
        }

        const categories = result.map(category => category.name)

        // Initialize checkboxStates based on fetched categories
        const initialState = categories.map(category => ({
          name: category,
          checked: data
            ? data.some(inclusion => inclusion.name === category)
            : false,
          id: `customCheck-outlinecolor-${category}`, // Unique ID based on option name
          colorClass: "form-check-primary", // Simplified for demonstration
          image: "text", // Assuming all inclusions use a default "text" image
        }))

        setOptions(categories) // Set fetched categories as options
        setCheckboxStates(initialState) // Set initial checkbox states
        setLoading(false) // Set loading to false once data is fetched
      } catch (error) {
        setError(error.message) // Set error message
        setLoading(false) // Stop loading if an error occurs
      }
    }

    fetchCategories()
  }, [data])

  useEffect(() => {
    // Notify parent component of the selected inclusions
    const selectedInclusions = checkboxStates
      .filter(checkbox => checkbox.checked)
      .map(({ name, image }) => ({ name, image }))
    onInclusionsChange(selectedInclusions)
  }, [checkboxStates])

  const handleCheckboxChange = index => {
    const newCheckboxStates = [...checkboxStates]
    newCheckboxStates[index].checked = !newCheckboxStates[index].checked
    setCheckboxStates(newCheckboxStates)
  }

  if (loading) {
    return <p>Loading categories...</p> // Display loading message while fetching data
  }

  if (error) {
    return <p>Error: {error}</p> // Display error message if an error occurs
  }

  if (checkboxStates.length === 0) {
    return <p>No categories available.</p> // Display message if no categories are found
  }

  return (
    <React.Fragment>
      {checkboxStates.map((option, index) => (
        <Col key={option.name} lg="4">
          <div
            className={`form-check form-checkbox-outline ${option.colorClass} mb-3`}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id={option.id}
              // checked={option.checked}
              // onChange={() => handleCheckboxChange(index)}
              checked={
                data
                  ? option.checked == true
                    ? true
                    : false || ""
                  : checkboxStates[index].checked
              }
              onChange={
                data
                  ? () => handleCheckboxChange(option.name)
                  : () => handleCheckboxChange(index)
              }
              // checked={option.checked == true ? true : false || ""}
              // onChange={() => handleCheckboxChange(option.name)}
              onClick={e => {
                e.preventDefault()
                if (data) {
                  if (option.checked == true) {
                    handleCheckboxChange(index)
                    e.target.checked = false
                  } else if (option.checked == false) {
                    handleCheckboxChange(index)
                    e.target.checked = true
                  } else {
                    handleCheckboxChange(index)
                    e.target.checked
                  }
                }
              }}
            />
            <label className="form-check-label" htmlFor={option.id}>
              {option.name}
            </label>
          </div>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default Inclusions
