import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import { isEmpty } from "lodash"
import DeleteModal from "../../components/Common/DeleteModal"

import { useSelector, useDispatch } from "react-redux"
import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap"

function Packages() {
  const [modal, setModal] = useState(false)
  const [data2, setData] = useState([])
  const [scheduleData, setScheduleData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [jobsList, setJobsList] = useState([])
  const [job, setJob] = useState(null)
  const [selectProduct, setselectProduct] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)
  const [noPax, setNoPax] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [confirmationType, setConfirmationType] = useState("")
  const [cutOffTime, setcutOffTime] = useState("")
  const [priceChild, setPriceChild] = useState("")
  const [priceAdult, setPriceAdult] = useState("")
  const [priceInfant, setPriceInfant] = useState("")
  const [productListing, setProductListing] = useState("")
  const [publishType, setPublishType] = useState("")
  const [promotionDiscountPrice, setPromotionDiscountPrice] = useState("")
  const [productId, setProductId] = useState("")
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      pax: (job && job.pax) || filteredData.map(item => item.no_of_pax) || "",
      cuttOffTime:
        (job && job.cuttOffTime) ||
        filteredData.map(item => item.cutt_off_time) ||
        "",
      startDate:
        (job && job.startDate) ||
        filteredData.map(item => item.start_date) ||
        "",
      endDate:
        (job && job.endDate) || filteredData.map(item => item.end_date) || "",
      confirmation:
        (job && job.confirmation) ||
        filteredData.map(item => item.confirmation_type) ||
        "",
      priceChild:
        (job && job.priceChild) ||
        filteredData.map(item => item.price_child) ||
        "",
      priceInfant:
        (job && job.priceInfant) ||
        filteredData.map(item => item.price_infant) ||
        "",
      priceAdult:
        (job && job.priceAdult) ||
        filteredData.map(item => item.price_adult) ||
        "",
      discount:
        (job && job.discount) ||
        filteredData.map(item => item.promotion_discount_price) ||
        "",
      productListing:
        (job && job.prodcut_listing) ||
        filteredData.map(item => item.prodcut_listing) ||
        "",
      publish:
        (job && job.publish) ||
        filteredData.map(item => item.publish_type) ||
        "",
    },
    validationSchema: Yup.object({
      // jobId: Yup.string()
      //   .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Job Id")
      //   .required("Please Enter Your Job Id"),
      // jobTitle: Yup.string().required("Please Enter Your Job Title"),
      // companyName: Yup.string().required("Please Enter Your Company Name"),
      // location: Yup.string().required("Please Enter Your Location"),
      // experience: Yup.string().required("Please Enter Your Experience"),
      // position: Yup.string().required("Please Enter Your Position"),
      // type: Yup.string().required("Please Enter Your Type"),
      // status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const payload = {
          country_id: job.id, // Assuming 'job.id' holds the country ID you want to update
          country: values.jobTitle, // Assuming 'jobTitle' is the updated country name
        }
        console.log(values.jobTitle, job.jobTitle) //
        const updateJobList = {
          id: job ? job.id : 0,
          // jobId: values.jobId,
          jobTitle: values.jobTitle,
          companyName: values.companyName,
          // location: values.location,
          // experience: values.experience,
          // position: values.position,
          // type: values.type,
          // postedDate: "02 June 2021",
          // lastDate: "25 June 2021",
          // status: values.status,
        }

        fetch(
          "https://login.flymiles.com.au/api_sections/packages/update_country.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other headers as required by your API
            },
            body: JSON.stringify(payload),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // Handle success response
            // You might want to update your local state or Redux store based on the response
            // For example, refreshing the country list to reflect the updated data
            validation.resetForm()
            toggle()
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle errors here, possibly showing an error message to the user
          })

        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const payload = {
          country: values.jobTitle, // Assuming the jobTitle field is used for country name
        }

        const newJobList = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          jobTitle: values["jobTitle"],
        }

        fetch(
          "https://login.flymiles.com.au/api_sections/packages/add_country.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include other headers as required by the API
            },
            body: JSON.stringify(payload),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // Handle success response
            // You might want to update your local state or Redux store based on the response
            // For example, refetching the country list if the API does not automatically do it
            validation.resetForm()
            toggle()
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle errors here
          })

        // save new Job
        dispatch(onAddNewJobList(newJobList))
        validation.resetForm()
      }
      toggle()
    },
  })

  const dispatch = useDispatch()
  const { jobs } = useSelector(state => ({
    jobs: state.JobReducer.jobs,
  }))

  useEffect(() => {
    if (jobs && !jobs.length) {
      dispatch(onGetJobList())
    }
  }, [dispatch, jobs])

  useEffect(() => {
    setJobsList(jobs)
  }, [jobs])

  useEffect(() => {
    if (!isEmpty(jobs) && !!isEdit) {
      setJobsList(jobs)
      setIsEdit(false)
    }
  }, [jobs])
  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  const handleJobClick = arg => {
    const job = arg
    const selectedCity = city.find(city => city.id == job.id)

    setJob({
      id: job.id,
      jobId: job.jobId,
      jobTitle: job.city,
      companyName: job.companyName,
      location: job.location,
      experience: job.experience,
      position: job.position,
      type: job.country_id,
      status: job.status,
    })

    setIsEdit(true)

    toggle()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedPkg, setSelectedPkg] = useState(false)

  const onClickDelete = job => {
    console.log(job)
    // setSelectedPkg(job)
    setJob(job)
    setDeleteModal(true)
  }

  const handleDeletejob = () => {
    if (job && job.id) {
      dispatch(onDeleteJobList(job.id))
      setDeleteModal(false)
    }
  }
  const handleJobClicks = () => {
    setJobsList("")
    setIsEdit(false)
    toggle()
  }
  const handleDeletePackage = async () => {
    if (!selectedPkg) return

    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/packages/delete_complete_pkg.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ product_id: selectedPkg }),
      }
    )
    const data = await response.json()
    console.log(data) // You might want to handle this depending on the response
    fetchData() // Refresh the list after deletion
    setDeleteModal(false)
  }

  const openDeleteModal = id => {
    setSelectedPkg(id)
    setDeleteModal(true)
  }

  const submitScheduleFormData = async e => {
    // e.preventDefault()
    // console.log(productListing)
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/packages/add_pricing_schedule.php"
    const scheduleData = {
      no_of_pax: Array.isArray(validation.values.pax)
        ? validation.values.pax[0]
        : validation.values.pax,
      start_date: Array.isArray(validation.values.startDate)
        ? validation.values.startDate[0]
        : validation.values.startDate,
      end_date: Array.isArray(validation.values.endDate)
        ? validation.values.endDate[0]
        : validation.values.endDate,
      confirmation_type: Array.isArray(validation.values.confirmation)
        ? validation.values.confirmation[0]
        : validation.values.confirmation, // Map "Automatic" to "yes", "Manually" to "no" or similar based on API requirements
      cutt_off_time: Array.isArray(validation.values.cuttOffTime)
        ? validation.values.cuttOffTime[0]
        : validation.values.cuttOffTime,
      price_adult: Array.isArray(validation.values.priceAdult)
        ? validation.values.priceAdult[0]
        : validation.values.priceAdult,
      price_child: Array.isArray(validation.values.priceChild)
        ? validation.values.priceChild[0]
        : validation.values.priceChild,
      price_infant: Array.isArray(validation.values.priceInfant)
        ? validation.values.priceInfant[0]
        : validation.values.priceInfant,
      publish_type: Array.isArray(validation.values.publish)
        ? validation.values.publish[0]
        : validation.values.publish, // You need to define how "Online", "Agent", "Both" map to your API's expected values
      promotion_discount_price: Array.isArray(validation.values.discount)
        ? validation.values.discount[0]
        : validation.values.discount,
      prodcut_listing: Array.isArray(validation.values.productListing)
        ? validation.values.productListing[0]
        : validation.values.productListing,

      product_id: productId, // This needs to be dynamically set or selected from a dropdown
    } // Prepare your form data for the API

    // console.log(startDate.target.value, endDate)
    console.log(scheduleData)
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/packages/add_pricing_schedule.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include other headers if needed
          },
          body: JSON.stringify(scheduleData),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      fetchData()
      setModal(false)
      setLoading(false)

      // Handle success (e.g., show success message)
    } catch (error) {
      console.error("Error submitting form:", error)
      // Handle error (e.g., show error message)
    }
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Id No",
        accessor: "id",
      },
      {
        Header: "Package",
        accessor: "package",
      },
      { Header: "Action", accessor: "action" },

      {
        Header: "Alldata",
        accessor: "allData",
      },
    ]
    return baseColumns
  }, [])

  // console.log(
  //   filteredData?.map(item => item.id),
  //   productId
  // )

  useEffect(() => {
    console.log(scheduleData)
    const newData = scheduleData
      ? scheduleData.filter(item => item.id === productId)
      : []
    console.log(productId, newData)

    setFilteredData(newData)
  }, [productId])

  const fetchData = () => {
    setIsLoading(true)

    const payload = {
      product_all: "yes",
    }
    return fetch(
      "https://login.flymiles.com.au/api_sections/packages/get_all_created_products.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(apidata => {
        // console.log(apidata)
        const formattedData = apidata.map(item => item.Product_Header)
        // setScheduleData(apidata.map(prod => prod.Product_Header))

        if (apidata && apidata.length > 0) {
          console.log(apidata)
          const updatedData = [
            Array.isArray(data2) ? data2 : [], // Ensure data2 is iterable
            ...apidata.slice(0, apidata.length).map(data => ({
              id: (
                <div style={{ color: "#2196f3" }}>
                  {data.Product_Header.prodcut_listing == 0
                    ? ""
                    : data.Product_Header.prodcut_listing}
                </div>
              ),
              package: (
                <>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 20 }}
                  >
                    <div style={{}}>
                      <img
                        src={
                          data
                            ? `https://login.flymiles.com.au/api_sections/packages/pkg_img/${
                                data?.images.find(
                                  image => image.type === "main"
                                )?.image
                              }`
                            : null
                        }
                        style={{ width: 100, height: 100 }}
                      />
                    </div>
                    <div style={{}}>
                      <h5 style={{ color: "#000", fontWeight: "700" }}>
                        {data ? (
                          data?.Product_Header?.product_name
                        ) : (
                          <>Product Name</>
                        )}
                      </h5>
                      <p style={{ color: "#aaa" }}>
                        Product code:{" "}
                        {data ? data?.Product_Header?.product_code : <>00000</>}
                      </p>
                    </div>
                  </div>
                </>
              ),
              action: (
                <ul className="list-unstyled hstack gap-1 mb-0">
                  <li>
                    <Link
                      to={`/edit-packages/${data?.Product_Header?.id}`}
                      className="btn btn-sm btn-soft-info ms-auto"
                      //   onClick={e => {
                      //     e.preventDefault()

                      //     const jobData = cellProps.row.original
                      //     // handleJobClick(jobData)
                      //   }}
                    >
                      <i className="mdi mdi-pencil-outline" id="edittooltip" />{" "}
                      Edit
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-danger ms-auto"
                      onClick={e => {
                        e.preventDefault()
                        openDeleteModal(data?.Product_Header?.id)
                        // const jobData = data?.Product_Header?.id
                        // console.log(jobData)
                        // onClickDelete(jobData)
                      }}
                    >
                      <i className="mdi mdi-delete-outline" id="edittooltip" />{" "}
                      Delete
                      <UncontrolledTooltip
                        // onClick={() =>
                        // openDeleteModal(data?.Product_Header?.id)
                        // }
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-success"
                      onClick={e => {
                        e.preventDefault()
                        setModal(true)
                        // Filter the array
                        setScheduleData(formattedData)
                        setProductId(data?.Product_Header?.id)

                        // const productId2 = "23" // Example product ID

                        console.log(
                          scheduleData,
                          "id",
                          data?.Product_Header?.id,
                          "pid",
                          productId,
                          data?.Product_Header?.product_name
                        )
                      }}
                    >
                      <i className="mdi mdi-clock-outline" id="deletetooltip" />{" "}
                      Schedule
                      <UncontrolledTooltip
                        onClick={() => {}}
                        placement="top"
                        target="deletetooltip"
                      >
                        Schedule
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                </ul>
              ),
              allData:
                data?.Product_Header?.id +
                data?.Product_Header?.product_name +
                data?.Product_Header?.product_code,
            })),
          ]

          setData(updatedData) // Update the state with the updated data array
        }
      })
      .catch(error => {
        // Handle the error
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false) // Set loading state to false after fetching data
      })
  }
  useEffect(() => {
    fetchData()
  }, [])

  document.title = "Packages | Flymiles"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeletePackage(selectedPkg)}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />
          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            columns={columns}
            data={isLoading ? [] : data2}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Pricing & Scheduling
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                // validation.handleSubmit()
                submitScheduleFormData()
                setLoading(true)
                return false
              }}
            >
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">No. of Pax</Label>
                    <Input
                      name="pax"
                      type="Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.pax || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Your No. of Pax"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      Order Cut-off time
                    </Label>
                    <Input
                      name="cuttOffTime"
                      type="Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.cuttOffTime || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Order Cut-off time"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label>When does your schedule start?</Label>
                    <Input
                      type="Date"
                      name="startDate"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Jun 19, 2024"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      When does your schedule end?
                    </Label>
                    <Input
                      type="Date"
                      name="endDate"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate || ""}
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Your No. of Pax"
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label>Confirmation Type</Label>
                    <select
                      className="form-select"
                      name="confirmation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmation || ""}
                    >
                      <option>Select Confirmation Type</option>
                      <option value={1}>Automatic</option>
                      <option value={2}>Manually</option>
                    </select>
                  </FormGroup>
                </Col>

                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      Product Listing
                    </Label>
                    <Input
                      type="Number"
                      className="form-control"
                      name="productListing"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.productListing || ""}
                      placeholder="Enter Product Listing"
                      id="example-number-input"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <div>
                      <Label htmlFor="basicpill-email-input42">
                        Pricing By
                      </Label>
                    </div>
                    <Label
                      htmlFor="example-number-input"
                      className="col-form-label"
                    >
                      Adult:
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      name="priceAdult"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.priceAdult || ""}
                      placeholder="Enter Pricing"
                      id="example-number-input"
                    />
                    <Label
                      htmlFor="example-number-input"
                      className=" col-form-label"
                    >
                      Child (3-11 yr.):
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      name="priceChild"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.priceChild || ""}
                      placeholder="Enter Pricing"
                      id="example-number-input"
                    />
                    <Label
                      htmlFor="example-number-input"
                      className=" col-form-label"
                    >
                      Infant (1-2 yr.):
                    </Label>
                    <Input
                      className="form-control"
                      type="number"
                      name="priceInfant"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.priceInfant || ""}
                      placeholder="Enter Pricing"
                      id="example-number-input"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label>Publish Online or Agent</Label>
                    <select
                      className="form-select"
                      name="publish"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.publish || ""}
                    >
                      <option>Select </option>
                      <option value={1}>Online</option>
                      <option value={2}>Agent</option>
                      <option value={3}>Both</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="basicpill-email-input42">
                      Promotion Discount
                    </Label>
                    <Input
                      name="discount"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.discount || ""}
                      type="Number"
                      className="form-control"
                      id="basicpill-email-input42"
                      placeholder="Enter Promotion Discount"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-success save-user"
                    >
                      {loading ? "Loading..." : "Save"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}
Packages.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Packages
