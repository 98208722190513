import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "assets/images/Logo3.png"
import axios from "axios"

const ForgetPasswordPage = props => {
  const [error, setError] = useState("")
  const [successNoti, setSuccessNoti] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  //meta title
  document.title = "Forget Password | Flymiles"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, props.history))
      handleSend(values)
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  const req = async data => {
    // const apiToken = localStorage.getItem("apiToken") // Get the API token from local storage
    // const value = JSON.parse(apiToken)
    // const api = value.API
    // const headers = { Authorization: `Bearer ${api}` }
    try {
      setIsLoading(true)

      const response = await axios.post(
        " https://flymiles.com.au/adminapi/public/api/forgot-password",
        data
      )
      if (response.data.success == true) {
        setSuccessNoti("Email sent successfully.")
        dispatch(props.router.navigate("/reset"))
      } else {
        setError("Email is not Correct.")
      }
      return response
    } catch (error) {
      setIsLoading(false)
      setError("An error occurred") // Or use a more general error message
      console.log(error)
      throw error
    }
  }

  const handleSend = async values => {
    const requestBody = {
      email: values.email,
    }

    try {
      const response = await req(requestBody)
      console.log("Response:", response.data)
    } catch (error) {
      console.error("Error:", error.response)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Forget Password</h5>
                        {/* <p>Sign in to continue to Skote.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className=" profile-user-wid mb-4">
                        {/* <span className="avatar-title rounded-circle bg-light"> */}
                        <span
                          style={{
                            margin: "0px 0px 20px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                          className="logo-sm ps-4"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              objectFit: "contain",
                              objectPosition: "center center",
                              marginTop: "-2%",
                              background: "#F8F8FB",
                              borderRadius: "50%",
                              padding: "5px",
                              border: "2px solid #D4DBF9",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              style={
                                {
                                  // width: "90%",
                                  // objectFit: "contain",
                                  // objectPosition: "center",
                                  // marginTop: "-6%",
                                  // background: "#F8F8FB",
                                  // borderRadius: "50%",
                                }
                              }
                              src={logo}
                              alt=""
                              height="80"
                            />
                          </div>
                        </span>
                        {/* </span> */}
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    ) : null}
                    {successNoti ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {successNoti}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {isLoading ? "Sumbiting..." : "Reset"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© 2023 All Rights Reserved by FLY MILES PTY. LTD.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
