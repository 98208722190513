// import React, { useState, useEffect } from "react"
// import { Editor } from "react-draft-wysiwyg"
// import { EditorState, ContentState } from "draft-js"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

// const MyEditor = ({ handleChange, data }) => {
//   const [editorState, setEditorState] = useState(EditorState.createEmpty())

//   useEffect(() => {
//     if (data) {
//       // Check if data is not null or undefined
//       const contentState = ContentState.createFromText(data) // Assuming `data` is plain text
//       const newEditorState = EditorState.createWithContent(contentState)
//       setEditorState(newEditorState)
//     }
//   }, [data]) // React to changes in `data`

//   // Optional: Function to handle editor changes if needed
//   const onEditorStateChange = newEditorState => {
//     const text = newEditorState
//     setEditorState(text)
//     // Here you can handle the updated editor state,
//     // e.g., storing the edited content in a state or sending it to an API
//   }
//   if (editorState == data) {
//     handleChange(editorState)
//   } else {
//     handleChange(editorState.getCurrentContent().getPlainText())
//   }
//   // console.log(editorState.getCurrentContent().getPlainText())

//   return (
//     <Editor
//       editorState={editorState}
//       onEditorStateChange={onEditorStateChange}
//       toolbarClassName="toolbarClassName"
//       wrapperClassName="wrapperClassName"
//       editorClassName="editorClassName"
//     />
//   )
// }

// export default MyEditor

import React, { useState, useEffect } from "react"
import { Editor } from "react-draft-wysiwyg"
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const MyEditor = ({ handleChange, data }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const preprocessAndParseJson = jsonString => {
    try {
      // Replace unescaped newlines and other potentially problematic escape sequences
      const sanitizedJsonString = jsonString.replace(/\n/g, "\\n")
      return JSON.parse(sanitizedJsonString)
    } catch (error) {
      console.error("Error parsing JSON:", error)
      return null // or however you wish to handle parsing errors
    }
  }

  // Initialize or update the editor state when `data` changes
  useEffect(() => {
    console.log(data)
    let newEditorState
    if (data) {
      try {
        const parsedData = preprocessAndParseJson(data)
        if (parsedData) {
          const contentState = convertFromRaw(parsedData)
          newEditorState = EditorState.createWithContent(contentState)
        } else {
          throw new Error("Parsed data is null")
        }

        console.log("content", newEditorState)
      } catch (error) {
        console.error("Error initializing editor with data:", error)
        const contentState = ContentState.createFromText(data)
        newEditorState = EditorState.createWithContent(contentState)
      }
    } else {
      // If no data, start with an empty editor
      newEditorState = EditorState.createEmpty()
    }
    setEditorState(newEditorState)
    const rawContentState = convertToRaw(newEditorState.getCurrentContent())

    // Send the updated data\
    handleChange(JSON.stringify(rawContentState))
    // console.log("by data", JSON.stringify(rawContentState))
  }, [data])

  const onEditorStateChange = newEditorState => {
    setEditorState(newEditorState)

    // Convert the current content of the editor to raw JS structure
    const rawContentState = convertToRaw(newEditorState.getCurrentContent())

    // Send the updated data
    handleChange(JSON.stringify(rawContentState))
    console.log("onChange", JSON.stringify(rawContentState))
  }

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
    />
  )
}

export default MyEditor
