import React, { useEffect, useState } from "react"
import { LoadScript, Autocomplete } from "@react-google-maps/api"

function DistanceCalculator() {
  const [place1, setPlace1] = useState(null)
  const [place2, setPlace2] = useState(null)
  const [distance, setDistance] = useState(0)
  const travelMode = "DRIVING"
  console.log(place1)
  useEffect(() => {
    calculateDistance()
  }, [place1, place2])

  const handlePlace1Change = place => {
    setPlace1(place.pla)
  }

  const handlePlace2Change = place => {
    setPlace2(place.place_id)
  }

  const calculateDistance = () => {
    if (!place1 || !place2) {
      setDistance(0)
      return
    }

    const service = new window.google.maps.DistanceMatrixService()
    service.getDistanceMatrix(
      {
        origins: [{ placeId: place1 }],
        destinations: [{ placeId: place2 }],
        travelMode: travelMode,
      },
      (response, status) => {
        if (status === "OK") {
          const distanceInMeters = response.rows[0].elements[0].distance.value
          const distanceInKms = (distanceInMeters / 1000).toFixed(2)
          setDistance(distanceInKms)
        } else {
          setDistance(0)
        }
      }
    )
  }

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDSS70hvcoWwGoJWnYfsNM8eOTzdybk-rg"
      libraries={["places"]}
    >
      <Autocomplete
        onLoad={autocomplete => {
          autocomplete.addListener("place_changed", () => {
            handlePlace1Change(autocomplete.getPlace())
          })
        }}
      >
        <input type="text" placeholder="Enter Place 1" />
      </Autocomplete>
      <Autocomplete
        onLoad={autocomplete => {
          autocomplete.addListener("place_changed", () => {
            handlePlace2Change(autocomplete.getPlace())
          })
        }}
      >
        <input type="text" placeholder="Enter Place 2" />
      </Autocomplete>
      <p>{distance !== null && <p>Distance: {distance}meters</p>}</p>
    </LoadScript>
  )
}

export default DistanceCalculator
