import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import classnames from "classnames"

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "pages/JobPages/JobList/JobListCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button,
} from "reactstrap"
import { useDropzone } from "react-dropzone"
import DayWiseItinerary from "./DayWiseItinerary "
import AccessibilityForm from "./AccessibilityOptions"
import HealthRestrictionsForm from "./HealthRestrictions"
import ItineraryDayComponent from "./ItineraryDayComponent "
import ImageUploadComponent from "./ImageUploadComponent"
import ImageUploader from "./StyledDropzone"
import MyMultiSelectComponent from "./MyMultiselectComponent"
import Inclusions from "./Inclusions"
import MyEditor from "./EditorForm"
// import { JobNo } from "pages/JobPages/JobList/JobListCol"

function CreatePackages() {
  //meta title
  document.title = "Create a Package | Flymiles"
  const [data, setData] = useState([])
  const [warning, setWarning] = useState("")

  // const [mainImage, setMainImage] = useState(null) // For the main product image

  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)

  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [countries, setCountries] = useState([])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [productId, setProductId] = useState(null)
  // form api data
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [productName, setProductName] = useState("")
  const [productCode, setProductCode] = useState("")
  const [countryId, setCountryId] = useState("")
  const [cities, setCities] = useState([])
  const [inclusions, setInclusions] = useState([])
  const [image, setImage] = useState([])
  const [multiImages, setMultiImages] = useState([])
  const [accessibilityQuestions, setAccessibilityQuestions] = useState([])
  const [healthRestrictions, setHealthRestrictions] = useState([])
  const [itinerary, setItinerary] = useState([])
  // const [formScheduleData, setFormScheduleData] = useState({})
  const [selectProduct, setselectProduct] = useState("")
  const [noPax, setNoPax] = useState(0)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [confirmationType, setConfirmationType] = useState("")
  const [cutOffTime, setcutOffTime] = useState("")
  const [priceChild, setPriceChild] = useState("")
  const [priceAdult, setPriceAdult] = useState("")
  const [priceInfant, setPriceInfant] = useState("")
  const [productListing, setProductListing] = useState("")
  const [publishType, setPublishType] = useState("")
  const [promotionDiscountPrice, setPromotionDiscountPrice] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [fetchImg, setFetchImg] = useState(false)

  // console.log(countries, countryId)
  // const handleChange = e => {
  //   const { name, value } = e.target
  //   setFormScheduleData(prevState => ({
  //     ...prevState,
  //     [name]: value,
  //   }))
  // }

  // console.log(countries)
  // console.log(healthRestrictions)
  const handleCityChange = selectedCities => {
    console.log(selectedCities)
    // Assuming you want to store just the values of selected options
    const cities = selectedCities.map(city => city.value)
    setCities(cities)
  }
  const handleInclusionsChange = inclusions => {
    setInclusions(inclusions)
    // console.log("incl", inclusions)
  }
  const handleAcc = Acc => {
    setAccessibilityQuestions(Acc)
    // console.log("incl", inclusions)
  }
  const handleHR = HR => {
    setHealthRestrictions(HR)
    // console.log("incl", inclusions)
  }
  const handleDay = day => {
    setItinerary(day)
    // console.log("incl", inclusions)
  }
  const handleMainImage = image => {
    // console.log("i2", image)
    setImage(image)
  }
  const handleImage = image => {
    // console.log("i", image)
    setMultiImages(image)
  }
  // const handleImage = images => {
  //   // Ensure 'images' is always treated as an array
  //   const imagesArray = Array.isArray(images) ? images : [images]

  //   // Temporary storage for processed images
  //   let newMultiImages = []
  //   let othersImages = []

  //   // Process each image
  //   imagesArray.forEach(img => {
  //     if (img.type == "main") {
  //       console.log("dd", img) // image is here
  //       // If it's a main image, add it to the start of the newMultiImages array
  //       // newMultiImages = [
  //       //   {
  //       //     image: img.image,
  //       //     type: "main",
  //       //   },
  //       //   ...newMultiImages,
  //       // ]
  //       newMultiImages.unshift({
  //         image: img.image,
  //         type: "main",
  //       })
  //     } else {
  //       // For other images, append to the newMultiImages array
  //       othersImages.push({
  //         image: img.image,
  //         type: "others",
  //       })
  //     }
  //   })
  //   newMultiImages = [...newMultiImages, ...othersImages]

  //   // Update state with the new combined array of images
  //   setMultiImages(newMultiImages)
  // }

  // console.log("nm", image, multiImages)
  const submitImages = async () => {
    // e.preventDefault()
    setIsLoading(true)

    const apiUrl =
      "https://login.flymiles.com.au/api_sections/packages/add_images.php"

    const payload = {
      prodcut_id: productId,
      mainImage: image,
      images: multiImages,
    }

    console.log(payload)

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      if (data) {
        setIsLoading(false)
        setFetchImg(false)
        toggleTabVertical(activeTabVartical + 1)
      }
      // Handle success response
    } catch (error) {
      console.error("Error submitting images:", error)
      setIsLoading(false)
      setFetchImg(false)
      // Handle errors here
    }
  }
  const fetchData = async e => {
    try {
      const apiUrl =
        "https://login.flymiles.com.au/api_sections/packages/get_prodcut_complete_detail.php"

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: productId,
        }),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }

      const result = await response.json()
      setData(result)
      console.log(result.Product_Header.id) // Process the success response
    } catch (error) {
      console.error("Failed to submit the form", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [fetchImg])
  // console.log(isLoading)
  // const handleImage = images => {
  //   // Initialize a placeholder for the main image, if any
  //   let mainImage = null
  //   // Initialize an array to hold "others" type images
  //   let othersImages = []

  //   // Ensure 'images' is always treated as an array
  //   const imagesArray = Array.isArray(images) ? images : [images]

  //   // Process each image
  //   imagesArray.forEach(img => {
  //     if (img.type == "main") {
  //       // console.log(img)
  //       // Directly set the main image since there should only be one
  //       mainImage = img
  //     } else {
  //       // Collect "others" type images
  //       othersImages.push(img)
  //     }
  //   })

  //   // Combine the main image with others, ensuring the main image is first if it exists
  //   const combinedImages = mainImage
  //     ? [mainImage, ...othersImages]
  //     : othersImages

  //   // Update the state with the combined array of images
  //   setMultiImages(combinedImages)
  // }

  // console.log(multiImages)
  // const combineImages = () => {
  //   // Start with an empty array to hold all combined images
  //   const combinedImages = []

  //   // If there's a main image, add it to the combined array
  //   if (image) {
  //     combinedImages.push({
  //       image: image?.preview, // Assuming 'image' state holds an object with a 'preview' property
  //       type: "main",
  //     })
  //   }

  //   // Add all other images to the combined array
  //   const othersFormatted = multiImages.map(img => ({
  //     image: img?.preview, // Assuming each item in 'multiImages' array has a 'preview' property
  //     type: "others",
  //   }))

  //   // Combine the main image and other images
  //   return combinedImages.concat(othersFormatted)
  // }

  // const handleImage = images => {
  //   // Clear previous states first, assuming you want to replace them with new uploads
  //   setImage(images)
  //   setMultiImages(images)

  //   console.log("kk", images)

  //   // Process the array of images
  //   // images.forEach((img, index) => {
  //   //   if (index === 0 && img.type == "main") { // Assuming the first image is always the main image
  //   //     setImage(img);
  //   //   } else {
  //   //     setMultiImages(prev => [...prev, img]);
  //   //   }
  //   // });
  // }

  // Example usage
  // console.log("Combined Images:", combineImages())

  // console.log("mm", image, multiImages)

  // console.log("editor", editorState.getCurrentContent)
  // console.log("editor", editorState.getCurrentContent().getPlainText())
  // console.log("cities", countryId.target.value)
  // console.log("in", inclusions)

  const handleSubmit = async e => {
    // e.preventDefault()
    // const productInclusionsHigh = draftToHtml(
    //   convertToRaw(editorState.getCurrentContent())
    // )
    setIsLoading(true)

    const formData = {
      product_name: productName?.target?.value,
      product_code: productCode?.target?.value,
      county_id: parseInt(countryId),
      prodcut_inclusion_high: editorState,
      cities: cities,
      inclusions: inclusions,
    }

    const updateFormData = {
      product_id: productId,
      product_name: productName?.target?.value,
      product_code: productCode?.target?.value,
      county_id: parseInt(countryId),
      prodcut_inclusion_high: editorState,
      cities: cities,
      inclusions: inclusions,
    }
    console.log(formData)

    try {
      const apiUrl =
        "https://login.flymiles.com.au/api_sections/packages/add_product.php"
      const updateApiUrl =
        "https://login.flymiles.com.au/api_sections/packages/update_main_product_api.php"
      const api = productId !== null ? updateApiUrl : apiUrl

      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productId !== null ? updateFormData : formData),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }

      const result = await response.json()
      if (result.prodcut_id) {
        setProductId(result.prodcut_id)
      }

      if (result) {
        setIsLoading(false)
        toggleTabVertical(activeTabVartical + 1)
      }

      console.log(result) // Process the success response
      console.log(result.prodcut_id) // Process the success response
    } catch (error) {
      setIsLoading(false)

      console.error("Failed to submit the form", error)
    }
  }
  const submitAccess = async () => {
    // Prepare the data in the required API format
    const accessibilityData = {
      prodcut_id: productId, // Ensure this ID is dynamically set based on your needs
      accessibility: accessibilityQuestions.map(q => ({
        title: q.question,
        yes_no: q.answer,
      })),
    }

    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/packages/add_accessibility.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any required headers here
          },
          body: JSON.stringify(accessibilityData),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      console.log("Success:", result)
      // Handle success response
    } catch (error) {
      console.error("Error during API call:", error)
      // Handle errors
    }
  }
  const submitHealthRes = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/packages/add_health_restriction.php"
    const payload = {
      prodcut_id: productId, // Assuming product ID is static; replace with dynamic value if necessary
      health_res: healthRestrictions
        .filter(restriction => restriction.answer == true)
        .map(restriction => ({
          title: restriction.question,
          check_uncheck: restriction.answer ? "yes" : "no",
        })),
    }

    console.log(payload)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Include other headers if required by your API (like authorization headers)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success response, e.g., show a notification
    } catch (error) {
      console.error("Error submitting health restrictions:", error)
      // Handle errors, e.g., show an error message
    }
  }
  const submitItinerary = async () => {
    const apiUrl =
      "https://login.flymiles.com.au/api_sections/packages/add_daywise.php"
    const payload = {
      prodcut_id: productId, // Ensure this is dynamically set based on your application's requirements
      daywise: itinerary.map(item => ({
        header: item.header,
        body: item.body,
        duration: item.duration,
        include: item.includes, // Ensure this matches your API expectation ('include' or 'includes')
      })),
    }

    console.log("ii", payload)

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Additional headers here if needed (e.g., authorization)
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle successful submission (e.g., showing a success message)
    } catch (error) {
      console.error("Error submitting itinerary:", error)
      // Handle error case (e.g., showing an error message)
    }
  }
  const submitScheduleFormData = async e => {
    // e.preventDefault()

    const apiUrl =
      "https://login.flymiles.com.au/api_sections/packages/add_pricing_schedule.php"
    const scheduleData = {
      no_of_pax: noPax?.target.value,
      start_date: `${startDate?.target.value}`,
      end_date: `${endDate?.target.value}`,
      confirmation_type: confirmationType?.target.value, // Map "Automatic" to "yes", "Manually" to "no" or similar based on API requirements
      cutt_off_time: cutOffTime?.target.value,
      price_adult: priceAdult?.target.value,
      price_child: priceChild?.target.value,
      price_infant: priceInfant?.target.value,
      publish_type: publishType?.target.value, // You need to define how "Online", "Agent", "Both" map to your API's expected values
      promotion_discount_price: promotionDiscountPrice?.target.value,
      product_id: productId, // This needs to be dynamically set or selected from a dropdown
    } // Prepare your form data for the API

    // console.log(startDate.target.value, endDate)
    // console.log(scheduleData)
    try {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/packages/add_pricing_schedule.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include other headers if needed
          },
          body: JSON.stringify(scheduleData),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Success:", data)
      // Handle success (e.g., show success message)
    } catch (error) {
      console.error("Error submitting form:", error)
      // Handle error (e.g., show error message)
    }
  }
  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 6) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const fetchCountries = async () => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/packages/get_country_city_list.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ get_country: "Yes" }),
      }
    )
    const data = await response.json()
    if (data) {
      setCountries(data || [])
    }
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  const handleEditorChange = newEditorState => {
    // const text = newEditorState.getCurrentContent().getPlainText()
    setEditorState(newEditorState)
    // If you need to do something with the plain text or for debugging
    // console.log(newEditorState.getCurrentContent().getPlainText())
  }

  // const fetchCity = async () => {
  //   const response = await fetch(
  //     "https://login.flymiles.com.au/api_sections/packages/get_country_city_list.php",
  //     {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ get_city: "Yes" }),
  //     }
  //   )
  //   const data = await response.json()
  //   setCity(data || [])
  // }
  // // console.log("data", city)

  // useEffect(() => {
  //   fetchCity()
  // }, [])

  const options = ["American Express", "Visa", "MasterCard", "Discover"]

  const handleSelectionChange = event => {
    // Collect all selected options
    const selectedValues = Array.from(
      event.target.selectedOptions,
      option => option.value
    )
    setSelectedOptions(selectedValues)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Packages" breadcrumbItem="Create a package " />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Create a Package</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1.</span> Product Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2.</span>{" "}
                            <span>Images</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 3,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 3,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(3)
                            }}
                            disabled={!(passedStepsVertical || []).includes(3)}
                          >
                            <span className="number">3.</span> Day Itinerary
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 4,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 4,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(4)
                            }}
                            disabled={!(passedStepsVertical || []).includes(4)}
                          >
                            <span className="number">4.</span> Accessibility
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 5,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 5,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(5)
                            }}
                            disabled={!(passedStepsVertical || []).includes(5)}
                          >
                            <span className="number">5.</span> Inclusions
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 6,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 6,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(6)
                            }}
                            disabled={!(passedStepsVertical || []).includes(6)}
                          >
                            <span className="number">6.</span> Confirm Detail
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    Product Name
                                  </Label>
                                  <Input
                                    type="text"
                                    required
                                    className="form-control"
                                    onChange={setProductName}
                                    id="basicpill-firstname-input12"
                                    placeholder="Enter Your Product Name"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-Productcode-input22">
                                    Product Code
                                  </Label>
                                  <Input
                                    type="text"
                                    required
                                    className="form-control"
                                    onChange={setProductCode}
                                    id="basicpill-Productcode-input22"
                                    placeholder="Enter Your Product Code"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>Country</Label>
                                  <select
                                    // required
                                    className="form-select"
                                    value={countryId || ""}
                                    onChange={e => setCountryId(e.target.value)}
                                  >
                                    <option> Select Country</option>
                                    {countries?.length > 0 ? (
                                      <>
                                        {countries?.map(country => (
                                          <option
                                            key={country.id}
                                            value={country.id}
                                          >
                                            {country.country}
                                          </option>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>City</Label>

                                  <MyMultiSelectComponent
                                    id={countryId}
                                    onChange={handleCityChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label>Tour Categories</Label>
                                  <Row>
                                    <Inclusions
                                      onInclusionsChange={
                                        handleInclusionsChange
                                      }
                                    />
                                  </Row>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label>Product Inclusions Highlights</Label>
                                  <MyEditor handleChange={handleEditorChange} />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <div>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="product-main-image">
                                      Product Image: (Main Image)
                                    </Label>
                                    <ImageUploadComponent
                                      handleMainImage={handleMainImage}
                                      data={data?.mainImage}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="12">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Extra Images: (Drag and Drop multiple
                                      images up to 6)
                                    </Label>
                                    <ImageUploader
                                      handleImage={handleImage}
                                      data={data?.images}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Form>
                            <div>
                              <ItineraryDayComponent onChange={handleDay} />
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Form>
                            <div>
                              <AccessibilityForm onChange={handleAcc} />
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Form>
                            <div>
                              <HealthRestrictionsForm onChange={handleHR} />
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={6}>
                          <Form>
                            <div className="row justify-content-center">
                              <Col lg="6">
                                <div className="text-center">
                                  <div className="mb-4">
                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                  </div>
                                  <div>
                                    <h5>Confirm Detail</h5>
                                    <p className="text-muted">
                                      Product is Successfully Created!
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        {activeTabVartical < 6 ? (
                          <>
                            <li
                              className={
                                activeTabVartical === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                        <li
                          className={
                            activeTabVartical === 7 ||
                            (activeTabVartical === 1 && isLoading === true) ||
                            (activeTabVartical === 2 && isLoading === true)
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              if (activeTabVartical === 1) {
                                handleSubmit()
                                // if (isLoading) {
                                // setIsLoading(false)
                                // }
                              } else if (activeTabVartical === 2) {
                                submitImages(),
                                  setFetchImg(true),
                                  console.log("images")
                              } else if (activeTabVartical === 3) {
                                submitItinerary(),
                                  console.log("itinerary"),
                                  toggleTabVertical(activeTabVartical + 1)
                              } else if (activeTabVartical === 4) {
                                submitAccess(),
                                  console.log("access"),
                                  toggleTabVertical(activeTabVartical + 1)
                              } else if (activeTabVartical === 5) {
                                submitHealthRes(),
                                  console.log("heath Res"),
                                  toggleTabVertical(activeTabVartical + 1)
                              } else if (activeTabVartical === 6) {
                                // submitScheduleFormData(),
                                // console.log("schedule"),
                                // toggleTabVertical(activeTabVartical + 1)
                              } else {
                              }
                            }}
                          >
                            {activeTabVartical === 1 ||
                            activeTabVartical === 2 ? (
                              <>{isLoading ? <>Loading...</> : <> Next</>}</>
                            ) : activeTabVartical === 6 ? (
                              <Link to="/packages">Finish</Link>
                            ) : (
                              <> Next</>
                            )}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreatePackages
