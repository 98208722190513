// // import React, { useEffect, useState } from "react"
// // import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

// // const HealthRestrictionsForm = ({ onChange }) => {
// //   const [healthRestrictions, setHealthRestrictions] = useState([
// //     {
// //       id: 1,
// //       question: "Not recommended for travelers with back problems",
// //       checked: false,
// //     },
// //     {
// //       id: 2,
// //       question: "Not recommended for pregnant travelers",
// //       checked: false,
// //     },
// //     {
// //       id: 3,
// //       question:
// //         "Not recommended for travelers with heart problems or other serious medical conditions",
// //       checked: false,
// //     },
// //     // More predefined questions can be added here
// //   ])
// //   const [newRestrictionText, setNewRestrictionText] = useState("")
// //   const [showNewRestrictionInput, setShowNewRestrictionInput] = useState(false)

// //   const handleCheckboxChange = (id, checked) => {
// //     setHealthRestrictions(
// //       healthRestrictions.map(restriction =>
// //         restriction.id === id
// //           ? { ...restriction, checked: checked }
// //           : restriction
// //       )
// //     )
// //   }

// //   const handleAddNewRestriction = () => {
// //     if (newRestrictionText.trim()) {
// //       const newId = healthRestrictions.length + 1
// //       setHealthRestrictions([
// //         ...healthRestrictions,
// //         { id: newId, question: newRestrictionText, checked: false },
// //       ])
// //       setNewRestrictionText("")
// //       setShowNewRestrictionInput(false)
// //     }
// //   }

// //   const handleShowNewRestrictionInput = () => {
// //     setShowNewRestrictionInput(true)
// //   }
// //   useEffect(() => {
// //     onChange(healthRestrictions)
// //   }, [handleCheckboxChange, handleAddNewRestriction])

// //   return (
// //     <TabPane tabId={5}>
// //       <h2>Health restrictions</h2>
// //       <p>Check all that apply</p>
// //       {healthRestrictions.map(restriction => (
// //         <FormGroup check key={restriction.id} style={{ marginBottom: 10 }}>
// //           <Label check>
// //             <Input
// //               type="checkbox"
// //               checked={restriction.checked}
// //               onChange={e =>
// //                 handleCheckboxChange(restriction.id, e.target.checked)
// //               }
// //             />
// //             {restriction.question}
// //           </Label>
// //         </FormGroup>
// //       ))}

// //       {showNewRestrictionInput ? (
// //         <>
// //           <FormGroup>
// //             <Label>New Health Restriction:</Label>
// //             <Input
// //               type="text"
// //               value={newRestrictionText}
// //               onChange={e => setNewRestrictionText(e.target.value)}
// //               placeholder="Enter a new health restriction"
// //             />
// //           </FormGroup>
// //           <Button color="success" onClick={handleAddNewRestriction}>
// //             Save
// //           </Button>
// //         </>
// //       ) : (
// //         <Button color="primary" onClick={handleShowNewRestrictionInput}>
// //           + Add another
// //         </Button>
// //       )}
// //     </TabPane>
// //   )
// // }

// // export default HealthRestrictionsForm

// import React, { useEffect, useState } from "react"
// import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

// const HealthRestrictionsForm = ({ onChange, data }) => {
//   // Predefined health restrictions
//   const predefinedRestrictions = [
//     {
//       id: "1",
//       question: "Not recommended for travelers with back problems",
//       checked: false,
//     },
//     {
//       id: "2",
//       question: "Not recommended for pregnant travelers",
//       checked: false,
//     },
//     {
//       id: "3",
//       question:
//         "Not recommended for travelers with heart problems or other serious medical conditions",
//       checked: false,
//     },
//   ]

//   // console.log(data)
//   // Initialize state with a merged list of predefined and incoming data restrictions
//   const [healthRestrictions, setHealthRestrictions] = useState(() => {
//     const dynamicRestrictions =
//       data?.map(d => ({
//         id: d.id,
//         question: d.title,
//         checked: d.check_uncheck === "yes" ? true : false,
//       })) || []

//     // Merge predefined and dynamic restrictions, prioritizing dynamic data
//     return predefinedRestrictions
//       .map(predefined => {
//         const dynamicMatch = dynamicRestrictions.find(
//           d => d.id === predefined.id
//         )
//         return dynamicMatch || predefined
//       })
//       .concat(
//         dynamicRestrictions.filter(
//           d => !predefinedRestrictions.find(p => p.id === d.id)
//         )
//       )
//   })

//   useEffect(() => {
//     onChange(healthRestrictions)
//   }, [healthRestrictions, onChange])

//   const [newRestrictionText, setNewRestrictionText] = useState("")
//   const [showNewRestrictionInput, setShowNewRestrictionInput] = useState(false)

//   const handleCheckboxChange = (id, checked) => {
//     setHealthRestrictions(current =>
//       current.map(restriction =>
//         restriction.id === id ? { ...restriction, checked } : restriction
//       )
//     )
//   }

//   const handleAddNewRestriction = () => {
//     if (newRestrictionText.trim()) {
//       const newId = String(
//         Math.max(0, ...healthRestrictions.map(r => parseInt(r.id, 10))) + 1
//       )
//       setHealthRestrictions(current => [
//         ...current,
//         { id: newId, question: newRestrictionText, checked: false },
//       ])
//       setNewRestrictionText("")
//       setShowNewRestrictionInput(false)
//     }
//   }

//   return (
//     <TabPane tabId="healthRestrictions">
//       <h2>Health Restrictions</h2>
//       {healthRestrictions.map(restriction => (
//         <FormGroup check key={restriction.id}>
//           <Label check>
//             <Input
//               type="checkbox"
// checked={restriction.checked == true ? true : false || ""}
// onChange={e =>
//   handleCheckboxChange(restriction.id, e.target.checked)
// }
// onClick={e => {
//   e.preventDefault()
//   if (restriction.checked == false) {
//     handleCheckboxChange(
//       restriction.id,
//       (e.target.checked = true)
//     )
//   } else {
//     handleCheckboxChange(
//       restriction.id,
//       (e.target.checked = false)
//     )
//   }
//               }}
//             />{" "}
//             {restriction.question}
//           </Label>
//         </FormGroup>
//       ))}

//       {showNewRestrictionInput ? (
//         <>
//           <FormGroup>
//             <Label>New Health Restriction:</Label>
//             <Input
//               type="text"
//               value={newRestrictionText}
//               onChange={e => setNewRestrictionText(e.target.value)}
//               placeholder="Enter a new health restriction"
//             />
//           </FormGroup>
//           <Button color="success" onClick={handleAddNewRestriction}>
//             Add
//           </Button>
//         </>
//       ) : (
//         <Button
//           color="primary"
//           onClick={() => setShowNewRestrictionInput(true)}
//         >
//           + Add Another
//         </Button>
//       )}
//     </TabPane>
//   )
// }

// export default HealthRestrictionsForm

// import React, { useEffect, useState } from "react"
// import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

// const HealthRestrictionsForm = ({ onChange, data }) => {
//   // Predefined health restrictions
//   const predefinedRestrictions = [
//     {
//       id: "1",
//       question: "Not recommended for travelers with back problems",
//       checked: false,
//     },
//     {
//       id: "2",
//       question: "Not recommended for pregnant travelers",
//       checked: false,
//     },
//     {
//       id: "3",
//       question:
//         "Not recommended for travelers with heart problems or other serious medical conditions",
//       checked: false,
//     },
//   ]

//   // Initialize state with a merged list of predefined and incoming data restrictions
//   const [healthRestrictions, setHealthRestrictions] = useState(() => {
//     // Convert incoming data to expected format
//     const dynamicRestrictions =
//       data?.map(d => ({
//         id: d.id,
//         question: d.title,
//         checked: d.check_uncheck === "yes",
//       })) || []

//     // Create a lookup for dynamic restrictions
//     const dynamicLookup = dynamicRestrictions.reduce((acc, cur) => {
//       acc[cur.id] = cur.checked
//       return acc
//     }, {})

//     // Merge predefined restrictions with dynamic data, using dynamic data to set 'checked'
//     const mergedRestrictions = predefinedRestrictions.map(restriction => ({
//       ...restriction,
//       checked: dynamicLookup.hasOwnProperty(restriction.id)
//         ? dynamicLookup[restriction.id]
//         : restriction.checked,
//     }))

//     // Add dynamic restrictions that are not in predefined list
//     const additionalDynamicRestrictions = dynamicRestrictions.filter(
//       d => !predefinedRestrictions.some(p => p.id === d.id)
//     )

//     return [...mergedRestrictions, ...additionalDynamicRestrictions]
//   })

//   useEffect(() => {
//     onChange(healthRestrictions)
//   }, [healthRestrictions, onChange])

//   const [newRestrictionText, setNewRestrictionText] = useState("")
//   const [showNewRestrictionInput, setShowNewRestrictionInput] = useState(false)

//   const handleCheckboxChange = (id, checked) => {
//     setHealthRestrictions(current =>
//       current.map(restriction =>
//         restriction.id === id ? { ...restriction, checked } : restriction
//       )
//     )
//   }

//   const handleAddNewRestriction = () => {
//     if (newRestrictionText.trim()) {
//       const newId = String(
//         Math.max(0, ...healthRestrictions.map(r => parseInt(r.id, 10))) + 1
//       )
//       setHealthRestrictions(current => [
//         ...current,
//         { id: newId, question: newRestrictionText, checked: true },
//       ])
//       setNewRestrictionText("")
//       setShowNewRestrictionInput(false)
//     }
//   }

//   return (
//     <TabPane tabId="healthRestrictions">
//       <h2>Health Restrictions</h2>
//       {healthRestrictions.map(restriction => (
//         <FormGroup check key={restriction.id}>
//           <Label check>
//             <Input
//               type="checkbox"
//               checked={restriction.checked}
//               onChange={e =>
//                 handleCheckboxChange(restriction.id, e.target.checked)
//               }
//             />
//             {restriction.question}
//           </Label>
//         </FormGroup>
//       ))}

// {showNewRestrictionInput ? (
//   <>
//     <FormGroup>
//       <Label>New Health Restriction:</Label>
//       <Input
//         type="text"
//         value={newRestrictionText}
//         onChange={e => setNewRestrictionText(e.target.value)}
//         placeholder="Enter a new health restriction"
//       />
//     </FormGroup>
//     <Button color="success" onClick={handleAddNewRestriction}>
//       Add
//     </Button>
//   </>
// ) : (
//   <Button
//     color="primary"
//     onClick={() => setShowNewRestrictionInput(true)}
//   >
//     + Add Another
//   </Button>
// )}
//     </TabPane>
//   )
// }

// export default HealthRestrictionsForm
// import React, { useEffect, useState } from "react"
// import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

// const predefinedRestrictions = [
//   {
//     id: "1",
//     question: "Not recommended for travelers with back problems",
//     answer: false,
//   },
//   {
//     id: "2",
//     question: "Not recommended for pregnant travelers",
//     answer: false,
//   },
//   {
//     id: "3",
//     question:
//       "Not recommended for travelers with heart problems or other serious medical conditions",
//     answer: false,
//   },
//   // Add more predefined restrictions if needed
// ]

// const HealthRestrictionsForm = ({ onChange, data }) => {
//   const [healthRestrictions, setHealthRestrictions] = useState([])

//   useEffect(() => {
//     const incomingRestrictions =
//       data?.map(d => ({
//         id: d.id,
//         question: d.title,
//         answer: d.check_uncheck === "yes" ? true : false,
//       })) || []

//     const mergedRestrictions = predefinedRestrictions.map(pq => {
//       const found = incomingRestrictions.find(iq => iq.question === pq.question)
//       return found || { ...pq, answer: "" }
//     })

//     setHealthRestrictions(mergedRestrictions)
//   }, [data])
//   const handleChange = (id, value) => {
//     setHealthRestrictions(current =>
//       current.map(q => (q.id === id ? { ...q, answer: value } : q))
//     )
//     // setHealthRestrictions(
//     //   healthRestrictions.map(restriction =>
//     //     restriction.id === id
//     //       ? { ...restriction, checked: checked }
//     //       : restriction
//     //   )
//     // )
//   }

//   const [newRestrictionText, setNewRestrictionText] = useState("")
//   const [showNewRestrictionInput, setShowNewRestrictionInput] = useState(false)

//   const handleAddNewOption = () => {
//     if (newRestrictionText.trim()) {
//       const newId = `new-${healthRestrictions.length + 1}`
//       setHealthRestrictions(current => [
//         ...current,
//         { id: newId, question: newRestrictionText, answer: "" },
//       ])
//       setNewRestrictionText("")
//       setShowNewRestrictionInput(false)
//     }
//   }

//   useEffect(() => {
//     // console.log(healthRestrictions)

//     onChange(healthRestrictions)
//     // console.log(healthRestrictions)
//   }, [healthRestrictions, onChange])

//   return (
//     <TabPane tabId="healthRestrictions">
//       <h2>Health Restrictions</h2>
//       {healthRestrictions.map(item => (
//         <FormGroup key={item.id}>
//           <Label>
//             <Input
//               type="checkbox"
//               name={`restriction-${item.id}`}
//               value="yes"
//               // checked={item.answer === "yes" ? true : false}
//               // onChange={() => handleChange(item.id, "yes")}
//               checked={item.answer === true ? true : false}
//               onChange={e => handleChange(item.id, e.target.checked)}
//               onClick={e => {
//                 e.preventDefault()
//                 // if (e.target.checked == true || item.answer === "yes") {
//                 //   handleChange(item.id, (e.target.checked = false))
//                 // } else if (e.target.checked == false) {
//                 //   handleChange(item.id, (e.target.checked = "yes"))
//                 // }
//                 if (item.answer === false) {
//                   handleChange(item.id, (e.target.checked = true))
//                 } else {
//                   handleChange(item.id, (e.target.checked = false))
//                 }
//               }}
//             />{" "}
//             {item.question}
//           </Label>
//         </FormGroup>
//       ))}
//       {/* {healthRestrictions.map(restriction => (
//         <FormGroup check key={restriction.id}>
//           <Label check>
//             <Input
//               type="checkbox"
//               checked={restriction.checked}
//               onChange={e =>
//                 handleCheckboxChange(restriction.id, e.target.checked)
//               }
//             />
//             {restriction.question}
//           </Label>
//         </FormGroup>
//       ))} */}

//       {showNewRestrictionInput ? (
//         <>
//           <FormGroup>
//             <Label>New Health Restriction:</Label>
//             <Input
//               type="text"
//               value={newRestrictionText}
//               onChange={e => setNewRestrictionText(e.target.value)}
//               placeholder="Enter a new health restriction"
//             />
//           </FormGroup>
//           <Button color="success" onClick={handleAddNewOption}>
//             Add
//           </Button>
//         </>
//       ) : (
//         <Button
//           color="primary"
//           onClick={() => setShowNewRestrictionInput(true)}
//         >
//           + Add New Restriction
//         </Button>
//       )}
//     </TabPane>
//   )
// }

// export default HealthRestrictionsForm

import React, { useEffect, useState } from "react"
import { TabPane, FormGroup, Label, Input, Button } from "reactstrap"

const predefinedRestrictions = [
  {
    id: "1",
    question: "Not recommended for travelers with back problems",
    answer: false,
  },
  {
    id: "2",
    question: "Not recommended for pregnant travelers",
    answer: false,
  },
  {
    id: "3",
    question:
      "Not recommended for travelers with heart problems or other serious medical conditions",
    answer: false,
  },
  // Add more predefined restrictions if needed
]

const HealthRestrictionsForm = ({ onChange, data }) => {
  const [healthRestrictions, setHealthRestrictions] = useState([])

  useEffect(() => {
    // Convert incoming data to expected format and include additional checks
    const incomingRestrictions =
      data?.map(d => ({
        id: d.id,
        question: d.title,
        answer: d.check_uncheck === "yes",
      })) || []

    // Update predefined restrictions with answers from incoming data
    const updatedPredefinedRestrictions = predefinedRestrictions.map(pr => {
      const found = incomingRestrictions.find(ir => ir.question === pr.question)
      return found ? { ...pr, answer: found.answer } : pr
    })

    // Find additional restrictions in incoming data not present in predefined set
    const additionalRestrictions = incomingRestrictions.filter(
      ir => !predefinedRestrictions.some(pr => pr.question === ir.question)
    )

    // Merge updated predefined restrictions with any additional restrictions
    const mergedRestrictions = [
      ...updatedPredefinedRestrictions,
      ...additionalRestrictions,
    ]

    setHealthRestrictions(mergedRestrictions)
  }, [data])

  const handleChange = (id, checked) => {
    setHealthRestrictions(current =>
      current.map(restriction =>
        restriction.id === id
          ? { ...restriction, answer: checked }
          : restriction
      )
    )
  }

  const [newRestrictionText, setNewRestrictionText] = useState("")
  const [showNewRestrictionInput, setShowNewRestrictionInput] = useState(false)

  const handleAddNewOption = () => {
    if (newRestrictionText.trim()) {
      const newId = `new-${healthRestrictions.length + 1}`
      setHealthRestrictions(current => [
        ...current,
        { id: newId, question: newRestrictionText, answer: "" },
      ])
      setNewRestrictionText("")
      setShowNewRestrictionInput(false)
    }
  }

  useEffect(() => {
    // console.log(healthRestrictions)

    onChange(healthRestrictions)
    // console.log(healthRestrictions)
  }, [healthRestrictions, onChange])

  return (
    <TabPane tabId="healthRestrictions">
      <h2>Health Restrictions</h2>
      {healthRestrictions.map(item => (
        <FormGroup key={item.id}>
          <Label>
            <Input
              type="checkbox"
              name={`restriction-${item.id}`}
              value="yes"
              checked={item.answer === true ? true : false}
              onChange={e => handleChange(item.id, e.target.checked)}
              onClick={e => {
                e.preventDefault()
                if (item.answer === false) {
                  handleChange(item.id, (e.target.checked = true))
                } else {
                  handleChange(item.id, (e.target.checked = false))
                }
              }}
            />{" "}
            {item.question}
          </Label>
        </FormGroup>
      ))}
      {/* {healthRestrictions.map(restriction => (
        <FormGroup check key={restriction.id}>
          <Label check>
            <Input
              type="checkbox"
              checked={restriction.checked}
              onChange={e =>
                handleCheckboxChange(restriction.id, e.target.checked)
              }
            />
            {restriction.question}
          </Label>
        </FormGroup>
      ))} */}

      {showNewRestrictionInput ? (
        <>
          <FormGroup>
            <Label>New Inclusions:</Label>
            <Input
              type="text"
              value={newRestrictionText}
              onChange={e => setNewRestrictionText(e.target.value)}
              placeholder="Enter a new health restriction"
            />
          </FormGroup>
          <Button color="success" onClick={handleAddNewOption}>
            Add
          </Button>
        </>
      ) : (
        <Button
          color="primary"
          onClick={() => setShowNewRestrictionInput(true)}
        >
          + Add New Inclusions
        </Button>
      )}
    </TabPane>
  )
}

export default HealthRestrictionsForm
