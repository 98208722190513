import React, { useEffect, useState } from "react"
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap"

const MultiSelectTimes = ({ selectedOptions, onChange, value }) => {
  const [times, setTimes] = useState([])
  const [newTime, setNewTime] = useState("")

  useEffect(() => {
    if (value && Array.isArray(value[0])) {
      setTimes(
        value[0]
          .map(item => ({ time: item.pickup_time }))
          .sort((a, b) => a.time.localeCompare(b.time))
      )
    }
  }, [value])

  const handleAdd = () => {
    if (newTime) {
      const newTimes = [...times, { time: newTime }].sort((a, b) =>
        a.time.localeCompare(b.time)
      )
      setTimes(newTimes)
      onChange(newTimes)
      setNewTime("")
    }
  }

  const handleRemove = index => {
    const newTimes = [...times]
    newTimes.splice(index, 1)
    newTimes.sort((a, b) => a.time.localeCompare(b.time))
    setTimes(newTimes)
    onChange(newTimes)
  }

  return (
    <div>
      {/* <h4>Added Times</h4> */}
      <ul>
        {times?.map((item, index) => (
          <li key={index} className="mb-2 ">
            <span>{item.time}</span>{" "}
            <Button
              color="danger"
              size="sm"
              onClick={() => handleRemove(index)}
              style={{ marginLeft: 20 }}
              className="btn btn-sm btn-soft-danger"
            >
              <i className="mdi mdi-delete-outline" id="deletetooltip" />
            </Button>
          </li>
        ))}
      </ul>
      {/* <h4>Add New Time</h4> */}
      <Row className="mb-3">
        <Col md={10}>
          <FormGroup>
            <Label for="new-time">Time</Label>
            <Input
              type="time"
              id="new-time"
              value={newTime}
              onChange={e => setNewTime(e.target.value)}
              placeholder="Enter Time"
            />
          </FormGroup>
        </Col>
        <Col md={2} className="d-flex align-items-center mt-3">
          <Button color="primary" onClick={handleAdd}>
            Add
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default MultiSelectTimes
