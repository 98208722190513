// src/components/filter.
import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { useMediaQuery } from "react-responsive"
import * as Yup from "yup"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import TableContainer from "../../components/Common/TableContainer"
import { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  InputGroup,
  FormGroup,
  Button,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabPane,
  NavItem,
  TabContent,
} from "reactstrap"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import axios from "axios"
import MapComponent from "./map"
import classnames from "classnames"
import { LoadScript, Autocomplete } from "@react-google-maps/api"
import countryCodes from "./countryCode" // Import the country codes data

function AddNewJob() {
  // add new job
  const [isEdit, setIsEdit] = useState(false)
  const [data_attr_luggage, setdata_attr_luggage] = useState(0)
  // const [data_attr_pax, setdata_attr_pax] = useState(0)
  const [place1, setPlace1] = useState(null)
  const [place2, setPlace2] = useState(null)
  const [place3, setPlace3] = useState(null)
  const [duration, setDuration] = useState(0)
  const [distance, setDistance] = useState(0)
  const travelMode = "DRIVING"
  const [jobsList, setJobsList] = useState([])
  const [job, setJob] = useState(null)
  const [error, setError] = useState("")
  const [successNoti, setSuccessNoti] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [selectedMulti2, setselectedMulti2] = useState(null)
  const [textareabadge, settextareabadge] = useState(0)
  const [textareabadge2, settextareabadge2] = useState(0)
  const [textareabadge3, settextareabadge3] = useState(0)
  const [rows1, setRows1] = useState([{ id: 1, value: "" }])
  const [row1, setRow1] = useState()
  const [row2, setRow2] = useState()
  const [row3, setRow3] = useState()
  const [textcount, settextcount] = useState(0)
  const [textcount2, settextcount2] = useState(0)
  const [textcount3, settextcount3] = useState(0)
  const [vehicleOptions, setVehicleOptions] = useState([])
  const [isOptionsFetched, setIsOptionsFetched] = useState(false)
  const [agentOptions, setAgentOptions] = useState([])
  const [isAgentOptionsFetched, setIsAgentOptionsFetched] = useState(false)
  const [driverOptions, setDriverOptions] = useState([])
  const [selectedVehicleType, setSelectedVehicleType] = useState([])
  const [isDriversFetched, setIsDriversFetched] = useState(false)
  const [citiesOptions, setcitiesOptions] = useState()
  const [isCitiesOptionsFetched, setIsCitiesOptionsFetched] = useState(false)
  const [fare, setFare] = useState(0)
  const [currency, setCurrency] = useState("USD")
  const [item1, setItem1] = useState(0)
  const [item2, setItem2] = useState(0)
  const [item3, setItem3] = useState(0)
  const [item4, setItem4] = useState(0)
  const [item5, setItem5] = useState(0)
  const [miscCharges, setMiscCharges] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [countpax, setCountpax] = useState(0)
  const [countluggage, setCountluggage] = useState(0)
  const [formData, setFormData] = useState([])
  const [bookingNo, setBookingNo] = useState()
  const [bookingEmail, setBookingEmail] = useState()
  const [invoiceSuccessNoti, setInvoiceSuccessNoti] = useState()
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  console.log(selectedVehicleType.id, selectedVehicleType.value)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      pickupDate: (job && job.pickupDate) || "",
      pickupTime: (job && job.pickupTime) || "",
      bookingCity: (job && job.bookingCity) || "",
      agentId: (job && job.agentId) || "",
      // vehicleType: (job && job.vehicleType) || "",
      luggage: (job && job.luggage) || countluggage || "",
      pax: (job && job.pax) || countpax || "",
      name: (job && job.name) || "",
      // country: (job && job.country) || "",
      countryCode: (job && job.countryCode) || countryCode || "",
      phone: (job && job.phone) || "",
      email: (job && job.email) || "",
      country: (job && job.country) || "",
      clientNotes: (job && job.clientNotes) || "",
      pickupLocation:
        (job && job.pickupLocation) || place1?.formatted_address || "",
      dropoffLocation:
        (job && job.dropoffLocation) || place2?.formatted_address || "",
      pickupInstruction: (job && job.pickupInstruction) || "",
      flight: (job && job.flight) || "",
      vehicleTypeApi:
        (job && job.vehicleTypeApi) || selectedVehicleType.id || "",
      driver: (job && job.driver) || "",
      fare: (job && job.fare) || "",
      type: (job && job.type) || "",
      notes: (job && job.notes) || "",
      stop1: (job && job.stop1) || row1?.formatted_address || "",
      stop2: (job && job.stop1) || row2?.formatted_address || "",
      stop3: (job && job.stop1) || row3?.formatted_address || "",

      currency: (job && job.currency) || "",
      extraItem1: (job && job.extraItem1) || "",
      extraItem1Price: (job && job.extraItem1Price) || "",
      extraItem2: (job && job.extraItem2) || "",
      extraItem2Price: (job && job.extraItem2Price) || "",
      extraItem3: (job && job.extraItem3) || "",
      extraItem3Price: (job && job.extraItem3Price) || "",
      extraItem4: (job && job.extraItem4) || "",
      extraItem4Price: (job && job.extraItem4Price) || "",
      extraItem5: (job && job.extraItem5) || "",
      extraItem5Price: (job && job.extraItem5Price) || "",
      miscCharges: (job && job.miscCharges) || "",
      discount: (job && job.discount) || "",
      bookingId: (job && job.bookingId) || "",
      // token: (job && job.token) || "",
      cardName: (job && job.cardName) || "",
      cardNumber: (job && job.cardNumber) || "",
      cardExpiryMonth: (job && job.cardExpiryMonth) || "",
      cardExpiryYear: (job && job.cardExpiryYear) || "",
      cardCvv: (job && job.cardCvv) || "",
      // status: (job && job.status) || "",
    },
    validationSchema: Yup.object({
      pickupDate: Yup.string().required("Please Enter Your Pickup Date"),
      pickupTime: Yup.string().required("Please Enter Your Pickup Time"),
      // bookingCity: Yup.string().required("Please Enter Your Job Title"),
      // agentId: Yup.string().required("Please Enter Your Agent ID"),
      // // vehicleType: Yup.string().required("Please Enter Your Vehicle Type"),
      // luggage: Yup.string().required("Please Enter Your Luggage"),
      // pax: Yup.string().required("Please Enter Your pax"),
      name: Yup.string().required("Please Enter Your Name"),
      // phone: Yup.string().required("Please Enter Your Phone"),
      // email: Yup.string().required("Please Enter Your Email"),
      // clientNotes: Yup.string().required("Please Enter Your Client Notes"),
      // pickupLocation: Yup.string().required(
      //   "Please Enter Your Pickup Location"
      // ),
      // dropoffLocation: Yup.string().required(
      //   "Please Enter Your Dropoff Location"
      // ),
      // pickupInstruction: Yup.string().required(
      //   "Please Enter Your Pickup Instruction"
      // ),
      // flight: Yup.string().required("Enter Your Flight Number"),
      vehicleTypeApi: Yup.string().required("Please Enter Your Vehicle Type"),
      // driver: Yup.string().required("Please Enter Your Driver"),
      // fare: Yup.string().required("Please Enter Your Fare"),
      // type: Yup.string().required("Please Enter Your Payment Type"),
      // waitTime: Yup.number().required("Please Enter Wait Time"),
      // currency: Yup.string().required("Please Enter Currency"),
      // notes: Yup.string().required("Please Enter Your Notes"),
      // extraItem1: Yup.string().required("Please Enter Your Child Seat "),
      // extraItem1Price: Yup.number().required(
      //   "Please Enter Your  Child Seat Price"
      // ),
      // extraItem2: Yup.string().required("Please Enter Your Child Seat"),
      // extraItem2Price: Yup.number().required(
      //   "Please Enter Your  Child Seat Price"
      // ),
      // extraItem3: Yup.string().required("Please Enter Your Extra Item"),
      // extraItem3Price: Yup.number().required(
      //   "Please Enter Your Extra Item Price"
      // ),
      // extraItem4: Yup.string().required("Please Enter Your Extra Item"),
      // extraItem4Price: Yup.number().required(
      //   "Please Enter Your Extra Item Price"
      // ),
      // extraItem5: Yup.string().required("Please Enter Your Extra Item"),
      // extraItem5Price: Yup.number().required(
      //   "Please Enter Your Extra Item Price"
      // ),
      // bookingId: Yup.string().required("Please Enter Your Booking ID"),
      // cardName: Yup.string().required("Please Enter Your Card Name"),
      // cardNumber: Yup.number().required("Please Enter Your Card Number"),
      // cardExpiryMonth: Yup.number().required(
      //   "Please Enter Your Card Expiry Month"
      // ),
      // cardExpiryYear: Yup.number().required(
      //   "Please Enter Your Card Expiry Year"
      // ),
      // cardCvv: Yup.number().required("Please Enter Your Card CVV "),
      // token: Yup.string().required("Please Enter Your Token"),
      // status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateJobList = {
          pickupDate: values.pickupDate,
          pickupTime: values.pickupTime,
          bookingCity: values.bookingCity,
          agentId: values.agentId,
          // vehicleType: values.vehicleType,
          luggage: countluggage,
          pax: values.pax,
          name: values.name,
          countryCode: countryCode,
          phone: values.phone,
          email: values.email,
          stop1: row1,
          stop2: row2,
          stop3: row3,
          country: values.country,
          clientNotes: values.clientNotes,
          pickupLocation: values.pickupLocation,
          dropoffLocation: values.dropoffLocation,
          pickupInstruction: values.pickupInstruction,
          flight: values.flight,
          vehicleTypeApi: values.vehicleTypeApi,
          driver: values.driver,
          fare: values.fare,
          paymentType: values.type,
          waitTime: values.waitTime,
          paymentTypeDriver: values.paymentTypeDriver,
          notes: values.notes,
          currency: values.currency,
          extraItem1: values.extraItem1,
          extraItem1Price: values.extraItem1Price,
          extraItem2: values.extraItem2,
          extraItem2Price: values.extraItem2Price,
          extraItem3: values.extraItem3,
          extraItem3Price: values.extraItem3Price,
          extraItem4: values.extraItem4,
          extraItem4Price: values.extraItem4Price,
          extraItem5: values.extraItem4,
          extraItem5Price: values.extraItem4Price,
          miscCharges: values.miscCharges,
          discount: values.discount,
          bookingId: values.bookingId,
          // token: values.token,
          cardName: values.cardName,
          cardNumber: values.cardNumber,
          cardExpiryMonth: values.cardExpiryMonth,
          cardExpiryYear: values.cardExpiryYear,
          cardCvv: values.cardCvv,
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          // status: values.status,
        }
        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const newJobList = {
          pickupDate: values["pickupDate"],
          pickupTime: values["pickupTime"],
          bookingCity: values["bookingCity"],
          agentId: values["agentId"],
          // vehicleType: values["vehicleType"],
          luggage: values["luggage"],
          pax: values["pax"],
          name: values["name"],
          countryCode: values["countryCode"],
          phone: values["phone"],
          email: values["email"],
          country: values["country"],
          clientNotes: values["clientNotes"],
          pickupLocation: (values["pickupLocation"] =
            place1?.formatted_address),
          dropoffLocation: (values["dropoffLocation"] =
            place2?.formatted_address),
          pickupInstruction: values["pickupInstruction"],
          flight: values["flight"],
          vehicleTypeApi: values["vehicleTypeApi"],
          driver: values["driver"],
          fare: values["fare"],
          paymentType: values["type"],
          waitTime: values["waitTime"],
          notes: values["notes"],
          currency: values["currency"],
          extraItem1: values["extraItem1"],
          extraItem1Price: values["extraItem1Price"],
          extraItem2: values["extraItem2"],
          extraItem2Price: values["extraItem2Price"],
          extraItem3: values["extraItem3"],
          extraItem3Price: values["extraItem3Price"],
          extraItem4: values["extraItem4"],
          extraItem4Price: values["extraItem4Price"],
          extraItem5: values["extraItem5"],
          extraItem5Price: values["extraItem5Price"],
          miscCharges: values["miscCharges"],
          discount: values["discount"],
          bookingId: values["bookingId"],
          // token: values["token"],
          cardName: values["cardName"],
          cardNumber: values["cardNumber"],
          cardExpiryMonth: values["cardExpiryMonth"],
          cardExpiryYear: values["cardExpiryYear"],
          cardCvv: values["cardCvv"],
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          // status: values["status"],
        }
        // save new Job
        dispatch(onAddNewJobList(newJobList))
        setFormData(values)
        setBookingEmail(validation.values.email)
        validation.resetForm()
      }
      // toggle()
      console.log("sent", values)
      handleSend(values)
    },
  })

  console.log("vehicle", validation.values.vehicleTypeApi)
  // console.log("booking no", bookingNo)
  // send invoice
  const sendBookingEmail = async () => {
    try {
      const apiToken = localStorage.getItem("apiToken") // Get the API token from local storage
      const value = JSON.parse(apiToken)
      const api = value.API
      // console.log("sendbooking", api)
      // console.log("booking:", bookingNo)
      // console.log("booking email:", bookingEmail)
      const res = await axios.post(
        "https://flymiles.com.au/adminapi/public/api/sendbookingemailondemand",
        {
          booking_no: bookingNo,
          customer_email: bookingEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${api}`, // Include the API token in the Authorization header
          },
        }
      )
      if (res.status === 200) {
        // Set a success message
        setInvoiceSuccessNoti("Invoice has been sent successfully.")
      }
      // console.log(res)
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  // send data

  const req = async data => {
    const apiToken = localStorage.getItem("apiToken") // Get the API token from local storage
    const value = JSON.parse(apiToken)
    const api = value.API
    const headers = { Authorization: `Bearer ${api}` }
    try {
      setIsLoading(true)
      const response = await axios.post(
        "https://flymiles.com.au/adminapi/public/api/savebooking",
        data,
        { headers }
      )
      // console.log(response)
      // console.log(response.data.booking_no)
      // console.log(validation.values.email)

      setSuccessNoti("Booking is Successful.")
      // console.log(formData)
      setBookingNo(response?.data?.booking_no)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.exception === "Stripe\\Exception\\CardException"
      ) {
        // Handle Stripe card error
        setIsLoading(false)

        setError(error.response.data.message) // Extract and set the error message
      } else {
        // Handle other errors
        setIsLoading(false)

        setError("An error occurred") // Or use a more general error message
      }

      throw error
    } finally {
      setIsLoading(false)
    }
  }
  console.log(validation.values.pickupDate)
  const handleSend = async values => {
    const requestBody = {
      pickup_date: values.pickupDate,
      pickup_time: values.pickupTime,
      booking_city: values.bookingCity,
      agent_id: values.agentId,
      vehicletype: values.vehicleTypeApi,
      pickuppassenger: values.pax,
      luggage: values.luggage,
      name: values.name,
      country: values.country,
      phone: values.countryCode + values.phone,
      email: values.email,
      Payment_type_driver: values.paymentTypeDriver,
      client_notes: values.clientNotes,
      pickup_address: place1?.formatted_address,
      drop_off: place2?.formatted_address,
      pickup_stop1: row1,
      pickup_stop2: row2,
      pickup_stop3: row3,
      pickup_instruction: values.pickupInstruction,
      driver_id: values.driver,
      subtotal: subTotal,
      extra_item1: values.extraItem1,
      extra_item1_price: values.extraItem1Price,
      extra_item2: values.extraItem2,
      extra_item2_price: values.extraItem2Price,
      extra_item3: values.extraItem3,
      extra_item3_price: values.extraItem3Price,
      extra_item4: values.extraItem4,
      extra_item4_price: values.extraItem4Price,
      extra_item5: values.extraItem5,
      extra_item5_price: values.extraItem5Price,
      price: subTotal,
      payment_type: values.type,
      token: "tok_1NfiURDA1JI4tuXPRQ9D8hbi",
      flight: values.flight,
      card_name: "shahzad",
      card_number: "4242424242424242",
      card_month: "8",
      card_year: "2024",
      card_cvc: "234",
      status: "1",
      pricing_instruction: values.notes,
      currency: values.currency,
      wait_time: values.waitTime,
      groupon: values.bookingId,
      distance: distance,
      duration: duration,
    }

    try {
      const response = await req(requestBody)
      console.log("Response:", response.data)
    } catch (error) {
      console.error("Error:", error.response)
    } finally {
      setIsLoading(false)
    }
  }

  // location
  console.log(place1)
  useEffect(() => {
    calculateDistance()
  }, [place1, place2])

  const handlePlace1Change = place => {
    setPlace1(place)
  }

  const handlePlace2Change = place => {
    setPlace2(place)
  }

  // const handlePlace3Change = selectedPlace => {
  //   const updatedRows = [...rows1];
  //   updatedRows[selectedRowIndex].value = selectedPlace.formatted_address; // or any property you want from the selectedPlace
  //   setRows1(updatedRows); // Update the state
  // };

  const calculateDistance = () => {
    if (!place1 || !place2) {
      setDistance(0)
      setDuration(0) // Initialize duration as well
      return
    }

    const service = new window.google.maps.DistanceMatrixService()
    service.getDistanceMatrix(
      {
        origins: [{ placeId: place1.place_id }],
        destinations: [{ placeId: place2.place_id }],
        travelMode: travelMode,
      },
      (response, status) => {
        if (status === "OK") {
          const distanceInMeters = response?.rows[0].elements[0].distance.value
          const durationInSeconds = response?.rows[0].elements[0].duration.value

          const distanceInKms = (distanceInMeters / 1000).toFixed(2)
          const durationInMinutes = (durationInSeconds / 60).toFixed(0)

          setDistance(distanceInKms)
          setDuration(durationInMinutes)
        } else {
          setDistance(0)
          setDuration(0)
        }
      }
    )
  }

  // handle jobs
  const dispatch = useDispatch()
  const { jobs } = useSelector(state => ({
    jobs: state.JobReducer.jobs,
  }))

  useEffect(() => {
    if (jobs && !jobs.length) {
      dispatch(onGetJobList())
    }
  }, [dispatch, jobs])

  useEffect(() => {
    setJobsList(jobs)
  }, [jobs])

  useEffect(() => {
    if (!isEmpty(jobs) && !!isEdit) {
      setJobsList(jobs)
      setIsEdit(false)
    }
  }, [jobs])

  const handleJobClick = arg => {
    const job = arg
    setJob({
      pickupDate: job.pickupDate,
      pickupTime: job.pickupTime,
      bookingCity: job.bookingCity,
      agentId: job.agentId,
      // vehicleType: job.vehicleType,
      name: job.name,
      phone: job.phone,
      email: job.email,
      clientNotes: job.clientNotes,
      pickupLocation: job.pickupLocation,
      dropoffLocation: job.dropoffLocation,
      pickupInstruction: job.pickupInstruction,
      vehicleTypeApi: job.vehicleTypeApi,
      fare: job.fare,
      paymentType: job.paymentType,
      waitTime: job.waitTime,
      notes: job.notes,
      status: job.status,
    })

    setIsEdit(true)

    toggle()
  }

  // end add new job

  // vehicle api
  const fetchOptions = () => {
    const apiToken = localStorage.getItem("apiToken") // Get the API token from local storage
    const value = JSON.parse(apiToken)
    const api = value.API
    // Fetch options from API
    axios
      .get("https://flymiles.com.au/adminapi/public/api/vehicles", {
        headers: {
          Authorization: `Bearer ${api}`,
        },
      })
      .then(response => {
        const apiOptions = response.data
        // .map(item => item.type_name)
        setVehicleOptions(apiOptions)
        setIsOptionsFetched(true)
      })
      .catch(error => console.error(error))
  }
  useEffect(() => {
    if (!isOptionsFetched) {
      fetchOptions()
    }
  }, [isOptionsFetched])

  const fetchDrivers = vehicleTypeId => {
    const apiToken = localStorage.getItem("apiToken") // Get the API token from local storage
    const value = JSON.parse(apiToken)
    const api = value.API
    // console.log("id", vehicleTypeId)
    // console.log("id", vehicleTypeId)
    axios
      .post(
        "https://flymiles.com.au/adminapi/public/api/drivers",
        { vehicle_id: vehicleTypeId },
        {
          headers: {
            Authorization: `Bearer ${api}`,
          },
        }
      )
      .then(response => {
        setDriverOptions(response.data["driver"])
        setIsDriversFetched(true)
      })
      .catch(error => console.error(error))
  }
  // console.log(driverOptions)

  useEffect(() => {
    if (selectedVehicleType && !isDriversFetched) {
      fetchDrivers(selectedVehicleType)
    }
  }, [selectedVehicleType, isDriversFetched])

  const fetchCities = () => {
    const apiToken = localStorage.getItem("apiToken")
    const value = JSON.parse(apiToken)
    const api = value.API

    axios
      .get("https://flymiles.com.au/adminapi/public/api/cities", {
        headers: {
          Authorization: `Bearer ${api}`,
          "Content-Type": "application/json", // Add Content-Type header
        },
      })
      .then(response => {
        const apiCities = response.data
        // Handle the retrieved data as needed
        // console.log(apiCities)
        setcitiesOptions(apiCities)
        setIsCitiesOptionsFetched(true)
      })
      .catch(error => console.error(error))
  }

  useEffect(() => {
    if (!isCitiesOptionsFetched) {
      fetchCities() // Fetch vehicle options
    }

    // Fetch cities when needed
    fetchCities()
  }, [isCitiesOptionsFetched])

  const fetchAgent = () => {
    const apiToken = localStorage.getItem("apiToken") // Get the API token from local storage
    const value = JSON.parse(apiToken)
    const api = value.API
    // Fetch options from API
    axios
      .get("https://flymiles.com.au/adminapi/public/api/agents", {
        headers: {
          Authorization: `Bearer ${api}`,
        },
      })
      .then(response => {
        const apiOptions = response.data["agents"]
        // .map(item => item.type_name)
        setAgentOptions(apiOptions)
        setIsAgentOptionsFetched(true)
      })
      .catch(error => console.error(error))
  }
  // console.log(agentOptions)
  useEffect(() => {
    if (!isAgentOptionsFetched) {
      fetchAgent()
    }
  }, [isAgentOptionsFetched])

  // form
  // console.log(data_attr_luggage)
  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2)
  }
  function textareachange(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }
  function textareachange2(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge2(true)
    } else {
      settextareabadge2(false)
    }
    settextcount2(event.target.value.length)
  }
  function textareachange3(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge3(true)
    } else {
      settextareabadge3(false)
    }
    settextcount3(event.target.value.length)
  }
  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setRows1(modifiedRows)
  }
  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setRows1(modifiedRows)
    }
  }

  const updateRowValues = updatedRows => {
    if (updatedRows[0]) {
      setRow1(updatedRows[0].value || "")
    }
    if (updatedRows[1]) {
      setRow2(updatedRows[1].value || "")
    }
    if (updatedRows[2]) {
      setRow3(updatedRows[2].value || "")
    }
  }

  const handlePlace3Change = (selectedPlace, rowIndex) => {
    const updatedRows = [...rows1]
    updatedRows[rowIndex].value = selectedPlace.formatted_address // Update with the appropriate property
    setRows1(updatedRows)
    updateRowValues(updatedRows) // Update row values
    console.log("complete row: ", updatedRows)
  }

  const handleInputChange = (e, id) => {
    const updatedRows = [...rows1]
    const selectedRowIndex = updatedRows.findIndex(row => row.id === id)

    if (selectedRowIndex !== -1) {
      updatedRows[selectedRowIndex].value = e.target.value
      setRows1(updatedRows)
      updateRowValues(updatedRows) // Update row values
    }

    console.log("row:", row1)
  }
  // function handleInputChange(e, id) {
  //   const { value } = e.target
  //   const updatedRows = rows1.map(row => {
  //     if (row.id === id) {
  //       return { ...row, value }
  //     }
  //     return row
  //   })
  //   setRows1(updatedRows)
  //   setRow1(rows1[0]?.value)
  //   setRow2(rows1[1]?.value)
  //   setRow3(rows1[2]?.value)
  // }

  useEffect(() => {
    calculateSubTotal()
  }, [fare, item1, item2, item3, item4, item5, miscCharges, discount])

  const calculateSubTotal = () => {
    const newSubTotal =
      parseInt(fare) +
      parseInt(item1) +
      parseInt(item2) +
      parseInt(item3) +
      parseInt(item4) +
      parseInt(item5) +
      parseInt(miscCharges) -
      parseInt(discount)
    setSubTotal(newSubTotal)
  }

  // form end

  //meta title
  document.title = "Add New Job | Flymiles"

  const handleIncrement = () => {
    setCountpax(countpax + 1)
  }

  const handleDecrement = () => {
    setCountpax(countpax - 1)
  }

  const handleIncrementlug = () => {
    setCountluggage(countluggage + 1)
  }

  const handleDecrementlug = () => {
    setCountluggage(countluggage - 1)
  }

  const [modal, setModal] = useState(false)
  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 8) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }
  useEffect(() => {
    if (successNoti) {
      // Navigate to the 8th page (or any specific page) here
      toggleTabVertical(8)
    }
  }, [successNoti])
  const radioButtons = [
    { label: "Credit Card", value: "stripe" },
    { label: "On Account", value: "On Account" },
    { label: "Cash Collect", value: "Cash Collect" },
  ]

  const paxOptions = []

  for (let i = 1; i <= 57; i++) {
    paxOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    )
  }
  const luggageOptions = []

  for (let i = 1; i <= 57; i++) {
    luggageOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    )
  }
  const handleCountryCodeChange = e => {
    setCountryCode(e.target?.value)
  }
  console.log(`Country${countryCode}`)
  // const handleCountryCodeChange = selectedOption => {
  //   validation.setFieldValue("countryCode", selectedOption.value)
  // }
  const handleCountryChange = selectedOption => {
    validation.setFieldValue("country", selectedOption.label)
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Add New Jobs" />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <div className="flex-shrink-0">
              <Link to="#!" className="btn btn-light me-1">
                <i className="mdi mdi-refresh"></i>
              </Link>
              <UncontrolledDropdown className="dropdown d-inline-block me-1">
                <DropdownToggle
                  type="menu"
                  className="btn btn-success"
                  id="dropdownMenuButton1"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <li>
                    <DropdownItem href="#">Print</DropdownItem>
                  </li>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          {/* <Table columns={columns} data={data} /> */}

          <Card>
            <CardBody>
              <h4 className="card-title mb-4">ADD NEW JOBS</h4>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="vertical-wizard wizard clearfix vertical">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 1,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTabVartical === 1,
                          })}
                          onClick={() => {
                            toggleTabVertical(1)
                          }}
                          disabled={!(passedStepsVertical || []).includes(1)}
                        >
                          <span className="number">1.</span> PICKUP & DROPOFF
                          INFORMATION
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 2,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTabVartical === 2,
                          })}
                          onClick={() => {
                            toggleTabVertical(2)
                          }}
                          disabled={!(passedStepsVertical || []).includes(2)}
                        >
                          <span className="number">2.</span> PICKUP INFORMATION
                          <span></span>
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 3,
                        })}
                      >
                        <NavLink
                          className={
                            (classnames({
                              active: activeTabVartical === 3,
                            }),
                            "done")
                          }
                          onClick={() => {
                            toggleTabVertical(3)
                          }}
                          disabled={!(passedStepsVertical || []).includes(3)}
                        >
                          <span className="number">3.</span> CUSTOMER
                          INFORMATION
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 4,
                        })}
                      >
                        <NavLink
                          className={
                            (classnames({
                              active: activeTabVartical === 4,
                            }),
                            "done")
                          }
                          onClick={() => {
                            toggleTabVertical(4)
                          }}
                          disabled={!(passedStepsVertical || []).includes(4)}
                        >
                          <span className="number">4.</span> DISPATCH & NOTES
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 5,
                        })}
                      >
                        <NavLink
                          className={
                            (classnames({
                              active: activeTabVartical === 5,
                            }),
                            "done")
                          }
                          onClick={() => {
                            toggleTabVertical(5)
                          }}
                          disabled={!(passedStepsVertical || []).includes(5)}
                        >
                          <span className="number">5.</span> FARE CALCULATION
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 6,
                        })}
                      >
                        <NavLink
                          className={
                            (classnames({
                              active: activeTabVartical === 6,
                            }),
                            "done")
                          }
                          onClick={() => {
                            toggleTabVertical(6)
                          }}
                          disabled={!(passedStepsVertical || []).includes(6)}
                        >
                          <span className="number">6.</span>BOOKING ID
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 7,
                        })}
                      >
                        <NavLink
                          className={
                            (classnames({
                              active: activeTabVartical === 7,
                            }),
                            "done")
                          }
                          onClick={() => {
                            toggleTabVertical(7)
                          }}
                          disabled={!(passedStepsVertical || []).includes(7)}
                        >
                          <span className="number">7.</span> PAYMENT METHOD
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 8,
                        })}
                      >
                        <NavLink
                          className={
                            (classnames({
                              active: activeTabVartical === 8,
                            }),
                            "done")
                          }
                          // onClick={() => {
                          //   toggleTabVertical(8)
                          // }}
                          disabled={!(passedStepsVertical || []).includes(8)}
                        >
                          <span className="number">8.</span> Confirm
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <TabContent activeTab={activeTabVartical} className="body">
                      <TabPane tabId={1}>
                        <div>
                          <h3>
                            <strong> PICKUP & DROPOFF INFORMATION </strong>
                          </h3>
                          <div className="bg-primary mb-3 px-3 p-2 fw-semibold  text-white text-right d-flex justify-content-end ">
                            Distance: {distance} km
                          </div>
                          <LoadScript
                            googleMapsApiKey="AIzaSyDSS70hvcoWwGoJWnYfsNM8eOTzdybk-rg"
                            libraries={["places"]}
                          >
                            <div className="mb-3">
                              <Label className="form-label">
                                Pickup Location
                              </Label>

                              <Autocomplete
                                onLoad={autocomplete => {
                                  autocomplete.addListener(
                                    "place_changed",
                                    () => {
                                      handlePlace1Change(
                                        autocomplete.getPlace()
                                      )
                                      // autocomplete.preventDefault()
                                    }
                                  )
                                }}
                                onSubmit={e => {
                                  e.preventDefault()
                                }}
                                onBlur={autocomplete => {
                                  autocomplete.preventDefault()
                                }}
                              >
                                <Input
                                  name="pickupLocation"
                                  type="text"
                                  placeholder="Insert Pickup Location"
                                  onChange={e => {
                                    e.preventDefault()
                                    setPlace1(e.target.value)
                                    validation.handleChange(e)
                                    e.preventDefault()
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    (place1?.name &&
                                      place1?.formatted_address) ||
                                    validation.values.pickupLocation ||
                                    ""
                                  }
                                  // invalid={
                                  //   validation.touched.pickupLocation &&
                                  //   validation.errors.pickupLocation
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </Autocomplete>

                              {validation.touched.pickupLocation &&
                              validation.errors.pickupLocation ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pickupLocation}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">
                                Dropoff Location
                              </Label>
                              <Autocomplete
                                onLoad={autocomplete => {
                                  autocomplete.addListener(
                                    "place_changed",
                                    () => {
                                      handlePlace2Change(
                                        autocomplete.getPlace()
                                      )
                                    }
                                  )
                                }}
                              >
                                <Input
                                  name="dropoffLocation"
                                  type="text"
                                  placeholder="Insert Dropoff Location"
                                  onChange={e => {
                                    setPlace2(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    (place2?.name &&
                                      place2?.formatted_address) ||
                                    validation.values.dropoffLocation ||
                                    ""
                                  }
                               
                                />
                              </Autocomplete>
                              {validation.touched.dropoffLocation &&
                              validation.errors.dropoffLocation ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.dropoffLocation}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="inner-repeater mb-3">
                              <Label>Stop</Label>
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  {rows1.map((formRow, key) => (
                                    <tr key={key}>
                                      <td>
                                        <Row className="mb-2">
                                          <Col md="9">
                                            <Autocomplete
                                              onLoad={autocomplete => {
                                                autocomplete.addListener(
                                                  "place_changed",
                                                  () => {
                                                    handlePlace3Change(
                                                      autocomplete.getPlace(),
                                                      key
                                                    )
                                                  }
                                                )
                                              }}
                                            >
                                              <Input
                                                type="text"
                                                className="inner form-control"
                                                placeholder="Insert Stop"
                                                value={formRow.value}
                                                onChange={e =>
                                                  handleInputChange(
                                                    e,
                                                    formRow.id
                                                  )
                                                }
                                              />
                                            </Autocomplete>
                                          </Col>
                                          <Col md="3">
                                            <Button
                                              color="primary"
                                              className="btn-block inner "
                                              id="unknown-btn"
                                              style={{ width: "100%" }}
                                              onClick={() =>
                                                handleRemoveRow(formRow.id)
                                              }
                                            >
                                              Delete
                                            </Button>
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              {rows1.length < 3 && (
                                <Button
                                  onClick={handleAddRowNested}
                                  color="success"
                                  className="mt-1"
                                >
                                  Add Stop
                                </Button>
                              )}
                            </div>
                          </LoadScript>

                          <div className="mb-3">
                            <Label className="form-label">
                              Flight Number / Cruise Info
                            </Label>
                            <Input
                              name="flight"
                              type="text"
                              placeholder="Insert Flight Number"
                              // validate={{
                              //   required: { value: true },
                              // }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.flight || ""}
                              // invalid={
                              //   validation.touched.flight &&
                              //   validation.errors.flight
                              //     ? true
                              //     : false
                              // }
                            />
                            {/* {validation.touched.flight &&
                            validation.errors.flight ? (
                              <FormFeedback type="invalid">
                                {validation.errors.flight}
                              </FormFeedback>
                            ) : null} */}
                          </div>
                          <div className="mb-3">
                            <Label>Notes</Label>
                            <Input
                              name="clientNotes"
                              type="textarea"
                              id="textarea"
                              onChange={e => {
                                textareachange(e)
                                validation.handleChange(e)
                              }}
                              maxLength="225"
                              rows="3"
                              placeholder="This textarea has a limit of 225 chars."
                              onBlur={validation.handleBlur}
                              value={validation.values.clientNotes || ""}
                              // invalid={
                              //   validation.touched.clientNotes &&
                              //   validation.errors.clientNotes
                              // }
                            />
                            {textareabadge ? (
                              <span className="badgecount badge bg-success">
                                {textcount} / 225
                              </span>
                            ) : null}
                            {validation.touched.clientNotes &&
                            validation.errors.clientNotes ? (
                              <FormFeedback type="invalid">
                                {validation.errors.clientNotes}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {/* <div className="mb-3">
                            <Label>
                              Pickup Instruction
                               
                            </Label>
                            <Input
                              name="pickupInstruction"
                              type="textarea"
                              id="textarea"
                              onChange={e => {
                                textareachange2(e)
                                validation.handleChange(e)
                              }}
                              maxLength="225"
                              rows="3"
                              placeholder="This textarea has a limit of 225 chars."
                              onBlur={validation.handleBlur}
                              value={validation.values.pickupInstruction || ""}
                              invalid={
                                validation.touched.pickupInstruction &&
                                validation.errors.pickupInstruction
                              }
                            />
                            {textareabadge2 ? (
                              <span className="badgecount badge bg-success">
                                {textcount2} / 225
                              </span>
                            ) : null}
                            {validation.touched.pickupInstruction &&
                            validation.errors.pickupInstruction ? (
                              <FormFeedback type="invalid">
                                {validation.errors.pickupInstruction}
                              </FormFeedback>
                            ) : null}
                          </div> */}
                        </div>
                      </TabPane>{" "}
                      <TabPane tabId={2}>
                        <div>
                          <h3>
                            <strong> PICKUP INFORMATION </strong>
                          </h3>
                          <div className="mb-3">
                            <Label className="form-label">
                              Pickup Date<span className="text-danger">*</span>
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className={`form-control`}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                }}
                                placeholder="mm/dd/yyyy"
                                value={validation.values.pickupDate || ""}
                                onBlur={validation.handleBlur}
                                onChange={date =>
                                  validation.setFieldValue(
                                    "pickupDate",
                                    date[0]
                                  )
                                }
                                invalid={
                                  validation.touched.pickupDate &&
                                  validation.errors.pickupDate
                                    ? true
                                    : false
                                }
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </InputGroup>
                            {validation.touched.pickupDate &&
                            validation.errors.pickupDate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.pickupDate}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Pickup Time<span className="text-danger">*</span>
                            </Label>
                            <InputGroup>
                              <Flatpickr
                                className={`form-control`}
                                placeholder="hh:mm"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                }}
                                value={validation.values.pickupTime || ""}
                                onChange={time =>
                                  validation.setFieldValue(
                                    "pickupTime",
                                    time[0].toISOString().substr(11, 5)
                                  )
                                }
                                invalid={
                                  validation.touched.pickupTime &&
                                  validation.errors.pickupTime
                                    ? true
                                    : false
                                }
                                validate={{
                                  required: { value: true },
                                }}
                              />
                              <InputGroupText>
                                <i className="mdi mdi-clock-outline" />
                              </InputGroupText>
                            </InputGroup>
                            {validation.touched.pickupTime &&
                            validation.errors.pickupTime ? (
                              <FormFeedback type="invalid">
                                {validation.errors.pickupTime}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">City</Label>
                            <Input
                              name="bookingCity"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.bookingCity || ""}
                            >
                              <option value="">Select City</option>

                              {isCitiesOptionsFetched ? (
                                citiesOptions.length > 0 ? (
                                  citiesOptions?.map(city => (
                                    <option
                                      key={city.id}
                                      value={city.city_name}
                                    >
                                      {city.city_name}
                                    </option>
                                  ))
                                ) : (
                                  <option disabled>No Cities found</option>
                                )
                              ) : (
                                <option disabled>Loading Cities...</option>
                              )}
                            </Input>
                            {/* {validation.values.bookingCity} */}
                            {validation.touched.bookingCity &&
                            validation.errors.bookingCity ? (
                              <FormFeedback type="invalid">
                                {validation.errors.bookingCity}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Vehicle Type<span className="text-danger">*</span>
                            </Label>
                            {/* <Input
                              name="vehicleTypeApi"
                              type="select"
                              onChange={e => {
                                setSelectedVehicleType(e.target.value)
                                setIsDriversFetched(false)
                                validation.handleChange(e)
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.vehicleTypeApi || ""}
                              invalid={
                                validation.touched.vehicleTypeApi &&
                                validation.errors.vehicleTypeApi
                                  ? true
                                  : false
                              }
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value="">Select Vehicle Type</option>

                              {vehicleOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={{
                                    id: option.id,
                                    value: option.type_name,
                                  }}
                                >
                                  {option.type_name}
                                </option>
                              ))}
                            </Input> */}

                            <Input
                              name="vehicleTypeApi"
                              type="select"
                              onChange={e => {
                                const selectedOption = vehicleOptions.find(
                                  option => option.type_name === e.target.value
                                )

                                if (selectedOption) {
                                  setSelectedVehicleType({
                                    id: selectedOption.id,
                                    value: e.target.value,
                                  })
                                } else {
                                  setSelectedVehicleType({ id: "", value: "" })
                                }

                                setIsDriversFetched(false)
                                validation.handleChange(e)
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.vehicleTypeApi || ""}
                              invalid={
                                validation.touched.vehicleTypeApi &&
                                validation.errors.vehicleTypeApi
                                  ? true
                                  : false
                              }
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value="">Select Vehicle Type</option>

                              {vehicleOptions.map((option, index) => (
                                <option key={index} value={option.type_name}>
                                  {option.type_name}
                                </option>
                              ))}
                            </Input>
                            {/* {validation.values.vehicleTypeApi} */}
                            {validation.touched.vehicleTypeApi &&
                            validation.errors.vehicleTypeApi ? (
                              <FormFeedback type="invalid">
                                {validation.errors.vehicleTypeApi}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Agent ID</Label>
                            <Input
                              name="agentId"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.agentId || ""}
                              // invalid={
                              //   validation.touched.agentId &&
                              //   validation.errors.agentId
                              //     ? true
                              //     : false
                              // }
                              // validate={{
                              //   required: { value: true },
                              // }}
                            >
                              <option value="">Select Agent</option>
                              {agentOptions?.map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </Input>
                            {/* {validation.values.agentId} */}

                            {validation.touched.agentId &&
                            validation.errors.agentId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.agentId}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label>No. of Pax: (1 to 57)</Label>
                                <Input
                                  name="pax"
                                  type="select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pax || ""}
                                >
                                  <option>Select No. of Passengers</option>
                                  {paxOptions}
                                </Input>
                              </div>
                              {/* {validation.values.pax} */}
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label>No. of luggage: (1 to 57)</Label>

                                <Input
                                  name="luggage"
                                  type="select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.luggage || ""}
                                >
                                  <option>Select No. of Luggage</option>
                                  {luggageOptions}
                                </Input>
                                {/* {validation.values.luggage} */}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      <TabPane tabId={3}>
                        <div>
                          <h3>
                            <strong> CUSTOMER INFORMATION </strong>
                          </h3>
                          <div className="mb-3">
                            <Label className="form-label">
                              Name<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Insert Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Phone</Label>
                            <Row>
                              <Col sm={4}>
                                <Input
                                  className="w-2/12"
                                  name="countryCode"
                                  type="select"
                                  placeholder="Select Country Code"
                                  onChange={e => {
                                    handleCountryCodeChange(e)
                                  }}
                                  value={
                                    (validation.values.countryCode =
                                      countryCode)
                                  }
                                >
                                  {countryCodes.map(code => (
                                    <option key={code.value} value={code.value}>
                                      {code.value}
                                    </option>
                                  ))}
                                </Input>
                              </Col>
                              <Col sm={8}>
                                <Input
                                  name="phone"
                                  type="number"
                                  placeholder="Insert Phone"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.phone || ""}
                                  // invalid={
                                  //   validation.touched.phone &&
                                  //   validation.errors.phone
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </Col>
                            </Row>
                            {/* {countryCode}
                            {validation.values.phone} */}

                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              placeholder="Insert Email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              // invalid={
                              //   validation.touched.email &&
                              //   validation.errors.email
                              //     ? true
                              //     : false
                              // }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Country</Label>
                            <Select
                              name="country"
                              options={countryCodes.map(code => ({
                                value: code.value,
                                label: code.label,
                              }))}
                              placeholder="Insert Country"
                              onChange={handleCountryChange}
                              value={
                                countryCodes.find(
                                  code =>
                                    code.label === validation.values.country
                                ) || ""
                              }
                            />
                            {/* {validation.values.country} */}
                            {validation.touched.country &&
                            validation.errors.country ? (
                              <FormFeedback type="invalid">
                                {validation.errors.country}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={4}>
                        <div>
                          {" "}
                          <h3>
                            <strong> DISPATCH & NOTES</strong>
                          </h3>
                          <div className="mb-3">
                            <Label className="form-label">Driver</Label>
                            <Input
                              name="driver"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.driver || ""}
                              // invalid={
                              //   validation.touched.driver &&
                              //   validation.errors.driver
                              //     ? true
                              //     : false
                              // }
                              // validate={{
                              //   required: { value: true },
                              // }}
                              disabled={!isDriversFetched}
                            >
                              <option value="">Select Driver</option>

                              {isDriversFetched ? (
                                driverOptions.length > 0 ? (
                                  driverOptions.map(driver => (
                                    <option key={driver.id} value={driver.id}>
                                      {driver.name}
                                    </option>
                                  ))
                                ) : (
                                  <option disabled>No drivers found</option>
                                )
                              ) : (
                                <option disabled>Loading drivers...</option>
                              )}
                            </Input>
                            {validation.touched.driver &&
                            validation.errors.driver ? (
                              <FormFeedback type="invalid">
                                {validation.errors.driver}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Wait Time</Label>
                            <Input
                              name="waitTime"
                              type="Number"
                              placeholder="Insert Wait Time"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.waitTime || ""}
                              // invalid={
                              //   validation.touched.waitTime &&
                              //   validation.errors.waitTime
                              //     ? true
                              //     : false
                              // }
                            />
                            {validation.touched.waitTime &&
                            validation.errors.waitTime ? (
                              <FormFeedback type="invalid">
                                {validation.errors.waitTime}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Payment Type</Label>
                            <Input
                              name="paymentTypeDriver"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.paymentTypeDriver || ""}
                            >
                              <option>Select Payment Type</option>
                              <option value="On Account"> On Account</option>
                              <option value="Cash Collect">Cash Collect</option>
                              <option value="Cab Charges">Cab Charges</option>
                              <option value="Others">Others</option>
                            </Input>
                            {/* {validation.values.paymentTypeDriver} */}
                            {validation.touched.status &&
                            validation.errors.status ? (
                              <FormFeedback status="invalid">
                                {validation.errors.status}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label>Notes</Label>
                            <Input
                              name="notes"
                              type="textarea"
                              id="textarea"
                              onChange={e => {
                                textareachange3(e)
                                validation.handleChange(e)
                              }}
                              maxLength="225"
                              rows="3"
                              placeholder="This textarea has a limit of 225 chars."
                              onBlur={validation.handleBlur}
                              value={validation.values.notes || ""}
                              // invalid={
                              //   validation.touched.notes &&
                              //   validation.errors.notes
                              // }
                            />

                            {textareabadge3 ? (
                              <span className="badgecount badge bg-success">
                                {textcount3} / 225
                              </span>
                            ) : null}
                            {validation.touched.notes &&
                            validation.errors.notes ? (
                              <FormFeedback type="invalid">
                                {validation.errors.notes}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={5}>
                        <div>
                          <h3>
                            <strong> FARE CALCULATION </strong>
                          </h3>
                          <div className="mb-3">
                            <Label className="form-label">Base Fare</Label>
                            <Row>
                              <Col md={4}>
                                <Input
                                  name="currency"
                                  type="select"
                                  onChange={e => {
                                    setCurrency(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.currency || ""}
                                  // invalid={
                                  //   validation.touched.currency &&
                                  //   validation.errors.currency
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  <option value="">Select Currency</option>

                                  <option>USD</option>
                                  <option>AUD</option>
                                  <option>EURO</option>
                                </Input>
                                {validation.touched.currency &&
                                validation.errors.currency ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.currency}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col md={8}>
                                <Input
                                  name="fare"
                                  type="Number"
                                  placeholder="Insert Fare"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  onChange={e => {
                                    setFare(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.fare || ""}
                                  // invalid={
                                  //   validation.touched.fare &&
                                  //   validation.errors.fare
                                  //     ? true
                                  //     : false
                                  // }
                                />{" "}
                                {validation.touched.fare &&
                                validation.errors.fare ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.fare}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Child Seat (Age 0 – 2 Years)
                            </Label>
                            <Row>
                              <Col md={6}>
                                <Input
                                  name="extraItem1Price"
                                  type="Number"
                                  placeholder="Insert Child Seat "
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  onChange={e => {
                                    setItem1(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.extraItem1Price || ""
                                  }
                                  // invalid={
                                  //   validation.touched.extraItem1Price &&
                                  //   validation.errors.extraItem1Price
                                  //     ? true
                                  //     : false
                                  // }
                                />
                                {validation.touched.extraItem1Price &&
                                validation.errors.extraItem1Price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem1Price}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col md={6}>
                                <Input
                                  name="extraItem1"
                                  type="select"
                                  onChange={e => {
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.extraItem1 || ""}
                                  // invalid={
                                  //   validation.touched.extraItem1 &&
                                  //   validation.errors.extraItem1
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  <option value="">Select Child Seat</option>

                                  <option>1</option>
                                  <option>2</option>
                                </Input>
                                {validation.touched.extraItem1 &&
                                validation.errors.extraItem1 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem1}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Child Seat (Age 2.5 - 5 Years)
                            </Label>
                            <Row>
                              <Col md={6}>
                                <Input
                                  name="extraItem2Price"
                                  type="Number"
                                  placeholder="Insert Child Seat "
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  onChange={e => {
                                    setItem2(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.extraItem2Price || ""
                                  }
                                  // invalid={
                                  //   validation.touched.extraItem2Price &&
                                  //   validation.errors.extraItem2Price
                                  //     ? true
                                  //     : false
                                  // }
                                />
                                {validation.touched.extraItem2Price &&
                                validation.errors.extraItem2Price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem2Price}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col md={6}>
                                <Input
                                  name="extraItem2"
                                  type="select"
                                  onChange={e => {
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.extraItem2 || ""}
                                  // invalid={
                                  //   validation.touched.extraItem2 &&
                                  //   validation.errors.extraItem2
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  <option value="">Select Child Seat</option>

                                  <option>1</option>
                                  <option>2</option>
                                </Input>
                                {validation.touched.extraItem2 &&
                                validation.errors.extraItem2 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem2}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Booster Seat (Age 5 - 7 Years)
                            </Label>
                            <Row>
                              <Col md={6}>
                                <Input
                                  name="extraItem3Price"
                                  type="Number"
                                  placeholder="Insert Booster Seat "
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  onChange={e => {
                                    setItem3(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.extraItem3Price || ""
                                  }
                                  // invalid={
                                  //   validation.touched.extraItem3Price &&
                                  //   validation.errors.extraItem3Price
                                  //     ? true
                                  //     : false
                                  // }
                                />
                                {validation.touched.extraItem3Price &&
                                validation.errors.extraItem3Price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem3Price}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col md={6}>
                                <Input
                                  name="extraItem3"
                                  type="select"
                                  onChange={e => {
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.extraItem3 || ""}
                                  // invalid={
                                  //   validation.touched.extraItem3 &&
                                  //   validation.errors.extraItem3
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  <option value="">Select Booster Seat</option>

                                  <option>1</option>
                                  <option>2</option>
                                </Input>
                                {validation.touched.extraItem3 &&
                                validation.errors.extraItem3 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem3}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Extra Luggage</Label>
                            <Row>
                              <Col md={6}>
                                <Input
                                  name="extraItem4Price"
                                  type="Number"
                                  placeholder="Insert Extra Luggage"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  onChange={e => {
                                    setItem4(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.extraItem4Price || ""
                                  }
                                  // invalid={
                                  //   validation.touched.extraItem4Price &&
                                  //   validation.errors.extraItem4Price
                                  //     ? true
                                  //     : false
                                  // }
                                />
                                {validation.touched.extraItem4Price &&
                                validation.errors.extraItem4Price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem4Price}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col md={6}>
                                <Input
                                  name="extraItem4"
                                  type="select"
                                  onChange={e => {
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.extraItem4 || ""}
                                  // invalid={
                                  //   validation.touched.extraItem4 &&
                                  //   validation.errors.extraItem4
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  <option value="">Select Extra Luggage</option>

                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                </Input>
                                {validation.touched.extraItem4 &&
                                validation.errors.extraItem4 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem4}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Surfboard & Bike
                            </Label>
                            <Row>
                              <Col md={6}>
                                <Input
                                  name="extraItem5Price"
                                  type="Number"
                                  placeholder="Insert Surfboard & Bike"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  onChange={e => {
                                    setItem5(e.target.value)
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.extraItem5Price || ""
                                  }
                                  // invalid={
                                  //   validation.touched.extraItem5Price &&
                                  //   validation.errors.extraItem5Price
                                  //     ? true
                                  //     : false
                                  // }
                                />
                                {validation.touched.extraItem5Price &&
                                validation.errors.extraItem5Price ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem5Price}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col md={6}>
                                <Input
                                  name="extraItem5"
                                  type="select"
                                  onChange={e => {
                                    validation.handleChange(e)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.extraItem5 || ""}
                                  // invalid={
                                  //   validation.touched.extraItem5 &&
                                  //   validation.errors.extraItem5
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  <option value="">
                                    Select Surfboard & Bike
                                  </option>
                                  <option>1</option>
                                  <option>2</option>
                                </Input>
                                {validation.touched.extraItem5 &&
                                validation.errors.extraItem5 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.extraItem5}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Mics Charges</Label>

                            <Input
                              name="miscCharges"
                              type="Number"
                              placeholder="Insert Mics Charges"
                              // validate={{
                              //   required: { value: true },
                              // }}
                              onChange={e => {
                                setMiscCharges(e.target.value)
                                validation.handleChange(e)
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.miscCharges || ""}
                              // invalid={
                              //   validation.touched.miscCharges &&
                              //   validation.errors.miscCharges
                              //     ? true
                              //     : false
                              // }
                            />
                            {validation.touched.miscCharges &&
                            validation.errors.miscCharges ? (
                              <FormFeedback type="invalid">
                                {validation.errors.miscCharges}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Discount</Label>

                            <Input
                              name="discount"
                              type="Number"
                              placeholder="Insert Discount"
                              // validate={{
                              //   required: { value: true },
                              // }}
                              onChange={e => {
                                setDiscount(e.target.value)
                                validation.handleChange(e)
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.discount || ""}
                              // invalid={
                              //   validation.touched.discount &&
                              //   validation.errors.discount
                              //     ? true
                              //     : false
                              // }
                            />
                            {validation.touched.discount &&
                            validation.errors.discount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.discount}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="bg-primary mb-3 px-3 p-2 fw-semibold  text-white text-right d-flex justify-content-end ">
                            SubTotal: ${subTotal} {currency}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={6}>
                        <div>
                          <h3>
                            <strong> BOOKING ID </strong>
                          </h3>
                          <div className="mb-3">
                            <Label className="form-label">
                              Booking / Reservation ID
                            </Label>
                            <Input
                              name="bookingId"
                              type="text"
                              placeholder="Insert Booking ID"
                              // validate={{
                              //   required: { value: true },
                              // }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.bookingId || ""}
                              // invalid={
                              //   validation.touched.bookingId &&
                              //   validation.errors.bookingId
                              //     ? true
                              //     : false
                              // }
                            />
                            {validation.touched.bookingId &&
                            validation.errors.bookingId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.bookingId}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={7}>
                        <div>
                          <h3>
                            <strong> PAYMENT METHOD </strong>
                          </h3>
                          <div className="mb-3">
                            <label className="form-label">
                              Select Payment Method
                            </label>
                            <div className="">
                              <Row>
                                {[
                                  { label: "Credit Card", value: "stripe" },
                                  { label: "On Account", value: "On Account" },
                                  {
                                    label: "Cash Collect",
                                    value: "Cash Collect",
                                  },
                                ].map(method => (
                                  <Col sm={4}>
                                    <div
                                      className="col-span-1"
                                      key={method.value}
                                    >
                                      <div
                                        className={`px-4 py-4 border rounded-4 border-4  ${
                                          method.value ===
                                          validation.values.type
                                            ? "border-primary"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          type="radio"
                                          id={method.value}
                                          name="type"
                                          value={
                                            validation.values.type ||
                                            method?.value
                                          }
                                          onChange={e => {
                                            // Set the checked prop of the radio button that is clicked
                                            if (method) {
                                              const radioButton =
                                                radioButtons.find(
                                                  button =>
                                                    button.value ===
                                                    e.target.value
                                                )
                                              radioButton.checked = true
                                              validation.values.type =
                                                e.target.value
                                              // method.value = e.target.value
                                              validation.setFieldValue(
                                                "type",
                                                method?.value
                                              )
                                            }
                                          }}
                                          onBlur={validation.handleBlur}
                                          className="form-check-input ms-1 me-2"
                                        />
                                        <label
                                          htmlFor={method.value}
                                          className="form-check-label"
                                        >
                                          {method.label}
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                            {/* {validation.values.type} */}
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}

                            {/* <Input type="radio" value={"validation"} /> */}

                            {validation.values.type === "other" && <div></div>}

                            {validation.values.type === "stripe" && (
                              <div>
                                <div className="my-3">
                                  <Label className="form-label">
                                    Card Holder Name
                                  </Label>
                                  <Input
                                    name="cardName"
                                    type="Text"
                                    placeholder="Insert Card Holder Name"
                                    // validate={{
                                    //   required: {
                                    //     value:
                                    //       validation.values.type === "stripe", // Validate if type is "stripe"
                                    //     message:
                                    //       "Card Holder Name is required.",
                                    //   },
                                    // }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.cardName || ""}
                                    // invalid={
                                    //   validation.touched.cardName &&
                                    //   validation.errors.cardName
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                  {validation.touched.cardName &&
                                  validation.errors.cardName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.cardName}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Card Number
                                  </Label>
                                  <Input
                                    name="cardNumber"
                                    type="Number"
                                    placeholder="Insert Card Number"
                                    // validate={{
                                    //   required: {
                                    //     value:
                                    //       validation.values.type === "stripe", // Validate if type is "stripe"
                                    //     message:
                                    //       "Card Holder Number is required.",
                                    //   },
                                    // }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.cardNumber || ""}
                                    disabled={
                                      validation.values.type !== "stripe"
                                    }
                                    // invalid={
                                    //   validation.touched.cardNumber &&
                                    //   validation.errors.cardNumber
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                  {validation.touched.cardNumber &&
                                  validation.errors.cardNumber ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.cardNumber}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Card Expiry Month
                                  </Label>
                                  <Input
                                    name="cardExpiryMonth"
                                    type="Number"
                                    placeholder="Insert Card Expiry Month"
                                    // validate={{
                                    //   required: {
                                    //     value:
                                    //       validation.values.type === "stripe", // Validate if type is "stripe"
                                    //     message:
                                    //       "Card Expiry Month is required.",
                                    //   },
                                    // }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.cardExpiryMonth || ""
                                    }
                                    disabled={
                                      validation.values.type !== "stripe"
                                    }
                                    // invalid={
                                    //   validation.touched.cardExpiryMonth &&
                                    //   validation.errors.cardExpiryMonth
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                  {validation.touched.cardExpiryMonth &&
                                  validation.errors.cardExpiryMonth ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.cardExpiryMonth}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Card Expiry Year
                                  </Label>
                                  <Input
                                    name="cardExpiryYear"
                                    type="Number"
                                    placeholder="Insert Card Expiry Year"
                                    // validate={{
                                    //   required: {
                                    //     value:
                                    //       validation.values.type === "stripe", // Validate if type is "stripe"
                                    //     message:
                                    //       "Card Expiry Year is required.",
                                    //   },
                                    // }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.cardExpiryYear || ""
                                    }
                                    disabled={
                                      validation.values.type !== "stripe"
                                    }
                                    // invalid={
                                    //   validation.touched.cardExpiryYear &&
                                    //   validation.errors.cardExpiryYear
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                  {validation.touched.cardExpiryYear &&
                                  validation.errors.cardExpiryYear ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.cardExpiryYear}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Card CVV</Label>
                                  <Input
                                    name="cardCvv"
                                    type="Number"
                                    placeholder="Insert Card CVV"
                                    // validate={{
                                    //   required: {
                                    //     value:
                                    //       validation.values.type === "stripe", // Validate if type is "stripe"
                                    //     message: "Card CVV is required.",
                                    //   },
                                    // }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.cardCvv || ""}
                                    disabled={
                                      validation.values.type !== "stripe"
                                    }
                                    // invalid={
                                    //   validation.touched.cardCvv &&
                                    //   validation.errors.cardCvv
                                    //     ? true
                                    //     : false
                                    // }
                                    minLength={3}
                                    maxLength={3}
                                  />
                                  {validation.touched.cardCvv &&
                                  validation.errors.cardCvv ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.cardCvv}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId={8}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Detail</h5>
                                <p className="text-success">{successNoti}</p>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                  <div className="actions clearfix">
                    {successNoti ? (
                      <ul>
                        <Link
                          to="#!"
                          onClick={() => setModal(true)}
                          className="btn btn-primary me-1"
                        >
                          Show Invoice
                        </Link>
                      </ul>
                    ) : (
                      <ul>
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <button
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                            className="btn btn-primary"
                          >
                            Previous
                          </button>
                        </li>
                        {activeTabVartical === 7 ? (
                          <li className="next">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                              disabled={isLoading}
                            >
                              {isLoading ? "Submitting..." : "Submit"}
                            </button>
                          </li>
                        ) : (
                          <li
                            className={
                              activeTabVartical === 7 ? "next disabled" : "next"
                            }
                          >
                            <button
                              onClick={e => {
                                e.preventDefault() // Prevent the default form submission
                                if (activeTabVartical < 7) {
                                  toggleTabVertical(activeTabVartical + 1)
                                } else {
                                  // Handle form submission here
                                  // You can submit the form data or perform any other actions
                                  console.log("Form submitted!")
                                }
                              }}
                              className="btn btn-primary"
                            >
                              Next
                            </button>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Booking Details
            </ModalHeader>
            <ModalBody>
              <div>
                <Row>
                  <Col>
                    <p>
                      <strong>Pickup Date:</strong>{" "}
                      {new Date(formData.pickupDate).toLocaleDateString()}
                    </p>
                    <p>
                      <strong>Pickup Time:</strong> {formData.pickupTime}
                    </p>
                    <p>
                      <strong>Booking City:</strong> {formData.bookingCity}
                    </p>
                    {/* <p><strong>Agent ID:</strong> {formData.agentId}</p> */}
                    <p>
                      <strong>Passengers:</strong> {formData.pax} {"  "}{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>Luggage:</strong> {formData.luggage}
                    </p>
                    <p>
                      <strong>Name:</strong> {formData.name}
                    </p>
                    <p>
                      <strong>Phone:</strong> {formData.phone}
                    </p>
                    <p>
                      <strong>Email:</strong> {formData.email}
                    </p>
                    <p>
                      <strong>Client Notes:</strong> {formData.clientNotes}
                    </p>
                    <p>
                      <strong>Pickup Location:</strong>{" "}
                      {formData.pickupLocation}
                    </p>

                    {row1 && (
                      <p>
                        <strong>pickup_stop1:</strong> {row1}
                      </p>
                    )}
                    {row2 && (
                      <p>
                        <strong>pickup_stop2:</strong> {row2}
                      </p>
                    )}
                    {row3 && (
                      <p>
                        <strong>pickup_stop3:</strong> {row3}
                      </p>
                    )}
                    <p>
                      <strong>Dropoff Location:</strong>{" "}
                      {formData.dropoffLocation}
                    </p>

                    <p>
                      <strong>Pickup Instruction:</strong>{" "}
                      {formData.pickupInstruction}
                    </p>
                    <p>
                      <strong>Flight:</strong> {formData.flight}
                    </p>
                    <p>
                      <strong>Notes:</strong> {formData.notes}
                    </p>
                    {/* <p><strong>Vehicle Type:</strong> {formData.vehicleTypeApi}</p>
  <p><strong>Driver:</strong> {formData.driver}</p> */}
                    <p>
                      <strong>Fare:</strong> {formData.currency} {formData.flat}
                    </p>
                    <p>
                      <strong>Vehicle Type:</strong> {selectedVehicleType.value}
                    </p>
                    <p>
                      <strong>Booking Number:</strong> {bookingNo}
                    </p>
                    {/* <p><strong>Type:</strong> {formData.type}</p> */}
                  </Col>
                  {/* <Col> */}
                  {/* <p>Currency: {formData.currency}</p>
                    <p>Extra Item 1: {formData.extraItem1}</p>
                    <p>Extra Item 1 Price: {formData.extraItem1Price}</p>
                    <p>Extra Item 2: {formData.extraItem2}</p>
                    <p>Extra Item 2 Price: {formData.extraItem2Price}</p>
                    <p>Extra Item 3: {formData.extraItem3}</p>
                    <p>Extra Item 3 Price: {formData.extraItem3Price}</p>
                    <p>Extra Item 4: {formData.extraItem4}</p>
                    <p>Extra Item 4 Price: {formData.extraItem4Price}</p>
                    <p>Extra Item 5: {formData.extraItem5}</p>
                    <p>Extra Item 5 Price: {formData.extraItem5Price}</p>
                    <p>Misc Charges: {formData.miscCharges}</p>
                    <p>Discount: {formData.discount}</p>
                    <p>Booking ID: {formData.bookingId}</p>
                    <p>Card Name: ********</p>
                    <p>Card Number: ****************</p>
                    <p>Card Expiry Month: **</p>
                    <p>Card Expiry Year: ****</p>
                    <p>Card CVV: ***</p> */}
                  {/* </Col> */}
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col xl={12}>
                  {invoiceSuccessNoti ? (
                    <Alert color="success">{invoiceSuccessNoti} </Alert>
                  ) : null}
                </Col>
                <Col>
                  <div className="text-end">
                    <button
                      onClick={() => {
                        sendBookingEmail()
                      }}
                      type="submit"
                      className="btn btn-success save-user"
                    >
                      Send Invoice To Email
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </>
  )
}
// DatatableTables.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// }

export default AddNewJob
