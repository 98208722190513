// import React, { useEffect, useState } from "react"
// import Select from "react-select"

// function MyMultiSelectComponent({ onChange, data }) {
//   const [cities, setCities] = useState([])
//   const [selectedOption, setSelectedOption] = useState([])

//   const fetchCity = async () => {
//     const response = await fetch(
//       "https://login.flymiles.com.au/api_sections/packages/get_country_city_list.php",
//       {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ get_city: "Yes" }),
//       }
//     )
//     const data = await response.json()
//     // Assuming 'data' is an array of city objects, convert them for react-select
//     const cityOptions = data.map(city => ({
//       value: city.id, // Adjust these keys based on your actual API response structure
//       label: city.city,
//     }))
//     setCities(cityOptions || [])
//   }

//   useEffect(() => {
//     fetchCity()
//   }, [])

//   const options = [
//     { value: "1", label: "Lahore" },
//     { value: "2", label: "Multan" },
//     { value: "3", label: "Karachi" },
//     { value: "4", label: "Islamabad" },
//     // ... other options
//   ]
//   // console.log("dd", data)

//   useEffect(() => {
//     // Assuming 'data' is an array of cities as mentioned
//     if (data) {
//       const selectedOptions = data.map(city => ({
//         value: city.city_id,
//         label: city.city_name,
//       }))
//       setSelectedOption(selectedOptions)
//     }
//   }, [data]) // React to 'data' changes

//   return (
//     <Select
//       isMulti
//       name="categories"
//       options={cities}
//       className="basic-multi-select"
//       classNamePrefix="select"
//       onChange={onChange}
//       value={selectedOption}
//       placeholder="Select a Cities"
//     />
//   )
// }

// export default MyMultiSelectComponent

// import React, { useEffect, useRef, useState } from "react"
// import Select from "react-select"

// function MyMultiSelectComponent({ onChange, id, data }) {
//   const [cities, setCities] = useState([])
//   const [selectedOption, setSelectedOption] = useState([])
//   const [isComponentMounted, setIsComponentMounted] = useState(false)
//   const initialRender = useRef(true)
//   const [previousId, setPreviousId] = useState(id)

//   // console.log(id, cities)
//   const fetchCity = async () => {
//     const response = await fetch(
//       "https://login.flymiles.com.au/api_sections/packages/get_city_list_specific_country.php",
//       {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ country_id: id ? id : null }),
//       }
//     )
//     const json = await response.json()
//     // if (!data) {
//     //   setSelectedOption([])
//     // }
//     // Convert the API response to match react-select's expected format
//     if (json) {
//       const cityOptions = json?.map(city => ({
//         value: city.id.toString(), // Ensuring value is a string; adjust based on actual API response
//         label: city.city,
//       }))
//       setCities(cityOptions)
//     }
//     // console.log(cityOptions)
//   }
//   useEffect(() => {
//     // Determine if this is the initial load by checking if previousId is the same as the current id.
//     const isInitialLoad = previousId === id
//     const shouldClearSelectedOptions = !isInitialLoad && !data

//     if (shouldClearSelectedOptions) {
//       // Clear the selection only if it's not the initial load and there's no data.
//       setSelectedOption([])
//     }

//     // Always attempt to fetch cities when id changes.
//     fetchCity()

//     // After fetching, update previousId to the current id for future comparisons.
//     setPreviousId(id)
//   }, [id, data]) // Depend

//   // useEffect(() => {
//   //   fetchCity()
//   // }, [id])

//   useEffect(() => {
//     // Adjust this effect based on the structure of the `data` prop
//     if (data) {
//       const selectedOptions = data?.map(city => ({
//         value: city.city_id.toString(), // Make sure 'value' matches the type and format expected by react-select
//         label: city.city_name,
//       }))
//       setSelectedOption(selectedOptions)
//     }
//   }, [data])

//   return (
//     <Select
//       isMulti
//       name="categories"
//       options={cities}
//       className="basic-multi-select"
//       classNamePrefix="select"
//       onChange={selectedOptions => {
//         setSelectedOption(selectedOptions)
//         onChange(selectedOptions) // Ensure this is handling the structure correctly for your use case
//       }}
//       value={selectedOption}
//       placeholder="Select Cities"
//     />
//   )
// }

// export default MyMultiSelectComponent

// import React, { useEffect, useRef, useState } from "react"
// import Select from "react-select"

// function MyMultiSelectComponent({ onChange, id, data }) {
//   const [cities, setCities] = useState([])
//   const [selectedOption, setSelectedOption] = useState([])
//   const initialRender = useRef(true) // Used to track the first render
//   const [previousId, setPreviousId] = useState(id)

//   useEffect(() => {
//     const fetchCity = async () => {
//       const response = await fetch(
//         "https://login.flymiles.com.au/api_sections/packages/get_city_list_specific_country.php",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ country_id: id ? id : null }),
//         }
//       )
//       const json = await response.json()

//       if (previousId == 0) {
//         setSelectedOption([])
//       }
//       // Convert the API response to match react-select's expected format
//       if (json) {
//         const cityOptions = json.map(city => ({
//           value: city.id.toString(), // Ensuring value is a string
//           label: city.city,
//         }))
//         setCities(cityOptions)
//       }
//     }

//     fetchCity()
//   }, [id])

//   // Initialize or update selectedOption from `data`
//   useEffect(() => {
//     if (data) {
//       const selectedOptions = data.map(city => ({
//         value: city.city_id.toString(), // Ensure value matches the expected format for react-select
//         label: city.city_name,
//       }))
//       setSelectedOption(selectedOptions)
//       setPreviousId(0)
//     } else {
//       // If there's no data (and this isn't the first render), clear the selections
//       setSelectedOption([])
//     }
//   }, [data])

//   return (
//     <Select
//       isMulti
//       name="categories"
//       options={cities}
//       className="basic-multi-select"
//       classNamePrefix="select"
//       onChange={selectedOptions => {
//         setSelectedOption(selectedOptions)
//         onChange(
//           selectedOptions.map(option => ({
//             city_id: option.value,
//             city_name: option.label,
//           }))
//         ) // Map back to the expected format if necessary
//       }}
//       value={selectedOption}
//       placeholder="Select Cities"
//     />
//   )
// }

// export default MyMultiSelectComponent

import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"

function MyMultiSelectComponent({ onChange, id, data }) {
  const [cities, setCities] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const prevIdRef = useRef() // Use a ref to store the previous id

  // console.log(cities, selectedOption, data)
  useEffect(() => {
    const fetchCity = async () => {
      const response = await fetch(
        "https://login.flymiles.com.au/api_sections/tours/get_city_list_specific_country.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ country_id: id ? id : null }),
        }
      )
      const json = await response.json()

      // Convert the API response to match react-select's expected format
      if (json) {
        const cityOptions = json.map(city => ({
          value: city.id.toString(), // Ensuring value is a string
          label: city.city,
        }))
        setCities(cityOptions)
      }
    }

    // Clear selectedOption only on id change (not on initial render)
    if (prevIdRef.current !== undefined && prevIdRef.current !== id) {
      setSelectedOption([])
    }

    fetchCity()

    // After everything, set prevIdRef to the current id for the next render
    prevIdRef.current = id
  }, [id])

  useEffect(() => {
    // This is only to set selectedOption based on initial data, not clear it
    if (data) {
      const selectedOptions = data.map(city => ({
        value: city.city_id.toString(), // Ensure value matches the expected format for react-select
        label: city.city_name,
      }))
      setSelectedOption(selectedOptions)
    }
  }, [data])

  return (
    <Select
      isMulti
      name="categories"
      options={cities}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={selectedOptions => {
        setSelectedOption(selectedOptions)
        onChange(selectedOptions) // Ensure this is handling the structure correctly for your use case
      }}
      value={selectedOption}
      placeholder="Select Cities"
    />
  )
}

export default MyMultiSelectComponent
