import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "pages/JobPages/JobList/JobListCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
// import { JobNo } from "pages/JobPages/JobList/JobListCol"

function TourCity() {
  //meta title
  document.title = "City | Flymiles"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [jobsList, setJobsList] = useState([])
  const [job, setJob] = useState(null)
  const [countries, setCountries] = useState([])
  const [city, setCity] = useState([])
  const [dataChanged, setDataChanged] = useState(false)
  const fetchCity = async () => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/tours/get_country_city_list.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ get_city: "Yes" }),
      }
    )
    const data = await response.json()
    setCity(data || [])
  }
  console.log("cc", countries, job)

  useEffect(() => {
    fetchCity()
  }, [dataChanged])

  const fetchCountries = async () => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/tours/get_country_city_list.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ get_country: "Yes", own_page: "yes" }),
      }
    )
    const data = await response.json()
    setCountries(data || [])
  }

  useEffect(() => {
    fetchCountries()
  }, [dataChanged]) // Re-fetch whenever dataChanged state changes
  // console.log("country", countries)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      jobTitle: (job && job.jobTitle) || "",
      companyName: (job && job.companyName) || "",
      location: (job && job.location) || "",
      experience: (job && job.experience) || "",
      position: (job && job.position) || "",
      type: (job && job.type) || "",
      status: (job && job.status) || "",
    },
    validationSchema: Yup.object({
      // jobId: Yup.string()
      //   .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Job Id")
      //   .required("Please Enter Your Job Id"),
      jobTitle: Yup.string().required("Please Enter Your Job Title"),
      // companyName: Yup.string().required("Please Enter Your Company Name"),
      // location: Yup.string().required("Please Enter Your Location"),
      // experience: Yup.string().required("Please Enter Your Experience"),
      // position: Yup.string().required("Please Enter Your Position"),
      type: Yup.string().required("Please Enter Your Type"),
      // status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const payload = {
          country_id: job.type, // The selected country ID from the form
          city: values.jobTitle, // The updated city name from the form
          city_id: job.id, // Assuming this is the ID of the city being edited
        }
        // console.log(
        //   job.id,
        //   values.jobTitle,
        //   job.type,
        //   values["type"],
        //   values.type
        // )

        const updateJobList = {
          id: job ? job.id : 0,
          jobId: values.jobId,
          jobTitle: values.jobTitle,
          // companyName: values.companyName,
          // location: values.location,
          // experience: values.experience,
          // position: values.position,
          type: values.type,
          // postedDate: "02 June 2021",
          // lastDate: "25 June 2021",
          // status: values.status,
        }

        fetch(
          "https://login.flymiles.com.au/api_sections/tours/update_city.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // Here, handle the successful update. For example, you might want to:
            // - Update the local state or Redux store to reflect the updated city info
            // - Notify the user that the update was successful
            // - Reset the form and close the modal
            validation.resetForm()
            toggle() // Close the modal
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle any errors here, such as showing an error message to the user
          })

        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const payload = {
          country_id: values["type"], // countryId is from the form's values
          city: values.jobTitle, // Assuming jobTitle is being repurposed as the city name
        }

        const newJobList = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          jobId: values["jobId"],
          jobTitle: values["jobTitle"],
          // companyName: values["companyName"],
          // location: values["location"],
          // experience: values["experience"],
          // position: values["position"],
          type: values["type"],
          // postedDate: "02 June 2021",
          // lastDate: "25 June 2021",
          // status: values["status"],
        }
        fetch("https://login.flymiles.com.au/api_sections/tours/add_city.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then(response => response.json())
          .then(data => {
            console.log("Success:", data)
            setDataChanged(prev => !prev)

            // You might want to do something upon successfully adding a city, like updating state or notifying the user
            // Reset form and maybe close the modal or clear selected countryId
            validation.resetForm()
            toggle() // Assuming this toggles the visibility of the modal
          })
          .catch(error => {
            console.error("Error:", error)
            // Handle any errors, such as showing a notification to the user
          })

        // save new Job
        dispatch(onAddNewJobList(newJobList))
        validation.resetForm()
      }
      toggle()
    },
  })

  const handleEnableDisableCity = async (cityId, status) => {
    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/tours/disable_enable_city.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ city_id: cityId, disbale_disable: status }),
      }
    )
    const data = await response.json()
    console.log(data) // Handle the response if necessary
    fetchCity() // Refresh the list after the change
  }
  const dispatch = useDispatch()
  const { jobs } = useSelector(state => ({
    jobs: state.JobReducer.jobs,
  }))

  useEffect(() => {
    if (jobs && !jobs.length) {
      dispatch(onGetJobList())
    }
  }, [dispatch, jobs])

  useEffect(() => {
    setJobsList(jobs)
  }, [jobs])

  useEffect(() => {
    if (!isEmpty(jobs) && !!isEdit) {
      setJobsList(jobs)
      setIsEdit(false)
    }
  }, [jobs])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  const handleJobClick = arg => {
    const job = arg
    const selectedCity = city.find(city => city.id == job.id)

    const findCountryName = (selectedCity, countries) => {
      const matchingCountry = countries.find(
        country => country.id === selectedCity?.country_id
      )
      return matchingCountry ? matchingCountry.id : ""
    }

    setJob({
      id: job.id,
      jobId: job.jobId,
      jobTitle: job.city,
      companyName: job.companyName,
      location: job.location,
      experience: job.experience,
      position: job.position,
      type: job.country_id,
      status: job.status,
    })

    setIsEdit(true)

    toggle()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setJob(job)
    setDeleteModal(true)
  }

  const handleDeletejob = () => {
    if (job && job.id) {
      dispatch(onDeleteJobList(job.id))
      setDeleteModal(false)
    }
  }
  const handleJobClicks = () => {
    setJobsList("")
    setIsEdit(false)
    toggle()
  }

  const handleDeleteCity = async () => {
    if (!job.id) return

    const response = await fetch(
      "https://login.flymiles.com.au/api_sections/tours/delete_city.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ city_id: job.id }),
      }
    )
    const data = await response.json()
    console.log(data) // You might want to handle this depending on the response
    fetchCity() // Refresh the list after deletion
    setDeleteModal(false)
  }

  const openDeleteModal = countryId => {
    setSelectedCountryId(countryId)
    setDeleteModal(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Country Name",
        accessor: "country_name",
        filterable: true,
        // Cell: cellProps => {
        //   return <JobNo {...cellProps} />
        // },
        Cell: ({ value }) => <>{value}</>, // Simplified for demonstration
      },
      {
        Header: "City Name",
        accessor: "city",
        filterable: true,
        // Cell: cellProps => {
        //   return <JobTitle {...cellProps} />
        // },
        Cell: ({ value }) => <>{value}</>, // Simplified for demonstration
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          const jobData = cellProps.row.original

          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={e => {
                    e.preventDefault()
                    const jobData = cellProps.row.original
                    handleJobClick(jobData)
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={`btn btn-sm btn-soft-${
                    jobData.disabled === "1" ? "success" : "success"
                  }`}
                  onClick={e => {
                    e.preventDefault()
                    handleEnableDisableCity(
                      jobData.id,
                      jobData.disabled === "0" ? "1" : "0"
                    )
                  }}
                >
                  <i
                    className={`mdi mdi-${
                      jobData.disabled === "1"
                        ? "eye-off-outline"
                        : "eye-outline"
                    }`}
                    id="enableDisableTooltip"
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target="enableDisableTooltip"
                  >
                    Enable/Disable
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip
                    onClick={() => openDeleteModal(cellProps.original.id)}
                    placement="top"
                    target="deletetooltip"
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },

      {
        Header: "No",
        accessor: "id",
        filterable: true,
        // Cell: cellProps => {
        //   return <JobNo {...cellProps} />
        // },
        Cell: ({ value }) => <>{value}</>, // Simplified for demonstration
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCity}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tours" breadcrumbItem="City Lists" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">City Lists</h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="#!"
                        onClick={() => setModal(true)}
                        className="btn btn-primary me-1"
                      >
                        Add New City
                      </Link>
                      {/* <Link to="#!" className="btn btn-light me-1">
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                      <UncontrolledDropdown className="dropdown d-inline-block me-1">
                        <DropdownToggle
                          type="menu"
                          className="btn btn-success"
                          id="dropdownMenuButton1"
                        >
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <li>
                            <DropdownItem href="#">Action</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem href="#">Another action</DropdownItem>
                          </li>
                          <li>
                            <DropdownItem href="#">
                              Something else here
                            </DropdownItem>
                          </li>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={city}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    handleJobClicks={handleJobClicks}
                    isJobListGlobalFilter={true}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Edit City" : "Add City"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Country</Label>
                      <Input
                        name="type"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ""}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      >
                        <option>Select Country</option>
                        {countries?.map(country => (
                          <option key={country.id} value={country.id}>
                            {country.country}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">City</Label>
                      <Input
                        name="jobTitle"
                        type="text"
                        placeholder="Insert City Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.jobTitle || ""}
                        invalid={
                          validation.touched.jobTitle &&
                          validation.errors.jobTitle
                            ? true
                            : false
                        }
                      />
                      {validation.touched.jobTitle &&
                      validation.errors.jobTitle ? (
                        <FormFeedback type="invalid">
                          {validation.errors.jobTitle}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TourCity
