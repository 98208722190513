// import React, { useEffect, useState } from "react"
// import { Col } from "reactstrap"

// const options = [
//   "International Flights",
//   "Domestic Flights",
//   "Private Intercity",
//   "Shared Intercity",
//   "Hotel Stay",
//   "Attractions / Passes",
//   "Day Tours",
//   "Transfers",
//   "Helicopter / Scenic Flight",
//   "Cruises",
//   "Water Sports",
//   "Motor Sports",
//   "Hot Air Balloon",
//   "Winery",
//   "Meal",
//   "Camper Vans",
//   "Car Rental",
// ]

// const getImageValue = inclusionName => {
//   return "text" // Here, you'll return "text" for every inclusion as per the instructions
// }

// // Helper function to generate a random color class
// const getRandomColorClass = () => {
//   const classes = ["primary", "danger", "success", "warning", "info"]
//   return `form-check-${classes[Math.floor(Math.random() * classes.length)]}`
// }

// function Inclusions({ onInclusionsChange, data }) {
//   // Generate state for each checkbox
//   const [checkboxStates, setCheckboxStates] = useState(
//     options.map((option, index) => ({
//       name: option,
//       checked: false,
//       id: `customCheck-outlinecolor${index + 1}`,
//       colorClass: getRandomColorClass(),
//       image: getImageValue(option),
//     }))
//   )

//   useEffect(() => {
//     // Whenever checkboxStates changes, notify the parent component
//     const selectedInclusions = checkboxStates
//       .filter(checkbox => checkbox.checked)
//       .map(({ name, image }) => ({
//         name,
//         image,
//       }))

//     onInclusionsChange(selectedInclusions)
//   }, [checkboxStates, onInclusionsChange])

//   // Function to handle checkbox state change
//   const handleCheckboxChange = index => {
//     const newCheckboxStates = [...checkboxStates]
//     newCheckboxStates[index].checked = !newCheckboxStates[index].checked
//     setCheckboxStates(newCheckboxStates)
//     // console.log("check", newCheckboxStates)
//   }

//   return (
//     <React.Fragment>
//       {options.map((option, index) => (
//         <Col key={option} lg="4">
//           <div
//             className={`form-check form-checkbox-outline ${checkboxStates[index].colorClass} mb-3`}
//           >
//             <input
//               type="checkbox"
//               className="form-check-input"
//               id={checkboxStates[index].id}
//               checked={checkboxStates[index].checked}
//               onChange={() => handleCheckboxChange(index)}
//             />
//             <label
//               className="form-check-label"
//               htmlFor={checkboxStates[index].id}
//             >
//               {option}
//             </label>
//           </div>
//         </Col>
//       ))}
//     </React.Fragment>
//   )
// }

// export default Inclusions

// import React, { useEffect, useState } from "react"
// import { Col } from "reactstrap"

// const options = [
//   "International Flights",
//   "Domestic Flights",
//   "Private Intercity",
//   "Shared Intercity",
//   "Hotel Stay",
//   "Attractions / Passes",
//   "Day Tours",
//   "Transfers",
//   "Helicopter / Scenic Flight",
//   "Cruises",
//   "Water Sports",
//   "Motor Sports",
//   "Hot Air Balloon",
//   "Winery",
//   "Meal",
//   "Camper Vans",
//   "Car Rental",
// ]

// function Inclusions({ onInclusionsChange, data }) {
//   // Initialize state with both the static options and the checked status from data
//   const [checkboxStates, setCheckboxStates] = useState(() =>
//     options.map(option => ({
//       name: option,
//       // Ensure that the checkbox is checked if it's found in the data array
//       checked: !!data?.find(inclusion => inclusion.name === option),
//     }))
//   )

//   // This effect updates the checkbox states based on changes in the data prop,
//   // ensuring the component responds to external updates.
//   useEffect(() => {
//     setCheckboxStates(currentStates =>
//       currentStates.map(state => ({
//         ...state,
//         checked: !!data?.find(inclusion => inclusion.name === state.name),
//       }))
//     )
//     console.log(checkboxStates)
//   }, [data])

//   const handleCheckboxChange = name => {
//     // Toggle the 'checked' state for the checkbox that the user interacts with
//     setCheckboxStates(prevState =>
//       prevState.map(checkbox =>
//         checkbox.name === name
//           ? { ...checkbox, checked: !checkbox.checked }
//           : checkbox
//       )
//     )
//   }

//   // Notify parent component of changes
//   useEffect(() => {
//     const selectedInclusions = checkboxStates
//       .filter(state => state.checked)
//       .map(({ name }) => ({
//         name,
//         image: "text", // Assuming 'image' field is required as in your initial setup
//       }))
//     onInclusionsChange(selectedInclusions)
//   }, [checkboxStates])

//   return (
//     <React.Fragment>
//       {checkboxStates.map((option, index) => (
//         <Col key={option.name} lg="4">
//           <div className="form-check form-checkbox-outline mb-3">
//             <input
//               type="checkbox"
//               className="form-check-input"
//               id={`inclusion-${index}`}
//               checked={option.checked == true ? true : false || ""}
//               onChange={() => handleCheckboxChange(option.name)}
//               onClick={e => {
//                 e.preventDefault()
//                 handleCheckboxChange(option.name)
//                 e.target.checked
//               }}
//             />
//             <label className="form-check-label" htmlFor={`inclusion-${index}`}>
//               {option.name}
//             </label>
//           </div>
//         </Col>
//       ))}
//     </React.Fragment>
//   )
// }

// export default Inclusions

import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"

const options = [
  "International Flights",
  "Domestic Flights",
  "Private Intercity",
  "Shared Intercity",
  "Hotel Stay",
  "Attractions / Passes",
  "Day Tours",
  "Transfers",
  "Helicopter / Scenic Flight",
  "Cruises",
  "Water Sports",
  "Motor Sports",
  "Hot Air Balloon",
  "Winery",
  "Meal",
  "Camper Vans",
  "Car Rental",
]

function Inclusions({ onInclusionsChange, data }) {
  // Convert incoming data to a format that matches the checkbox state structure
  const initialState = options.map(option => ({
    name: option,
    checked: data ? data?.some(inclusion => inclusion.name === option) : false,
    id: `customCheck-outlinecolor-${option}`, // Unique ID based on option name
    colorClass: "form-check-primary", // Simplified for demonstration
    image: "text", // Assuming all inclusions use a default "text" image
  }))

  const [checkboxStates, setCheckboxStates] = useState(initialState)

  useEffect(() => {
    // Update state to reflect changes in incoming data
    setCheckboxStates(initialState)
  }, [data])

  useEffect(() => {
    // Whenever checkboxStates changes, notify the parent component
    const selectedInclusions = checkboxStates
      .filter(checkbox => checkbox.checked)
      .map(({ name, image }) => ({ name, image }))
    onInclusionsChange(selectedInclusions)
  }, [checkboxStates])

  const handleCheckboxChange = index => {
    const newCheckboxStates = [...checkboxStates]
    newCheckboxStates[index].checked = !newCheckboxStates[index].checked
    setCheckboxStates(newCheckboxStates)
  }

  return (
    <React.Fragment>
      {checkboxStates.map((option, index) => (
        <Col key={option.name} lg="4">
          <div
            className={`form-check form-checkbox-outline ${option.colorClass} mb-3`}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id={option.id}
              // checked={option.checked}
              // onChange={() => handleCheckboxChange(index)}

              checked={
                data
                  ? option.checked == true
                    ? true
                    : false || ""
                  : checkboxStates[index].checked
              }
              onChange={
                data
                  ? () => handleCheckboxChange(option.name)
                  : () => handleCheckboxChange(index)
              }
              // checked={option.checked == true ? true : false || ""}
              // onChange={() => handleCheckboxChange(option.name)}
              onClick={e => {
                e.preventDefault()
                if (data) {
                  if (option.checked == true) {
                    handleCheckboxChange(index)
                    e.target.checked = false
                  } else if (option.checked == false) {
                    handleCheckboxChange(index)
                    e.target.checked = true
                  } else {
                    handleCheckboxChange(index)
                    e.target.checked
                  }
                }
              }}
            />
            <label className="form-check-label" htmlFor={option.id}>
              {option.name}
            </label>
          </div>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default Inclusions
